import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { baseAxios } from '../../api/customAxios';
import MainLayout from '../../layout/MainLayout';
import { tokenState } from '../../recoil/atom';

export default function KakaoLogin() {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const setToken = useSetRecoilState(tokenState);

	useEffect(() => {
		const fetchCode = async () => {
			try {
				const KAKAO_CODE = searchParams.get('code');
				if (KAKAO_CODE) {
					const user = await baseAxios.post(`/auth/social-login/kakao`, {
						code: KAKAO_CODE,
					});
					setToken(user.data.token);
					localStorage.setItem('doSurvey', user.data.doServey);
					localStorage.setItem('agreeTerms', user.data.agreeTerms);
					if (!user.data.agreeTerms) {
						navigate('/auth/agree');
					} else if (!user.data.doServey) {
						navigate('/apply/1');
					} else {
						navigate('/home');
					}
				}
			} catch (err) {
				console.log('fail', err);
				alert('카카오 로그인 에러!\n죄송합니다. 똑디 로그인을 이용해주세요');
				localStorage.clear();
				navigate('/login');
			}
		};

		fetchCode();
	}, [searchParams]);

	return (
		<MainLayout>
			<LoginText>로그인중입니다..</LoginText>
		</MainLayout>
	);
}

const LoginText = styled.div`
	font-size: 20px;
	font-weight: 600;
	text-align: center;
`;
