import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Title from '../../components/Title';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { applyState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';

export default function Apply4() {
	const navigate = useNavigate();
	const apply = useRecoilValue(applyState);

	return (
		<ApplyLayout>
			<TopHeader page='/apply/3' />
			<Title>
				좋아요!
				<br />
				{apply?.name}님 잘 부탁드립니다!
			</Title>
			<ApplyFooter>
				<VolumeButton
					onClick={() => getSpeech(`좋아요! 회원님 잘 부탁드립니다!`)}
				/>
				<ApplyButton onClick={() => navigate('/apply/5')}>다음</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}
