import React from 'react';
import { styled } from 'styled-components';

interface TitleProps {
  children: React.ReactNode;
}

export default function Title({ children }: TitleProps) {
  return <Header>{children}</Header>;
}

const Header = styled.header`
  width: 100%;
  margin: 10% auto 15%;
  font-size: 24px;
  font-weight: 500;
  line-height: 35px;
`;
