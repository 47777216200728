import { useState } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as Badge1 } from '../../asset/svg/Badge1.svg';
import { ReactComponent as Badge2 } from '../../asset/svg/Badge2.svg';
import { ReactComponent as Badge3 } from '../../asset/svg/Badge3.svg';
import { ReactComponent as Lock } from '../../asset/svg/LockIcon.svg';
import theme from '../styles/theme';

interface bdgType {
	id: number;
	name: string;
	getBadge: boolean;
}

interface BadgeProps {
	name: string;
	data: bdgType[];
}

interface ModalProps {
	isopen: string;
}

export default function Badge({ name, data }: BadgeProps) {
	const [isOpen, setIsOpen] = useState(window.innerHeight >= 800);

	const LockBadge = (
		<Box>
			<BdgImgGray>
				<Lock />
			</BdgImgGray>
			<Text>비공개</Text>
		</Box>
	);

	return (
		<BadgeSection>
			<Title>
				{name} 님의 배지
				<OpenBtn
					onClick={() => {
						setIsOpen((prev) => !prev);
					}}
				>
					{isOpen ? '닫기' : '열기'}
				</OpenBtn>
			</Title>
			<ModalWrapper isopen={isOpen ? 'true' : 'false'}>
				{isOpen && (
					<Content>
						{data.map((x) => {
							return (
								<Box key={x.id}>
									{x.getBadge ? (
										<BdgImg>
											{x.id === 1 ? (
												<Badge1 />
											) : x.id === 2 ? (
												<Badge2 />
											) : (
												<Badge3 />
											)}
										</BdgImg>
									) : (
										<BdgImgGray>
											<Lock />
										</BdgImgGray>
									)}
									<Text>{x.name}</Text>
								</Box>
							);
						})}
						{LockBadge}
						{LockBadge}
						{LockBadge}
					</Content>
				)}
			</ModalWrapper>
		</BadgeSection>
	);
}

const BadgeSection = styled.section`
	margin-top: auto;
	padding: 3% 0;
	border-radius: 20px 20px 0px 0px;
	background: #fff;
	box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.3);
`;

const Title = styled.div`
	width: 90%;
	max-width: 425px;
	margin: 3% auto;
	font-size: 20px;
	font-weight: 600;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const OpenBtn = styled.div`
	color: ${theme.D_grey5};
	font-size: 14px;
	cursor: pointer;

	@media (min-height: 800px) {
		display: none;
	}
`;

const Content = styled.div`
	width: 90%;
	max-width: 425px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	overflow: auto;
`;

const ModalWrapper = styled.div<ModalProps>`
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;

	${({ isopen }) =>
		isopen === 'true' &&
		css`
			max-height: 500px;
		`}
`;

const Box = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 30%;
	margin: 5% 0;
`;

const BdgImg = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 84px;
	height: 84px;
	border-radius: 12px;
	background-color: ${theme.D_blue};
`;

const BdgImgGray = styled(BdgImg)`
	background-color: ${theme.D_grey2};
`;

const Text = styled.div`
	margin-top: 15px;
	color: #6f6f6f;
	font-size: 14px;
	font-weight: 400;
`;
