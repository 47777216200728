interface ExperienctType {
	id: number;
	experience: string;
}

export const Experience: ExperienctType[] = [
	{
		id: 1,
		experience: '매우 많이 들어봤다. (10회 이상)',
	},
	{
		id: 2,
		experience: '많이 들어봤다. (5회 이상)',
	},
	{
		id: 3,
		experience: '몇 번 들어봤다. (2~3회)',
	},
	{
		id: 4,
		experience: '한 번 들어봤다.',
	},
	{
		id: 5,
		experience: '전혀 안 들어봤다.',
	},
];
