import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import LevelTestLayout from '../../layout/LevelTestLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';

function LevelTest() {
	const navigate = useNavigate();
	return (
		<LevelTestLayout>
			<TopHeader title='나에게 맞는 수업 찾기' page='/home' />
			<RangeBarWrap>
				<div>1/14</div>
				<RangeBar type='range' min={0} max={14} step={1} defaultValue={1} />
			</RangeBarWrap>
			<MentBox>
				딱 맞는 수업 과정을
				<br /> 추천해드리기 위해
				<br /> 몇가지를 여쭤보고자 해요.
			</MentBox>
			<ApplyFooter>
				<VolumeButton />
				<ApplyButton onClick={() => navigate('/levelTest2')}>다음</ApplyButton>
			</ApplyFooter>
		</LevelTestLayout>
	);
}

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: baseline;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;

export default LevelTest;
