import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Celebrate } from '../asset/svg/Celebrate.svg';
import theme from '../pages/styles/theme';

interface LevelProps {
	level?: string;
}

export default function EndGame(props: LevelProps) {
	const [isCorrect, setIsCorrect] = useState();
	const url = useLocation();
	const path = url.pathname;
	const navigate = useNavigate();

	console.log(path);
	return (
		<Background>
			{/* <Celebratea /> */}
			<Ment>
				<Sorry>아쉽지만..</Sorry>
				<div>게임 종료</div>
			</Ment>
			<ButtonBox>
				<ButtonRe
					onClick={() => {
						window.location.reload();
					}}
				>
					다시 플레이
				</ButtonRe>
				<Button
					onClick={() => {
						navigate('/game');
					}}
				>
					종료
				</Button>
			</ButtonBox>
		</Background>
	);
}

const Celebratea = styled(Celebrate)``;

const Background = styled.div`
	position: absolute;
	z-index: 50;
	width: 100vw;
	height: 100vh;
	opacity: 0.8;
	background-color: #000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 48px;
	font-weight: 900;
`;

const Ment = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 10px;
`;

const Sorry = styled.div`
	font-size: 20px;
`;

const ButtonBox = styled.div`
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	bottom: 60px;
	gap: 20px;
`;

const ButtonRe = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90%;
	height: 52px;
	padding: 0 24px 0 24px;
	border: none;
	border-radius: 11px;
	color: #fff;
	background-color: ${theme.pink};
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	opacity: 1;
`;
const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90%;
	height: 52px;
	padding: 0 24px 0 24px;
	border: none;
	border-radius: 11px;
	color: #000;
	background-color: ${theme.D_grey3};
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	opacity: 1;
`;
