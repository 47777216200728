export const getSpeech = (text: string) => {
	let voices: any[] = [];

	// 내장된 voice 가져오기
	const setVoiceList = () => {
		voices = window.speechSynthesis.getVoices();
	};

	setVoiceList();

	if (window.speechSynthesis.onvoiceschanged !== undefined) {
		window.speechSynthesis.onvoiceschanged = setVoiceList;
	}

	const speech = (txt: string) => {
		const lang = 'ko-KR';
		const utterThis = new SpeechSynthesisUtterance(txt);

		utterThis.lang = lang;
		utterThis.rate = 0.8;

		// 한국어 voice 찾기
		const kor_voice = voices.find(
			(el) => el.lang === lang || lang.replace('-', '_')
		);

		// 한국어 voice가 있으면 utterance에 목소리 설정
		if (kor_voice) {
			utterThis.voice = kor_voice;
		} else {
			return;
		}

		// utterance 재생
		window.speechSynthesis.speak(utterThis);
	};

	speech(text);
};
