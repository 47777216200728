import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import Content from '../../components/Content';
import ApplyButton from '../../components/button/ApplyButton';
import InputButton from '../../components/button/InputButton';
import MainLayout from '../../layout/MainLayout';
import { tokenState } from '../../recoil/atom';

export default function ClassCode() {
	const navigate = useNavigate();
	const [code, setCode] = useState<string>('');
	const token = useRecoilValue(tokenState);

	const handleCode = (e: ChangeEvent<HTMLInputElement>) => {
		setCode(e.target.value);
	};

	const handleNextPage = () => {
		if (token) {
			navigate('/home');
		} else navigate('/login');
	};

	return (
		<MainLayout>
			<CodeWrapper>
				<Title>
					만나서 반가워요! <br /> 수업코드를 입력해주세요
				</Title>

				<Content>
					<InputButton
						type='number'
						id='classcode'
						label='수업코드'
						value={code}
						onChange={handleCode}
					/>
				</Content>
			</CodeWrapper>
			<Footer>
				<ApplyButton onClick={handleNextPage} disabled={code.length === 0}>
					다음
				</ApplyButton>
			</Footer>
		</MainLayout>
	);
}

const CodeWrapper = styled.section`
	width: 90%;
	height: 70%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const Title = styled.div`
	margin-bottom: 10%;
	font-size: 24px;
	font-weight: 500;
	line-height: 150%;
	text-align: center;
`;

const Footer = styled.footer`
	width: 90%;
	margin-top: auto;
	margin-bottom: 30px;
`;
