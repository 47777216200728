import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import CheckList from '../../components/CheckList';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { getSpeech } from '../../utils/getSpeech';

export default function Curriculum6() {
	const [disable, setDisable] = useState(false);
	const [isCorrect, setIsCorrect] = useState(false);
	const [checkedTasksText, setCheckedTasksText] = useState<string[]>([]);
	const navigate = useNavigate();

	useEffect(() => {
		let newIsCorrect = false;
		if (JSON.stringify(checkedTasksText) !== JSON.stringify([])) {
			newIsCorrect = true;
		}
		setIsCorrect(newIsCorrect);
		setDisable(false);
		// 여기서 isCorrect를 사용하거나 다른 동작을 수행
		console.log(checkedTasksText);
		console.log(newIsCorrect);
	}, [checkedTasksText]);

	return (
		<ApplyLayout>
			<TopHeader title='스마트폰 사용법 기초' page='/curriculum5' />
			<RangeBarWrap>
				<div>6/16</div>
				<RangeBar type='range' min={0} max={16} step={6} defaultValue={6} />
			</RangeBarWrap>
			{!disable && !isCorrect && (
				<MentBox>
					원하는 항목에 옆에 있는
					<br /> 작은 버튼을 누르면
					<br /> 선택할 수 있어요
				</MentBox>
			)}
			{disable && (
				<MentBox>
					아무거나 한 번
					<br />
					눌러보세요!
				</MentBox>
			)}
			{!disable && isCorrect && (
				<MentBox>
					잘하셨어요!
					<br />
					다음으로 넘어가 볼까요?
				</MentBox>
			)}

			<CheckList setCheckedTasksText={setCheckedTasksText} />

			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						if (!disable && !isCorrect) {
							getSpeech(
								'원하는 항목에 옆에 있는  작은 버튼을 누르면 선택할 수 있어요'
							);
						}
						if (disable) {
							getSpeech('아무거나 한 번 눌러보세요!');
						}
						if (!disable && isCorrect) {
							getSpeech('잘하셨어요 다음으로 넘어가볼까요?');
						}
					}}
				/>
				<ApplyButton
					disabled={disable}
					onClick={() => {
						if (!disable && !isCorrect) {
							setDisable(true);
						}

						if (!disable && isCorrect) {
							navigate('/curriculum7');
						}
					}}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;
