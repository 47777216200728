import React from 'react';
import { styled } from 'styled-components';

interface ContentProps {
	children?: React.ReactNode;
	active?: boolean;
	onClick?: () => void;
}

export default function CurryButton({
	children,
	active,
	onClick,
}: ContentProps) {
	return (
		<Container active={active} onClick={onClick}>
			{children}
		</Container>
	);
}

const Container = styled.div<ContentProps>`
	width: 96px;
	height: 36px;
	border-radius: 18px;
	background-color: var(--d-grey-2, #e6e6e6);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 12px;
	box-shadow: ${({ active }) =>
		active ? '2px 2px 4px 0px rgba(0, 0, 0, 0.25)' : ''};
	background-color: ${({ active }) =>
		active ? '#FD698D' : 'var(--d-grey-2, #e6e6e6)'};
	color: ${({ active }) => (active ? '#fff' : '#000')};
`;
