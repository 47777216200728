import { styled } from 'styled-components';
import { ReactComponent as LogoPink } from '../../../src/asset/svg/LogoPink.svg';
import MainLayout from '../../layout/MainLayout';
import FindIdPw from '../auth/FindIdPw';
import LoginContainer from './LoginContainer';

export default function Login() {
	return (
		<MainLayout>
			<Header>
				<LogoPink />
				<Subtitle>똑똑하게 배우는 디지털 세상</Subtitle>
			</Header>
			<Container>
				<LoginContainer />
				<FindIdPw />
			</Container>
		</MainLayout>
	);
}

const Header = styled.header`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 40vh;
`;

const Subtitle = styled.div`
	margin-top: 10%;
	color: #252525;
	font-size: 16px;
	font-weight: 600;
`;

const Container = styled.div`
	width: 85%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
