interface AgeProps {
	id: number;
	age: number;
}

const Age: AgeProps[] = [];

for (let i = 100; i >= 50; i--) {
	const obj = { id: i, age: i };
	Age.push(obj);
}

export default Age;
