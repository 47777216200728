import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { HardestApp } from '../../asset/data/HardestApp';
import Content from '../../components/Content';
import Title from '../../components/Title';
import ApplyButton from '../../components/button/ApplyButton';
import SelectButton from '../../components/button/SelectButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { applyState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';

export default function Apply9() {
	const navigate = useNavigate();
	const [apply, setApply] = useRecoilState(applyState);
	const [app, setApp] = useState<number[]>(apply.hardestAppToUseIds || []);

	const handleBtn = (id: number) => {
		if (app.includes(id)) {
			setApp(app.filter((x) => x !== id).sort());
			return;
		}
		setApp((prev) => [...prev, id].sort());
	};

	const submit = () => {
		setApply({ ...apply, hardestAppToUseIds: app });
	};

	return (
		<ApplyLayout>
			<TopHeader page='/apply/8' />
			<Title>
				사용하기 어렵다고 느껴지는
				<br />앱 서비스를 선택해주세요
			</Title>
			<Content>
				{HardestApp.map((x) => {
					return (
						<SelectButton
							key={x.id}
							value={x.app}
							onClick={() => handleBtn(x.id)}
							selected={app.includes(x.id)}
						/>
					);
				})}
			</Content>
			<ApplyFooter>
				<VolumeButton
					onClick={() =>
						getSpeech('사용하기 어렵다고 느껴지는, 앱 서비스를 선택해주세요')
					}
				/>
				<ApplyButton
					onClick={() => {
						submit();
						navigate('/apply/10');
					}}
					disabled={app.length === 0}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}
