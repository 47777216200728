export const GuideKorail = [
	{ id: 1, title: '기차 예매하는 방법을 모르겠어요' },
	{ id: 2, title: '특가 할인 방법을 모르겠어요' },
	{ id: 3, title: '정기권 구매 방법을 모르겠어요' },
	{ id: 4, title: '내가 구매한 승차권은 어디서 확인하나요?' },
	{ id: 5, title: '구매한 승차권을 환불하고 싶어요' },
	{ id: 6, title: '승차권의 예매 내역을 변경하고 싶어요' },
	{ id: 7, title: '승차권을 다른 사람에게 보내주고 싶어요' },
	{ id: 8, title: '관광 상품과 함께 예약하고 싶어요' },
];
