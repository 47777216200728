import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import Age from '../../asset/data/Age';
import { Cities } from '../../asset/data/City';
import { CityGu } from '../../asset/data/CityGu';
import { ReactComponent as Arrow } from '../../asset/svg/ArrowDown.svg';
import Content from '../../components/Content';
import Title from '../../components/Title';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { applyState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';
import theme from '../styles/theme';

export default function Apply5() {
	const navigate = useNavigate();
	const [apply, setApply] = useRecoilState(applyState);
	const [age, setAge] = useState<number>(apply.age || 0);
	const [gender, setGender] = useState<string>(apply.gender || '');
	const [city, setCity] = useState<number>(apply.addressSidoId || 0);
	const [gu, setGu] = useState<number>(apply.addressSigunguId || 0);

	const handleSelectAge = (e: ChangeEvent<HTMLSelectElement>) => {
		setAge(Number(e.target.value));
	};

	const handleSelectGender = (e: ChangeEvent<HTMLSelectElement>) => {
		setGender(e.target.value);
	};

	const handleSelectCity = (e: ChangeEvent<HTMLSelectElement>) => {
		setCity(Number(e.target.value));
	};

	const handleSelectGu = (e: ChangeEvent<HTMLSelectElement>) => {
		setGu(Number(e.target.value));
	};

	const submit = () => {
		setApply({
			...apply,
			age: age,
			gender: gender,
			addressSidoId: city,
			addressSigunguId: gu,
		});
	};

	return (
		<ApplyLayout>
			<TopHeader page='/apply/4' />
			<Title>
				{apply?.name}님의 나이, 성별, 거주지를
				<br />
				입력해주세요!
			</Title>
			<Content>
				<Wrapper>
					<Label>나이</Label>
					<DropdownSelect defaultValue={age} onChange={handleSelectAge}>
						<Option value='none'>나이를 선택하세요</Option>
						{Age.map((x) => {
							return (
								<Option key={x.id} value={x.age}>
									{x.age}
								</Option>
							);
						})}
					</DropdownSelect>
					<Arrow />
				</Wrapper>
				<Wrapper>
					<Label>성별</Label>
					<DropdownSelect defaultValue={gender} onChange={handleSelectGender}>
						<Option value='none'>성별을 선택하세요</Option>
						<Option value='남'>남</Option>
						<Option value='여'>여</Option>
					</DropdownSelect>
					<Arrow />
				</Wrapper>
				<SmallInput>
					<Wrapper2>
						<Label>시.도</Label>
						<DropdownSelect defaultValue={city} onChange={handleSelectCity}>
							<Option value='none'>도시를 선택하세요</Option>
							{Cities.map((city) => {
								return (
									<Option key={city.id} value={city.id}>
										{city.sido}
									</Option>
								);
							})}
						</DropdownSelect>
						<Arrow />
					</Wrapper2>
					<Wrapper2>
						<Label>시.군.구</Label>
						{city ? (
							<DropdownSelect defaultValue={gu} onChange={handleSelectGu}>
								<Option value='none'>시.군.구를 선택하세요</Option>
								{CityGu.filter((x) => x.address_sido_id === city).map((gu) => {
									return (
										<Option key={gu.id} value={gu.id}>
											{gu.sigungu}
										</Option>
									);
								})}
							</DropdownSelect>
						) : (
							<DropdownSelect>
								<Option value='none'>도시를 먼저 선택하세요</Option>
							</DropdownSelect>
						)}
						<Arrow />
					</Wrapper2>
				</SmallInput>
			</Content>

			<ApplyFooter>
				<VolumeButton
					onClick={() =>
						getSpeech('회원님의 나이, 성별, 거주지를 입력해주세요')
					}
				/>
				<ApplyButton
					onClick={() => {
						submit();
						navigate('/apply/6');
					}}
					disabled={!(age && gender && city && gu)}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}

const SmallInput = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Wrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	height: 48px;
	margin: 2% 0;
	padding: 0 5%;
	border: 2px solid ${theme.D_grey3};
	border-radius: 11px;
	cursor: pointer;
	outline-color: ${theme.pink};
`;

const Wrapper2 = styled(Wrapper)`
	width: 38%;
`;

const Label = styled.label`
	position: absolute;
	top: 7px;
	width: 50px;
	margin-bottom: 1%;
	color: #737373;
	font-size: 12px;
	font-weight: 400;
`;

const DropdownSelect = styled.select`
	width: 100%;
	margin-top: 20px;
	border: none;
	color: ${theme.D_grey6};
	font-size: 16px;
	font-weight: 700;
	outline: none;
	box-sizing: border-box;
	background: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
`;

const Option = styled.option`
	margin-top: 10px;
	color: #737373;
	font-size: 14px;
	font-weight: 600;
`;
