import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { ReactComponent as Arrow } from '../../asset/svg/ArrowBox.svg';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { getSpeech } from '../../utils/getSpeech';

export default function Curriculum4() {
	const [disable, setDisable] = useState(true);
	const [comment, setComment] = useState(true);
	const [fruit, setFruit] = useState('');
	const navigate = useNavigate();

	useEffect(() => {
		if (fruit == '딸기') {
			return setDisable(false);
		} else {
			return setDisable(true);
		}
	}, [fruit]);

	return (
		<ApplyLayout>
			<TopHeader title='스마트폰 사용법 기초' page='/curriculum3' />
			<RangeBarWrap>
				<div>4/16</div>
				<RangeBar type='range' min={0} max={16} step={4} defaultValue={4} />
			</RangeBarWrap>
			{disable && comment && (
				<MentBox>
					아래의 상자는 버튼을 눌러
					<br />더 많은 내용을 펼쳐볼 수 있어요
					<br />한 번 눌러보세요!
				</MentBox>
			)}
			{disable && !comment && (
				<MentBox>
					아래의 리스트에서
					<br /> `딸기`를 골라주세요
				</MentBox>
			)}
			{!disable && !comment && (
				<MentBox>
					잘하셨어요! <br />
					다음으로 넘어가 볼까요?
				</MentBox>
			)}
			<ListBox>
				<ArrowB />
				<SelectBox
					onClick={() => {
						setComment(false);
					}}
					onChange={(e) => setFruit(e.target.value)}
				>
					<Option value=''>과일 목록</Option>
					<Option value='딸기'>딸기</Option>
					<Option value='수박'>수박</Option>
					<Option value='메론'>메론</Option>
					<Option value='참외'>참외</Option>
					<Option value='사과'>사과</Option>
				</SelectBox>
			</ListBox>
			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						if (disable && comment) {
							getSpeech(
								'아래의 상자는 버튼을 눌러 더 많은 내용을 펼쳐볼 수 있어요 한 번 눌러보세요!'
							);
						}
						if (disable && !comment) {
							getSpeech('아래의 리스트에서 `딸기`를 골라주세요');
						}
						if (!disable && !comment) {
							getSpeech('잘하셨어요 다음으로 넘어가볼까요?');
						}
					}}
				/>
				<ApplyButton
					disabled={disable}
					onClick={() => navigate('/curriculum5')}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}

const SelectBox = styled.select`
	width: 100%;
	height: 48px;
	margin-top: 13px;
`;

const ArrowB = styled(Arrow)`
	width: 20px;
	height: 36px;
`;

const Option = styled.option`
	padding: 0 24px;
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	height: 144px;
	display: flex;
	align-items: baseline;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;

const ListBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: end;
	margin-top: 14px;
`;
