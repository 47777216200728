interface CitiesType {
	id: number;
	sido: string;
}

export const Cities: CitiesType[] = [
	{
		id: 1,
		sido: '서울특별시',
	},
	{
		id: 2,
		sido: '부산광역시',
	},
	{
		id: 3,
		sido: '대구광역시',
	},
	{
		id: 4,
		sido: '인천광역시',
	},
	{
		id: 5,
		sido: '광주광역시',
	},
	{
		id: 6,
		sido: '대전광역시',
	},
	{
		id: 7,
		sido: '울산광역시',
	},
	{
		id: 8,
		sido: '세종특별자치시',
	},
	{
		id: 9,
		sido: '경기도',
	},
	{
		id: 10,
		sido: '강원도',
	},
	{
		id: 11,
		sido: '충청북도',
	},
	{
		id: 12,
		sido: '충청남도',
	},
	{
		id: 13,
		sido: '전라북도',
	},
	{
		id: 14,
		sido: '전라남도',
	},
	{
		id: 15,
		sido: '경상북도',
	},
	{
		id: 16,
		sido: '경상남도',
	},
	{
		id: 17,
		sido: '제주도',
	},
];
