import { useEffect, useState } from 'react';
import styled from 'styled-components';

interface RangeBarProps {
	value: number;
}
interface TimeProps {
	time?: number;
	setValue: React.Dispatch<React.SetStateAction<number>>;
}
export default function TimerRangeBar(props: TimeProps) {
	const [value, setValue] = useState(100);
	props.setValue(value);

	console.log(props.time);

	const time = props?.time;
	const duration = time !== undefined ? time : 10000; // 기본값 0으로 설정

	useEffect(() => {
		setTimeout(function () {
			const interval = setInterval(() => {
				setValue((prevValue) => {
					if (prevValue <= 0) {
						clearInterval(interval);
						return 0;
					}
					return prevValue - (100 / duration) * 100;
				});
			}, 100);

			return () => {
				clearInterval(interval);
			};
		}, 2000);
	}, []);

	return <RangeBar type='range' min={0} max={100} value={value} />;
}

const RangeBar = styled.input<RangeBarProps>`
	width: 100%;
	height: 8px;
	-webkit-appearance: none;
	appearance: none;
	border-radius: 24px;
	background: linear-gradient(
		to right,
		#fd698d 0%,
		#fd698d ${(props) => props.value}%,
		white ${(props) => props.value}%,
		white 100%
	);
	outline: none;

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 8px;
		height: 8px;
		/* border: 2px solid gray; */
		border-radius: 50%;
	}

	&:focus::-webkit-slider-thumb {
		background: #fd698d;
		border-radius: 24px;
	}

	&::-moz-range-thumb {
		width: 20px;
		height: 20px;
		border: 2px solid gray;
		border-radius: 50%;
	}

	&:focus::-moz-range-thumb {
		background: #fd698d;
		border-radius: 24px;
	}
`;
