import { styled } from 'styled-components';

import theme from '../../pages/styles/theme';

interface LoginButtonProps {
	children: string;
	onClick(): void;
}

const BaseButton = styled.button`
	width: 100%;
	height: 52px;
	margin: 2% 0;
	border: 1.5px solid ${theme.D_grey5};
	border-radius: 31px;
	color: ${theme.D_grey6};
	background-color: ${theme.white};
	font-weight: 600;
`;

const KaKaoButton = styled(BaseButton)`
	border: none;
	color: #3a1f1e;
	background-color: ${theme.yellow};
`;

const NaverButton = styled(BaseButton)`
	border: none;
	color: ${theme.white};
	background-color: ${theme.green};
`;

const DdokdButton = styled(BaseButton)`
	border: none;
	background-color: ${theme.pink};
	color: ${theme.white};
`;

const buttonMap: Record<string, any> = {
	'카카오톡으로 로그인하기': KaKaoButton,
	'네이버로 로그인하기': NaverButton,
	'똑디 아이디로 로그인하기': DdokdButton,
};

export default function LoginButton(props: LoginButtonProps) {
	const ButtonComponent = buttonMap[props.children] || BaseButton;
	return (
		<ButtonComponent onClick={props.onClick}>{props.children}</ButtonComponent>
	);
}
