import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import { authAxios } from '../../api/customAxios';
import WifiImage from '../../components/Wifi';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { correctAnswersState, tokenState } from '../../recoil/atom';
import theme from '../styles/theme';

function LevelTest10() {
	const [wifi, setWifi] = useState<boolean>(false);
	const [isCorrect, setIsCorrect] = useState(false);
	const [correctAnswers, setCorrectAnswers] =
		useRecoilState(correctAnswersState); // Recoil atom 상태 추가	// const [touchPosition, setTouchPosition] = useState({ x: 0, y: 0 });
	// const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
	const [dragging, setDragging] = useState(false);
	const [dragPosition, setDragPosition] = useState<{ x: number; y: number }[]>(
		[]
	);
	const [touchLocations, setTouchLocations] = useState<
		{ x: number; y: number }[]
	>([]);

	const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
		setDragging(true);
		handleMouseMove(event); // Call handleMouseMove directly to set initial position
	};

	const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = () => {
		setDragging(false);
	};

	// const touchLocation = [];

	const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
		if (!dragging) return;

		const dragX = event.clientX;
		const dragY = event.clientY;
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		const normalizedX = (dragX / screenWidth) * 100;
		const normalizedY = (dragY / screenHeight) * 100;

		setTouchLocations((prevLocations) => [
			...prevLocations,
			{ x: normalizedX, y: normalizedY },
		]);
	};

	// const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = (event) => {
	// 	const touch = event.touches[0];
	// 	const screenWidth = window.innerWidth;
	// 	const screenHeight = window.innerHeight;

	// 	const touchX = touch.clientX;
	// 	const touchY = touch.clientY;

	// 	const normalizedX = (touchX / screenWidth) * 100;
	// 	const normalizedY = (touchY / screenHeight) * 100;

	// 	// setTouchPosition({ x: normalizedX, y: normalizedY });
	// 	setTouchLocations((prevLocations) => [
	// 		...prevLocations,
	// 		{ x: normalizedX, y: normalizedY },
	// 	]);
	// };

	const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
		const clickX = event.clientX;
		const clickY = event.clientY;
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		const normalizedX = (clickX / screenWidth) * 100;
		const normalizedY = (clickY / screenHeight) * 100;

		// setClickPosition({ x: normalizedX, y: normalizedY });
		setTouchLocations((prevLocations) => [
			...prevLocations,
			{ x: normalizedX, y: normalizedY },
		]);
	};
	console.log('클릭', touchLocations);
	console.log('드래그', dragPosition);
	const token = useRecoilValue(tokenState);
	console.log(wifi);
	const navigate = useNavigate();

	useEffect(() => {
		if (wifi == true) {
			setIsCorrect(true);
		} else {
			setIsCorrect(false);
		}
		console.log(correctAnswers);
	}, [wifi]);
	console.log(isCorrect);

	const fetchCode = async () => {
		if (isCorrect == true) {
			setCorrectAnswers(correctAnswers + 1);
		}
		try {
			const user = await authAxios(token).post(`/quiz/evaluation`, {
				quizId: 8,
				userAnswer: String(wifi),
				isCorrect: isCorrect,
			});
			const userClick = await authAxios(token).post(`/quiz/curriculum`, {
				quizId: 8,
				clickLocation: touchLocations,
			});

			console.log(userClick);
			console.log(user);
			console.log('정답', isCorrect);

			console.log(user);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Container
			onClick={handleClick}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
		>
			<Content>
				<TopHeader title='나에게 맞는 수업 찾기' page='/levelTest9' />
				<RangeBarWrap>
					<div>10/14</div>
					<RangeBar type='range' min={0} max={14} step={10} defaultValue={10} />
				</RangeBarWrap>
				<MentBox>
					와이파이가 꺼져있어요.
					<br /> 와이파이를 켜주세요!
				</MentBox>
				<WifiImage setWifi={setWifi} />
				<ApplyFooter>
					<VolumeButton />
					<ApplyButton
						onClick={() => {
							fetchCode();
							navigate('/levelTest11');
						}}
					>
						다음
					</ApplyButton>
				</ApplyFooter>
			</Content>
		</Container>
	);
}

const Container = styled.div`
	height: 100vh;
	margin: 0 auto;
	background-color: ${theme.white};
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 85%;
	height: 100vh;
	margin: 0 auto;
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;

export default LevelTest10;
