import styled from 'styled-components';

interface LevelProps {
	level?: string;
}

export default function GameLoad(props: LevelProps) {
	return <Background>{props.level}</Background>;
}

const Background = styled.div`
	position: absolute;
	z-index: 50;
	width: 100vw;
	height: 100vh;
	opacity: 0.8;
	background-color: #000;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 48px;
	font-weight: 900;
`;
