import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../pages/styles/theme';

export default function UsePhoneBasicCard() {
	const navigate = useNavigate();

	return (
		<CardWrapper>
			<CardTitle>
				스마트폰 사용법
				<br />
				기초부터 이해하기
			</CardTitle>
			<FlexRow>
				<CardSubtitle>
					난이도 <CardBoldText>하</CardBoldText>
					<br />총 <CardBoldText>6강</CardBoldText>
				</CardSubtitle>
				<CardButton onClick={() => navigate('/curriculum1')}>
					학습하기
				</CardButton>
			</FlexRow>
		</CardWrapper>
	);
}

const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 90%;
	height: 190px;
	margin: 3% auto;
	border-radius: 12px;
	background: conic-gradient(
			from 134deg at 54.73% 54.08%,
			rgba(255, 255, 255, 0.14) 0deg,
			rgba(255, 255, 255, 0) 360deg
		),
		#fd9569;
	box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);
`;

const CardTitle = styled.div`
	width: 85%;
	margin: 0 auto;
	color: ${theme.white};
	font-size: 24px;
	font-weight: 700;
	line-height: 135%;
`;

const FlexRow = styled.div`
	width: 85%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const CardSubtitle = styled.div`
	color: ${theme.white};
	font-weight: 400;
	line-height: 135%;
`;

const CardBoldText = styled.span`
	font-weight: 700;
`;

const CardButton = styled.button`
	padding: 8px 20px;
	border: none;
	border-radius: 30px;
	box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.15);
	background: #fffade;
	font-size: 14px;
	font-weight: 600;
`;
