import { atom, selector } from 'recoil';
import { authAxios } from '../api/customAxios';

export interface applyTypes {
	name?: string;
	phoneNumber?: string;
	age?: number;
	gender?: string;
	addressSidoId?: number;
	addressSigunguId?: number;
	funnelIds?: number[];
	digitalDeviceUsageLevelId?: number;
	digitalEduExperienceId?: number;
	hardestAppToUseIds?: number[];
}

// Recoil & localStorage
const localStorageEffect =
	(key: string) =>
	({ setSelf, onSet }: any) => {
		const savedValue = localStorage.getItem(key);

		if (savedValue !== null) {
			setSelf(JSON.parse(savedValue));
		}

		onSet((newValue: any, _: any, isReset: boolean) => {
			isReset
				? localStorage.removeItem(key)
				: localStorage.setItem(key, JSON.stringify(newValue));
		});
	};

export const applyState = atom<applyTypes>({
	key: 'applyState',
	default: {},
	effects: [localStorageEffect('apply-data')],
});

export const tokenState = atom({
	key: 'token',
	default: '',
	effects: [localStorageEffect('token')],
});

export const getNameSelector = selector({
	key: 'user/name',
	get: async ({ get }) => {
		const token = get(tokenState);
		try {
			const res = await authAxios(token).get('user/info');
			return res.data.user.name;
		} catch (err) {
			return null;
		}
	},
});

export const getUserInfoSelector = selector({
	key: 'user/info',
	get: async ({ get }) => {
		const token = get(tokenState);
		try {
			const res = await authAxios(token).get('user/info');
			return res.data.user;
		} catch (err) {
			return null;
		}
	},
});

export const getUserBadge = selector({
	key: 'user/badge',
	get: async ({ get }) => {
		const token = get(tokenState);
		try {
			const res = await authAxios(token).get('user/badge');
			return res.data;
		} catch (err) {
			return null;
		}
	},
});

export const correctAnswersState = atom({
	key: 'correctAnswersState',
	default: 2,
});

export const getTotalCorrectAnswers = selector({
	key: 'getTotalCorrectAnswers',
	get: ({ get }) => {
		const correctAnswers = get(correctAnswersState);
		return correctAnswers;
	},
});
