import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authAxios } from '../../api/customAxios';
import Title from '../../components/Title';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { applyState, tokenState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';

export default function Apply10() {
	const navigate = useNavigate();
	const applyData = useRecoilValue(applyState);
	const token = useRecoilValue(tokenState);

	const submitBackend = async () => {
		try {
			await authAxios(token).post(`user/info`, applyData);
			alert('정보 입력이 완료되었습니다!');
			localStorage.setItem('doSurvey', 'true');
			await authAxios(token).post('user/badge', { badgeId: 1 });
			navigate('/home');
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<ApplyLayout>
			<TopHeader page='/apply/9' />
			<Title>
				모든 질문이 끝났어요.
				<br />
				똑디와 함께 열심히 공부해보아요!
			</Title>
			<ApplyFooter>
				<VolumeButton
					onClick={() =>
						getSpeech('모든 질문이 끝났어요. 똑디와 함께 열심히 공부해보아요')
					}
				/>
				<ApplyButton
					onClick={() => {
						submitBackend();
					}}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}
