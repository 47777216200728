import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import GuideLayout from '../../../layout/GuideLayout';
import GuideHeader from '../../../layout/header/GuideHeader';

export default function Korail8() {
	const images = [
		require('../../../asset/jpg/korailFirst.jpg'),
		require('../../../asset/jpg/korail-8/1.jpg'),
		require('../../../asset/jpg/korail-8/2.jpg'),
		require('../../../asset/jpg/korail-8/3.jpg'),
		require('../../../asset/jpg/korail-8/4.jpg'),
		require('../../../asset/jpg/korail-8/5.jpg'),
		require('../../../asset/jpg/korail-8/6.jpg'),
		require('../../../asset/jpg/korail-8/7.jpg'),
		require('../../../asset/jpg/korail-8/8.jpg'),
		require('../../../asset/jpg/korail-8/9.jpg'),
		require('../../../asset/jpg/korail-8/10.jpg'),
		require('../../../asset/jpg/korail-8/11.jpg'),
		require('../../../asset/jpg/korail-8/12.jpg'),
		require('../../../asset/jpg/korail-8/13.jpg'),
		require('../../../asset/jpg/korail-8/14.jpg'),
		require('../../../asset/jpg/korail-8/15.jpg'),
		require('../../../asset/jpg/korail-8/16.jpg'),
		require('../../../asset/jpg/korail-8/17.jpg'),
		require('../../../asset/jpg/korail-8/18.jpg'),
		require('../../../asset/jpg/korail-8/19.jpg'),
		require('../../../asset/jpg/korail-8/20.jpg'),
		require('../../../asset/jpg/korail-8/21.jpg'),
		require('../../../asset/jpg/korail-8/22.jpg'),
		require('../../../asset/jpg/korail-8/23.jpg'),
		require('../../../asset/jpg/korail-8/24.jpg'),
		require('../../../asset/jpg/korail-8/25.jpg'),
		require('../../../asset/jpg/korail-8/26.jpg'),
		require('../../../asset/jpg/korail-8/27.jpg'),
		require('../../../asset/jpg/korail-8/28.jpg'),
		require('../../../asset/jpg/korail-8/29.jpg'),
		require('../../../asset/jpg/korail-8/30.jpg'),
		require('../../../asset/jpg/korail-8/31.jpg'),
		require('../../../asset/jpg/korail-8/32.jpg'),
	];

	const navigate = useNavigate();
	const [currentImgIdx, setCurrentImgIdx] = useState(0);

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const halfScreenWidth = window.innerWidth / 2;

		if (e.clientX < halfScreenWidth) {
			// 왼쪽 클릭시, 이전 이미지로
			if (currentImgIdx > 0) {
				setCurrentImgIdx(currentImgIdx - 1);
			}
		} else {
			// 오른쪽 클릭시, 다음 이미지로
			if (currentImgIdx < images.length - 1) {
				setCurrentImgIdx(currentImgIdx + 1);
			} else {
				navigate('/guide/korail');
			}
		}
	};

	return (
		<GuideLayout>
			<GuideHeader
				title='관광 상품과 함께 예약하고 싶어요'
				page='/guide/korail'
			/>
			<ImgSlider onClick={(e) => handleClick(e)}>
				<ImgBox src={images[currentImgIdx]} alt='korail guide' />
			</ImgSlider>
		</GuideLayout>
	);
}

const ImgSlider = styled.div``;

const ImgBox = styled.img`
	width: 100%;
	height: 100%;
`;
