import { useNavigate } from 'react-router-dom';
import LoginButton from '../../components/button/LoginButton';
import { CLIENT_URL } from '../config/constants';

export default function LoginContainer() {
	const navigate = useNavigate();

	const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
	const NAVER_REDIRECT_URI = `${CLIENT_URL}/auth/naver`;
	const NAVER_AUTH_URI = `https://nid.naver.com/oauth2.0/authorize?client_id=${NAVER_CLIENT_ID}&redirect_uri=${NAVER_REDIRECT_URI}&response_type=code`;

	const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
	const KAKAO_REDIRECT_URI = `${CLIENT_URL}/auth/kakao`;
	const KAKAO_AUTH_URI = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code`;

	return (
		<>
			<LoginButton onClick={() => (window.location.href = KAKAO_AUTH_URI)}>
				카카오톡으로 로그인하기
			</LoginButton>
			<LoginButton onClick={() => (window.location.href = NAVER_AUTH_URI)}>
				네이버로 로그인하기
			</LoginButton>
			<LoginButton onClick={() => navigate('/signin')}>
				똑디 아이디로 로그인하기
			</LoginButton>
			<LoginButton onClick={() => navigate('/signup')}>
				회원가입하기
			</LoginButton>
		</>
	);
}
