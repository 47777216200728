import { styled } from 'styled-components';

import theme from '../../pages/styles/theme';

export default function InputButton(props: any) {
	return (
		<Button>
			<Label htmlFor='input'>{props.label}</Label>
			<Input
				type={props.type}
				id={props.id}
				placeholder={props.placeholder}
				pattern={props.pattern}
				value={props.value}
				onChange={props.onChange}
				maxLength={props.max}
				onKeyUp={props.onKeyUp}
			/>
		</Button>
	);
}

const Button = styled.div`
	position: relative;
	width: 100%;
	margin: 3% 0;
`;

const Label = styled.label`
	position: absolute;
	top: 20%;
	left: 20px;
	font-size: 12px;
	color: #737373;
	z-index: 1;
`;

const Input = styled.input`
	width: 100%;
	height: 60px;
	border: 2px solid ${theme.D_grey3};
	border-radius: 11px;
	padding: 20px 0 0 18px;
	font-size: 18px;
	font-weight: 700;
	box-sizing: border-box;
	outline-color: ${theme.pink};

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;
