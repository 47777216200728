import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import { ReactComponent as YouTube } from '../../asset/svg/YouTube.svg';
import VideoPlayer from '../../components/VideoPlayer';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import LevelTestLayout from '../../layout/LevelTestLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { tokenState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';

export default function Curriculum8() {
	const [disable, setDisable] = useState(false);
	const [isCorrect, setIsCorrect] = useState(false);
	const [isVideo, setIsVideo] = useState(false);
	const [playerTime, setPlayerTime] = useState(0);
	const token = useRecoilValue(tokenState);
	const navigate = useNavigate();

	const integer = Math.floor(playerTime);

	console.log('시간', integer);

	useEffect(() => {
		if (integer >= 207) {
			setIsCorrect(true);
			setDisable(false);
		}
	}, [integer]);
	console.log(isCorrect);

	return (
		<LevelTestLayout>
			<TopHeader title='스마트폰 사용법 기초' page='/curriculum7' />
			<RangeBarWrap>
				<div>8/16</div>
				<RangeBar type='range' min={0} max={16} step={8} defaultValue={8} />
			</RangeBarWrap>
			{!isVideo && !disable && (
				<MentBox>
					영상속 중요한 부분만 보고싶다면
					<br />
					아래의 빨간 바를 눌러서
					<br /> 원하는 재생 위치에 두면 돼요
				</MentBox>
			)}
			{isVideo && disable && (
				<MentBox>
					영상이 너무 길어요.
					<br /> 3분 27초로 이동해주세요!
				</MentBox>
			)}
			{isVideo && !disable && (
				<MentBox>
					잘하셨어요!
					<br />
					다음으로 넘어가 볼까요?
				</MentBox>
			)}

			<VideoBox>
				{isVideo ? (
					<VideoPlayer setPlayerTime={setPlayerTime} />
				) : (
					<YouTubeImg />
				)}
			</VideoBox>
			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						if (!isVideo && !disable) {
							getSpeech(
								'영상속 중요한 부분만 보고싶다면 아래의 빨간 바를 눌러서 원하는 재생 위치에 두면 돼요'
							);
						}
						if (isVideo && disable) {
							getSpeech('영상이 너무 길어요. 3분 27초로 이동해주세요!');
						}
						if (isVideo && !disable) {
							getSpeech('잘하셨어요 다음으로 넘어가볼까요?');
						}
					}}
				/>
				<ApplyButton
					disabled={disable}
					onClick={() => {
						setIsVideo(true);
						setDisable(true);
						if (isCorrect == true && isVideo == true) {
							navigate('/curriculum9');
						}
					}}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</LevelTestLayout>
	);
}

const VideoBox = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 60px;
`;

const YouTubeImg = styled(YouTube)`
	width: 90vw;
	height: 200px;
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;
