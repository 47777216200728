import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Content from '../../components/Content';
import Title from '../../components/Title';
import ApplyButton from '../../components/button/ApplyButton';
import InputButton from '../../components/button/InputButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { applyState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';

export default function Apply3() {
	const navigate = useNavigate();
	const [apply, setApply] = useRecoilState(applyState);
	const [name, setName] = useState<string>(apply.name || '');
	const [phone, setPhone] = useState<string>(apply.phoneNumber || '');

	const handleName = (e: ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
	};

	const handlePhone = (e: ChangeEvent<HTMLInputElement>) => {
		// 숫자만 남기고 나머지 문자 제거
		const digits = e.target.value.replace(/\D/g, '');

		// 전화번호 형식에 맞게 가공
		let formattedNumber = '';
		if (digits.length > 0) {
			formattedNumber += digits.substring(0, 3);
		}
		if (digits.length >= 4) {
			formattedNumber += '-' + digits.substring(3, 7);
		}
		if (digits.length >= 8) {
			formattedNumber += '-' + digits.substring(7, 11);
		}

		setPhone(formattedNumber);
	};

	const submit = () => {
		setApply({ ...apply, name: name, phoneNumber: phone });
	};

	return (
		<ApplyLayout>
			<TopHeader page='/apply/2' />
			<Title>
				먼저 이름, 전화번호를
				<br />
				입력해주세요!
			</Title>
			<Content>
				<InputButton
					type='text'
					label='이름'
					placeholder='홍길동'
					value={name}
					onChange={handleName}
				/>
				<InputButton
					type='text'
					label='전화번호'
					placeholder='010-1234-5678'
					value={phone}
					onChange={handlePhone}
				/>
			</Content>
			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						getSpeech('먼저, 이름과 전화번호를 입력해주세요');
					}}
				/>
				<ApplyButton
					onClick={() => {
						submit();
						navigate('/apply/4');
					}}
					disabled={!(name && phone)}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}
