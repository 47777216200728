import { styled } from 'styled-components';
import { ReactComponent as Volume } from '../../asset/svg/Volume.svg';

interface VolumeButtonProps {
	onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export default function VolumeButton({ onClick }: VolumeButtonProps) {
	return (
		<VolumeBtn onClick={onClick}>
			<SVolume />
		</VolumeBtn>
	);
}

const VolumeBtn = styled.div`
	display: flex;
	justify-content: end;
	margin-bottom: 3%;
`;

const SVolume = styled(Volume)`
	cursor: pointer;
`;
