import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { DeviceLevel } from '../../asset/data/DeviceLevel';
import Content from '../../components/Content';
import Title from '../../components/Title';
import ApplyButton from '../../components/button/ApplyButton';
import SelectButton from '../../components/button/SelectButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { applyState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';

export default function Apply7() {
	const navigate = useNavigate();
	const [apply, setApply] = useRecoilState(applyState);
	const [level, setLevel] = useState<number>(
		apply.digitalDeviceUsageLevelId || 0
	);

	const submit = () => {
		setApply({ ...apply, digitalDeviceUsageLevelId: level });
	};

	return (
		<ApplyLayout>
			<TopHeader page='/apply/6' />
			<Title>
				디지털 기기를 얼마나
				<br />잘 활용한다고 생각하시나요?
			</Title>
			<Content>
				{DeviceLevel.map((x) => {
					return (
						<SelectButton
							key={x.id}
							value={x.usage_level}
							onClick={() => setLevel(x.id)}
							selected={x.id === level}
						/>
					);
				})}
			</Content>
			<ApplyFooter>
				<VolumeButton
					onClick={() =>
						getSpeech('디지털 기기를, 얼마나 잘 활용한다고 생각하시나요?')
					}
				/>
				<ApplyButton
					onClick={() => {
						submit();
						navigate('/apply/8');
					}}
					disabled={level === 0}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}
