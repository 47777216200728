import { useEffect, useRef, useState } from 'react';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import { authAxios } from '../../api/customAxios';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { correctAnswersState, tokenState } from '../../recoil/atom';
import theme from '../styles/theme';

declare global {
	interface Window {
		initMap: () => void;
	}
}

window.initMap = () => {
	// Google Maps API 로드 시 호출될 함수
	// 이 함수 내에서 Google Maps 관련 작업을 수행합니다.
};
function LevelTest14() {
	const [correctAnswers, setCorrectAnswers] =
		useRecoilState(correctAnswersState); // Recoil atom 상태 추가	// const [touchPosition, setTouchPosition] = useState({ x: 0, y: 0 });
	// const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
	const [dragging, setDragging] = useState(false);
	const [dragPosition, setDragPosition] = useState<{ x: number; y: number }[]>(
		[]
	);
	const [touchLocations, setTouchLocations] = useState<
		{ x: number; y: number }[]
	>([]);

	const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
		setDragging(true);
		handleMouseMove(event); // Call handleMouseMove directly to set initial position
	};

	const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = () => {
		setDragging(false);
	};

	// const touchLocation = [];

	const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
		if (!dragging) return;

		const dragX = event.clientX;
		const dragY = event.clientY;
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		const normalizedX = (dragX / screenWidth) * 100;
		const normalizedY = (dragY / screenHeight) * 100;

		setTouchLocations((prevLocations) => [
			...prevLocations,
			{ x: normalizedX, y: normalizedY },
		]);
	};

	// const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = (event) => {
	// 	const touch = event.touches[0];
	// 	const screenWidth = window.innerWidth;
	// 	const screenHeight = window.innerHeight;

	// 	const touchX = touch.clientX;
	// 	const touchY = touch.clientY;

	// 	const normalizedX = (touchX / screenWidth) * 100;
	// 	const normalizedY = (touchY / screenHeight) * 100;

	// 	// setTouchPosition({ x: normalizedX, y: normalizedY });
	// 	setTouchLocations((prevLocations) => [
	// 		...prevLocations,
	// 		{ x: normalizedX, y: normalizedY },
	// 	]);
	// };

	const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
		const clickX = event.clientX;
		const clickY = event.clientY;
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		const normalizedX = (clickX / screenWidth) * 100;
		const normalizedY = (clickY / screenHeight) * 100;

		// setClickPosition({ x: normalizedX, y: normalizedY });
		setTouchLocations((prevLocations) => [
			...prevLocations,
			{ x: normalizedX, y: normalizedY },
		]);
	};
	console.log('클릭', touchLocations);
	console.log('드래그', dragPosition);
	const navigate = useNavigate();
	const mapRef = useRef<HTMLDivElement>(null);
	const searchRef = useRef<HTMLInputElement>(null);
	const [isCorrect, setIsCorrect] = useState(false);
	const token = useRecoilValue(tokenState);

	useEffect(() => {
		// 이미 initMap 함수가 전역 스코프에 정의되어 있으므로,
		// Google Maps API 스크립트가 로드되면 바로 호출합니다.
		if (window.google.maps && window.google) {
			initMap();
		} else {
			console.error('Google Maps API not loaded.');
		}
		console.log('맵', mapRef);
		console.log('서치', searchRef);
		console.log(isCorrect);
	}, []);

	const initMap = () => {
		if (mapRef.current) {
			const map = new window.google.maps.Map(mapRef.current, {
				center: { lat: 37.5665, lng: 126.978 },
				zoom: 15,
				disableDefaultUI: true,
			});

			const marker = new window.google.maps.Marker({
				position: { lat: 37.57589241187062, lng: 126.97681468220921 },
				map: map,
				title: '광화문',
			});

			// 검색 버튼 클릭 시 실행되는 함수
			const searchLocation = () => {
				const searchValue = searchRef.current?.value;
				if (searchValue) {
					const geocoder = new window.google.maps.Geocoder();
					geocoder.geocode({ address: searchValue }, (results, status) => {
						if (status === 'OK' && results?.[0]) {
							map.setCenter(results[0].geometry.location);
						} else {
							console.error(
								'Geocode was not successful for the following reason:',
								status
							);
						}
					});
				}
			};

			// 검색 버튼 클릭 이벤트 추가
			const searchButton = document.getElementById('search-button');
			if (searchButton) {
				searchButton.addEventListener('click', searchLocation);
			}
		} else {
			console.error('Map container not found.');
		}
	};

	const reValue = () => {
		if (searchRef.current?.value === '광화문') {
			setIsCorrect(true);
		} else if (searchRef.current?.value !== '광화문') {
			return setIsCorrect(false);
		}
	};
	console.log(isCorrect);

	const userAnswer = searchRef.current?.value;
	console.log(userAnswer);
	const fetchCode = async () => {
		if (isCorrect == true) {
			setCorrectAnswers(correctAnswers + 1);
		}
		try {
			const user = await authAxios(token).post(`/quiz/evaluation`, {
				quizId: 12,
				userAnswer: userAnswer,
				isCorrect: isCorrect,
			});
			const userClick = await authAxios(token).post(`/quiz/curriculum`, {
				quizId: 12,
				clickLocation: touchLocations,
			});

			console.log(userClick);
			console.log(user);
			console.log('정답', isCorrect);

			console.log(user);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Container
			onClick={handleClick}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
		>
			<Content>
				<TopHeader title='나에게 맞는 수업 찾기' page='/levelTest13' />
				<RangeBarWrap>
					<div>14/14</div>
					<RangeBar type='range' min={0} max={14} step={14} defaultValue={14} />
				</RangeBarWrap>
				<MentBox>
					지도에서
					<br /> 광화문을 찾아볼까요?
				</MentBox>
				<MapWrap>
					<SearchBox>
						<InputBox
							type='text'
							ref={searchRef}
							placeholder='광화문을 검색해보세요'
						/>
						<PiMagnifyingGlass
							className='search-button'
							id='search-button'
							onClick={() => {
								reValue();
							}}
						/>
					</SearchBox>
					<MapContainer ref={mapRef} />
				</MapWrap>
				<ApplyFooter>
					<VolumeButton />
					<ApplyButton
						onClick={() => {
							fetchCode();
							navigate('/levelTest15');
						}}
					>
						저장
					</ApplyButton>
				</ApplyFooter>
			</Content>
		</Container>
	);
}

const Container = styled.div`
	height: 100vh;
	margin: 0 auto;
	background-color: ${theme.white};
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 85%;
	height: 100vh;
	margin: 0 auto;
`;

const SearchBox = styled.div`
	position: absolute;
	top: 40vh;
	z-index: 20;
	width: 90%;
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

	.search-button {
		width: 24px;
		height: 24px;
		color: gray;

		&:hover {
			cursor: pointer;
		}
	}
`;

const InputBox = styled.input`
	width: 80%;
	border: none;
`;

const MapWrap = styled.div`
	width: 100vw;
	margin-top: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const MapContainer = styled.div`
	width: 100%;
	height: 250px; /* Adjust the height as needed */
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;

export default LevelTest14;
