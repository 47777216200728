export const Routes = [
	{
		id: 1,
		route: '가족/지인',
	},
	{
		id: 2,
		route: '뉴스/기사/블로그',
	},
	{
		id: 3,
		route: '인터넷 검색',
	},
	{
		id: 4,
		route: 'SNS',
	},
	{
		id: 5,
		route: '센터/복지관 등 기관',
	},
	{
		id: 6,
		route: '기타',
	},
];
