import styled from 'styled-components';
import theme from '../pages/styles/theme';
import BottomTabs from './tab/BottomTabs';

interface HomeLayoutProps {
	children: React.ReactNode;
}

export default function HomeLayout({ children }: HomeLayoutProps) {
	return (
		<Container>
			<Content>{children}</Content>
			<Footer>
				<BottomTabs />
			</Footer>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	height: 100vh;
	background-color: ${theme.white};
`;

const Content = styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: auto;
	-ms-overflow-style: none; /* 인터넷 익스플로러 */
	scrollbar-width: none; /* 파이어폭스 */
	::-webkit-scrollbar {
		display: none; /* 크롬, 사파리, 오페라, 엣지 */
	}
`;

const Footer = styled.div`
	width: 100%;
	background-color: ${(props) => props.theme.background};
`;
