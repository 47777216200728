import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { getNameSelector } from '../../recoil/atom';

export default function UserInfo() {
	const name = useRecoilValue(getNameSelector);

	return (
		<Section>
			<Img src={require('../../asset/jpg/ddokDLogo.png')} alt='profileImg' />
			<TitleText>
				<BoldText>{name}님 안녕하세요!</BoldText>
				<SmallText>디지털 기초과정</SmallText>
			</TitleText>
		</Section>
	);
}

const Section = styled.div`
	width: 90%;
	margin: 5% auto;
	display: flex;
	align-items: center;
`;

const Img = styled.img`
	width: 70px;
	height: 70px;
	margin-right: 20px;
	border-radius: 50%;
`;

const TitleText = styled.div``;

const BoldText = styled.div`
	font-size: 20px;
	font-weight: 700;
`;

const SmallText = styled.div`
	margin-top: 5%;
	color: #898989;
	font-size: 12px;
`;
