import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import { authAxios } from '../../api/customAxios';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { correctAnswersState, tokenState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';
import theme from '../styles/theme';

function LevelTest11() {
	const [isCorrect, setIsCorrect] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [correctAnswers, setCorrectAnswers] =
		useRecoilState(correctAnswersState); // Recoil atom 상태 추가	// const [touchPosition, setTouchPosition] = useState({ x: 0, y: 0 });
	// const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
	const [dragging, setDragging] = useState(false);
	const [dragPosition, setDragPosition] = useState<{ x: number; y: number }[]>(
		[]
	);
	const [touchLocations, setTouchLocations] = useState<
		{ x: number; y: number }[]
	>([]);

	const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
		setDragging(true);
		handleMouseMove(event); // Call handleMouseMove directly to set initial position
	};

	const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = () => {
		setDragging(false);
	};

	// const touchLocation = [];

	const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
		if (!dragging) return;

		const dragX = event.clientX;
		const dragY = event.clientY;
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		const normalizedX = (dragX / screenWidth) * 100;
		const normalizedY = (dragY / screenHeight) * 100;

		setTouchLocations((prevLocations) => [
			...prevLocations,
			{ x: normalizedX, y: normalizedY },
		]);
	};

	// const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = (event) => {
	// 	const touch = event.touches[0];
	// 	const screenWidth = window.innerWidth;
	// 	const screenHeight = window.innerHeight;

	// 	const touchX = touch.clientX;
	// 	const touchY = touch.clientY;

	// 	const normalizedX = (touchX / screenWidth) * 100;
	// 	const normalizedY = (touchY / screenHeight) * 100;

	// 	// setTouchPosition({ x: normalizedX, y: normalizedY });
	// 	setTouchLocations((prevLocations) => [
	// 		...prevLocations,
	// 		{ x: normalizedX, y: normalizedY },
	// 	]);
	// };

	const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
		const clickX = event.clientX;
		const clickY = event.clientY;
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		const normalizedX = (clickX / screenWidth) * 100;
		const normalizedY = (clickY / screenHeight) * 100;

		// setClickPosition({ x: normalizedX, y: normalizedY });
		setTouchLocations((prevLocations) => [
			...prevLocations,
			{ x: normalizedX, y: normalizedY },
		]);
	};
	console.log('클릭', touchLocations);
	console.log('드래그', dragPosition);
	const token = useRecoilValue(tokenState);
	const navigate = useNavigate();

	console.log(inputValue);
	console.log(isCorrect);

	useEffect(() => {
		if (inputValue === '30586') {
			setIsCorrect(true);
		} else {
			setIsCorrect(false);
		}
		console.log(correctAnswers);
	}, [inputValue]);

	const fetchCode = async () => {
		if (isCorrect == true) {
			setCorrectAnswers(correctAnswers + 1);
		}
		try {
			const user = await authAxios(token).post(`/quiz/evaluation`, {
				quizId: 9,
				userAnswer: String(inputValue),
				isCorrect: isCorrect,
			});
			const userClick = await authAxios(token).post(`/quiz/curriculum`, {
				quizId: 9,
				clickLocation: touchLocations,
			});

			console.log(userClick);
			console.log(user);
			console.log('정답', isCorrect);

			console.log(user);
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<Container
			onClick={handleClick}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
		>
			<Content>
				<TopHeader title='나에게 맞는 수업 찾기' page='/levelTest10' />
				<RangeBarWrap>
					<div>11/14</div>
					<RangeBar type='range' min={0} max={14} step={11} defaultValue={11} />
				</RangeBarWrap>
				<MentBox>
					문자로 인증번호가 도착했어요.
					<br /> 아래 창에 인증번호를
					<br /> 입력해주세요!
				</MentBox>
				<AuthBox>
					<AuthMessage>
						띠링에서 보낸 인증번호 입니다.
						<br />
						[30586] 을 인증번호 창에 입력하세요.
					</AuthMessage>
					<AnswerInput
						type='text'
						placeholder=' 인증번호를 입력하세요'
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
					/>
				</AuthBox>
				<ApplyFooter>
					<VolumeButton
						onClick={() => {
							getSpeech(' dkssud');
						}}
					/>
					<ApplyButton
						onClick={() => {
							fetchCode();
							navigate('/levelTest12');
						}}
					>
						다음
					</ApplyButton>
				</ApplyFooter>
			</Content>
		</Container>
	);
}

const Container = styled.div`
	height: 100vh;
	margin: 0 auto;
	background-color: ${theme.white};
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 85%;
	height: 100vh;
	margin: 0 auto;
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;

const AuthBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 70vw;
`;

const AuthMessage = styled.div`
	display: flex;
	padding-left: 20px;
	justify-content: baseline;
	align-items: center;
	width: 100%;
	height: 56px;
	border-radius: 15px;
	background: #d9d9d9;
	margin-top: 60px;
`;

const AnswerInput = styled.input`
	width: 70vw;
	height: 36px;
	border: 1px solid var(--d-grey-2, #e6e6e6);
	border-radius: 10px;
	margin-top: 30px;
`;

export default LevelTest11;
