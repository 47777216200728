import { useEffect, useState } from 'react';
import { BiBluetooth, BiSolidVolumeMute } from 'react-icons/bi';
import { BsFileLock2Fill } from 'react-icons/bs';
import { FiWifi } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { getSpeech } from '../../utils/getSpeech';

type ApplyButtonProps = {
	onOff?: boolean;
};

export default function Curriculum9() {
	const [disable, setDisable] = useState(false);
	const [isCorrect, setIsCorrect] = useState(false);
	const [wifi, setWifi] = useState(true);
	const [volumeMute, setVolumeMute] = useState(false);
	const [bluetooth, setBluetooth] = useState(false);
	const [fileLock, setFileLock] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (wifi == true) {
			setIsCorrect(false);
			setDisable(false);
		}
		if (wifi == false) {
			setDisable(false);
			setIsCorrect(true);
		}
	}, [wifi]);

	const handleWifiClick = () => {
		setWifi(false); // 토글
	};

	const handleVolumeMuteClick = () => {
		setVolumeMute((prevVolumeMute) => !prevVolumeMute); // 토글
	};

	const handleBluetoothClick = () => {
		setBluetooth((prevBluetooth) => !prevBluetooth); // 토글
	};

	const handleFileLockClick = () => {
		setFileLock((prevFileLock) => !prevFileLock); // 토글
	};
	return (
		<ApplyLayout>
			<TopHeader title='스마트폰 사용법 기초' page='/curriculum8' />
			<RangeBarWrap>
				<div>9/16</div>
				<RangeBar type='range' min={0} max={16} step={9} defaultValue={9} />
			</RangeBarWrap>
			{!disable && !isCorrect && wifi && (
				<MentBox>
					제어센터는 환경설정에
					<br /> 들어가지 않고도 간단한 설정을
					<br /> 바로 할 수 있는 기능이에요
				</MentBox>
			)}
			{disable && !isCorrect && wifi && (
				<MentBox>와이파이를 찾아 꺼주세요</MentBox>
			)}
			{!disable && !wifi && isCorrect && (
				<MentBox>
					잘하셨어요!
					<br />
					다음으로 넘어가 볼까요?
				</MentBox>
			)}
			<ButtonWrap>
				<Circle onOff={wifi} onClick={handleWifiClick}>
					<FiWifi className='logo' />
				</Circle>
				<Circle onOff={volumeMute} onClick={handleVolumeMuteClick}>
					<BiSolidVolumeMute className='logo' />
				</Circle>
				<Circle onOff={bluetooth} onClick={handleBluetoothClick}>
					<BiBluetooth className='logo' />
				</Circle>
				<Circle onOff={fileLock} onClick={handleFileLockClick}>
					<BsFileLock2Fill className='logo' />
				</Circle>
			</ButtonWrap>
			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						if (!disable && !isCorrect && wifi) {
							getSpeech(
								'제어센터는 환경설정에 들어가지 않고도 간단한 설정을 바로 할 수 있는 기능이에요'
							);
						}
						if (disable && !isCorrect && wifi) {
							getSpeech('와이파이를 찾아 꺼주세요');
						}
						if (!disable && !wifi && isCorrect) {
							getSpeech('잘하셨어요 다음으로 넘어가볼까요?');
						}
					}}
				/>
				<ApplyButton
					disabled={disable}
					onClick={() => {
						if (!disable && !wifi && isCorrect) {
							navigate('/curriculum10');
						}
						setDisable(true);
					}}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}

const ButtonWrap = styled.div`
	margin-top: 75px;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
`;

const Circle = styled.div<ApplyButtonProps>`
	width: 50px;
	height: 50px;
	border-radius: 50px;
	background-color: ${(props) => (props.onOff ? '#4364fa' : '#d3d3d3')};
	display: flex;
	justify-content: center;
	align-items: center;

	.logo {
		width: 50%;
		height: 50%;
		color: ${(props) => (props.onOff ? '#fff' : '#b2b2b2')};
	}
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;
