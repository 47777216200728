import React from 'react';
import { styled } from 'styled-components';

interface ContentProps {
	children: React.ReactNode;
}

export default function Content({ children }: ContentProps) {
	return <Container>{children}</Container>;
}

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
