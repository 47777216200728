import { styled } from 'styled-components';

import theme from '../../pages/styles/theme';

interface ApplyButtonProps {
	disabled?: boolean;
}

const ApplyButton = styled.button<ApplyButtonProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 52px;
	padding: 0 24px 0 24px;
	border: none;
	border-radius: 11px;
	color: #fff;
	background-color: ${(props) => (props.disabled ? theme.D_grey3 : theme.pink)};
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
`;

export default ApplyButton;
