interface DeviceLevelType {
	id: number;
	usage_level: string;
}

export const DeviceLevel: DeviceLevelType[] = [
	{
		id: 1,
		usage_level: '매우 잘 활용할 수 있다',
	},
	{
		id: 2,
		usage_level: '웬만한 기능은 활용할 수 있다',
	},
	{
		id: 3,
		usage_level: '기본적인 기능만 활용 가능하다',
	},
	{
		id: 4,
		usage_level: '전혀 사용할 줄 모른다',
	},
];
