import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { baseAxios } from '../../api/customAxios';
import Content from '../../components/Content';
import Title from '../../components/Title';
import ApplyButton from '../../components/button/ApplyButton';
import InputButton from '../../components/button/InputButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { getSpeech } from '../../utils/getSpeech';

export default function FindPw() {
	const navigate = useNavigate();
	const [id, setId] = useState<string>('');
	const [name, setName] = useState<string>('');
	const [phone, setPhone] = useState<string>('');

	const handleId = (e: ChangeEvent<HTMLInputElement>) => {
		setId(e.target.value);
	};

	const handleName = (e: ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
	};

	const handlePhone = (e: ChangeEvent<HTMLInputElement>) => {
		const digits = e.target.value.replace(/\D/g, '');

		let formattedNumber = '';
		if (digits.length > 0) {
			formattedNumber += digits.substring(0, 3);
		}
		if (digits.length >= 4) {
			formattedNumber += '-' + digits.substring(3, 7);
		}
		if (digits.length >= 8) {
			formattedNumber += '-' + digits.substring(7, 11);
		}

		setPhone(formattedNumber);
	};

	const notify = async (pw: string) => {
		toast.success(
			<div>
				변경된 비밀번호는 <br />
				{pw} 입니다.
			</div>,
			{
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 20000,
				closeOnClick: false,
				theme: 'colored',
			}
		);
	};

	const submit = async () => {
		try {
			const res = await baseAxios.post('auth/password', {
				name: name,
				phoneNumber: phone,
				nickname: id,
			});
			await notify(res.data.newPassword);
			alert('임시 비밀번호가 전송됩니다');
			navigate('/signin');
		} catch (err) {
			alert('정보를 정확히 입력해주세요');
		}
	};

	return (
		<ApplyLayout>
			<TopHeader title='비밀번호 찾기' page='/login' />
			<Title>
				가입하실 때 입력하신
				<br />
				정보를 입력해주세요
			</Title>
			<Content>
				<InputButton
					type='text'
					label='이름'
					placeholder='홍길동'
					value={name}
					onChange={handleName}
				/>
				<InputButton
					type='text'
					label='아이디'
					placeholder='아이디를 입력하세요'
					value={id}
					onChange={handleId}
				/>
				<InputButton
					type='tel'
					label='전화번호'
					placeholder='010-1234-5678'
					value={phone}
					onChange={handlePhone}
				/>
			</Content>
			<ApplyFooter>
				<VolumeButton
					onClick={() =>
						getSpeech('가입하실 때, 입력하신 정보를, 입력해주세요')
					}
				/>
				<ApplyButton disabled={!(name && id && phone)} onClick={submit}>
					찾기
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}
