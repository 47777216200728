import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import VolumeButton from '../../components/button/VolumeButton';
import CurriculumLayout from '../../layout/CurriculumLayout';
import TopHeader from '../../layout/header/TopHeader';
import CurriculumTabs from '../../layout/tab/CurriculumTabs';

export default function Jungle() {
	const [isCorrect, setIsCorrect] = useState(false);
	const [disable, setDisable] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const navigate = useNavigate();

	const handleChange = (e: any) => {
		setInputValue(e.target.value);
	};

	useEffect(() => {
		if (inputValue === '안녕하세요') {
			setIsCorrect(true);
			setDisable(false);
		} else {
			setIsCorrect(false);
		}
	}, [inputValue]);

	console.log(inputValue);
	console.log(isCorrect);
	return (
		<CurriculumLayout>
			<TopHeader title='나에게 맞는 수업 찾기' page='/curriculum15' />
			<RangeBarWrap>
				<div>16/16</div>
				<RangeBar type='range' min={0} max={16} step={16} defaultValue={16} />
			</RangeBarWrap>
			<MentBox>
				이곳은 정글이에요
				<br />
				낚시를 하려면 바다로 가야해요
			</MentBox>

			<WrapFooter>
				<VolumeButton />
				<Footer>
					<CurriculumTabs />
				</Footer>
			</WrapFooter>
		</CurriculumLayout>
	);
}

const WrapFooter = styled.footer`
	margin-top: auto;
	width: 100vw;
`;

const Footer = styled.div`
	width: 100%;
	background-color: ${(props) => props.theme.background};
`;

const CopyBox = styled.input`
	width: 90%;
	height: 36px;
	flex-shrink: 0;
	border-radius: 10px;
	border: 1px solid var(--D_Pink, #fd698d);
	display: flex;
	align-items: center;
	padding-left: 15px;
	margin-top: 10px;
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	margin-bottom: 40px;
	line-height: 150%; /* 36px */
`;
