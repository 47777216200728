import { ReactComponent as Company } from '../svg/CompanyIcon.svg';
import { ReactComponent as Font } from '../svg/FontSizeIcon.svg';
import { ReactComponent as Headphone } from '../svg/HeadphoneIcon.svg';
import { ReactComponent as Megaphone } from '../svg/MegaPhone.svg';
import { ReactComponent as Setting } from '../svg/SettingIcon.svg';
import { ReactComponent as TTS } from '../svg/TTSIcon.svg';
import { ReactComponent as Users } from '../svg/UsersIcon.svg';

export const SettingData1 = [
	{
		id: 1,
		icon: Font,
		title: '글자 크기',
		subtitle: '글자의 크기를 키우거나 줄여 쉽게 읽을 수 있습니다',
	},
	{
		id: 2,
		icon: TTS,
		title: '음성 (TTS)',
		subtitle: '음성으로 읽어주는 기능을 키거나 종료할 수 있습니다',
	},
	{
		id: 3,
		icon: Setting,
		title: '설정 초기화',
		subtitle: '어플리케이션 설정을 기본 값으로 되돌릴 수 있습니다',
	},
];

export const SettingData2 = [
	{
		id: 4,
		icon: Company,
		title: '회사 소개',
		subtitle: '똑디의 정보를 확인할 수 있습니다',
		link: 'https://jet-koi-be0.notion.site/3b09de6b8d934d2ab504bd5c6d57f1d4',
	},
	{
		id: 5,
		icon: Headphone,
		title: '고객센터',
		subtitle: '어플리케이션 관련 문의를 할 수 있습니다',
		link: 'https://jet-koi-be0.notion.site/446f8d2896124cdca7a9af0ba546dc57',
	},
	{
		id: 6,
		icon: Megaphone,
		title: '공지사항',
		subtitle: '어플리케이션 관련 공지를 확인할 수 있습니다',
		link: 'https://jet-koi-be0.notion.site/7e1b58a5d5064cc2b9d4e21e86c82103?v=f46ac5cb11034e85b457fd4f065edd46',
	},
];

export const SettingData3 = [
	{
		id: 7,
		icon: Users,
		title: '똑디 커뮤니티 바로가기',
		subtitle: '시니어 관련 정보 확인 및 소통 커뮤니티',
		link: 'https://open.kakao.com/o/gthAyDCf',
	},
];
