import styled from 'styled-components';
import theme from '../pages/styles/theme';

interface CheckboxProps {
	title: string;
	checked: boolean;
	onChange(): void;
}

export default function Checkbox({ title, checked, onChange }: CheckboxProps) {
	return (
		<Label htmlFor={title}>
			<CheckBoxInput
				type='checkbox'
				id={title}
				checked={checked}
				onChange={onChange}
			/>
			<LabelText>{title}</LabelText>
		</Label>
	);
}

const Label = styled.label`
	display: flex;
	align-items: center;
	margin: 1% 0;
	cursor: pointer;
`;

const CheckBoxInput = styled.input.attrs({ type: 'checkbox' })`
	margin-right: 10px;
	// appearance는 IE에서 지원하지 않음
	appearance: none;
	width: 1.2rem;
	height: 1.2em;
	border: 1.5px solid gainsboro;
	border-radius: 0.35rem;

	&:checked {
		border-color: transparent;
		background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
		background-size: 100% 100%;
		background-position: 50%;
		background-repeat: no-repeat;
		background-color: ${theme.pink};
	}
`;

const LabelText = styled.span`
	font-size: 16px;
`;
