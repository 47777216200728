import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import { ReactComponent as Lock } from '../../asset/svg/LockIcon.svg';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import LevelTestLayout from '../../layout/LevelTestLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { getNameSelector, getTotalCorrectAnswers } from '../../recoil/atom';

export default function LevelTest15() {
	const totalCorrectAnswers = useRecoilValue(getTotalCorrectAnswers);
	const name = useRecoilValue(getNameSelector);
	const navigate = useNavigate();
	return (
		<LevelTestLayout>
			<TopHeader title='나에게 맞는 수업 찾기' page='/levelTest14' />
			<RangeBarWrap>
				<p>
					문제풀이가 모두 끝났습니다
					<br />총 14문제 중 {totalCorrectAnswers}문제를 맞추셨어요
				</p>
			</RangeBarWrap>
			<RecommendBox>
				<RecommendMent>
					{name}님,
					<br />
					스마트폰에 친숙해지는
					<br />
					기초 수업 과정을 추천드릴게요.
				</RecommendMent>
				<CurriculumBox
					onClick={() => {
						navigate('/curriculum1');
					}}
				>
					<Ment>
						스마트폰 사용법
						<br />
						기초부터 이해하기
					</Ment>
					<MentSmall>
						<div>
							<div>난이도 하</div>
							<div>총 16강</div>
						</div>
					</MentSmall>
				</CurriculumBox>

				<CardWrapper>
					<Ment>
						스마트폰 사용법
						<br />
						기초부터 이해하기
					</Ment>
					<MentSmall>
						<div>
							<div>난이도 하</div>
							<div>총 6강</div>
						</div>
					</MentSmall>
					<LockImg>
						<Lock />
					</LockImg>
				</CardWrapper>
			</RecommendBox>
			<ApplyFooter>
				<VolumeButton />
				<ApplyButton onClick={() => navigate('/home')}>홈으로</ApplyButton>
			</ApplyFooter>
		</LevelTestLayout>
	);
}
const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 90%;
	height: 125px;
	margin-left: 25px;
	margin-top: 10px;
	position: relative;
	border-radius: 12px;
	background: conic-gradient(
			from 134deg at 54.73% 54.08%,
			rgba(255, 255, 255, 0.14) 0deg,
			rgba(255, 255, 255, 0) 360deg
		),
		linear-gradient(0deg, #7fabee 0%, #7fabee 100%), #fff;

	&::after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		border-radius: 12px;
		background: rgba(217, 217, 217, 0.5);
	}
`;

const LockImg = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 15px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.65);
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	z-index: 1;
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: baseline;
	flex-direction: column;
	margin-top: 35px;
	font-weight: 500;
	font-size: 24px;
	color: var(--Black, #252525);
	font-family: Pretendard;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 36px */
	letter-spacing: -0.408px;
`;

const RecommendBox = styled.div`
	width: 100vw;
	border-radius: 20px 20px 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.22);
	margin-top: 20px;
`;

const RecommendMent = styled.div`
	width: 238px;
	height: 60px;
	margin-top: 25px;
	margin-left: 25px;
	color: var(--Black, #252525);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 135%; /* 27px */
	letter-spacing: -0.408px;
`;

const CurriculumBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 31px;
	margin-left: 25px;
	width: 90%;
	height: 128px;
	background: conic-gradient(
			from 115deg at 54.73% 54.08%,
			#fab893 0deg,
			rgba(255, 255, 255, 0) 360deg
		),
		linear-gradient(0deg, #fd9569, #fd9569), #e7a783;
	border-radius: 12px;
`;

const Ment = styled.p`
	color: #fff;
	font-family: Pretendard;
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: 135%; /* 29.7px */
	letter-spacing: -0.408px;
	margin-left: 18px;
`;

const MentSmall = styled.div`
	color: #fff;
	font-family: Pretendard;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 135%; /* 21.6px */
	letter-spacing: -0.408px;
	width: 95%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: end;
`;

const AccentMent = styled.p``;
