import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Experience } from '../../asset/data/EduExperience';
import Content from '../../components/Content';
import Title from '../../components/Title';
import ApplyButton from '../../components/button/ApplyButton';
import SelectButton from '../../components/button/SelectButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { applyState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';

export default function Apply8() {
	const navigate = useNavigate();
	const [apply, setApply] = useRecoilState(applyState);
	const [experience, setExperience] = useState<number>(
		apply.digitalEduExperienceId || 0
	);

	const submit = () => {
		setApply({ ...apply, digitalEduExperienceId: experience });
	};

	return (
		<ApplyLayout>
			<TopHeader page='/apply/7' />
			<Title>
				디지털 활용 교육을
				<br />
				들어본 경험이 있나요?
			</Title>
			<Content>
				{Experience.map((x) => {
					return (
						<SelectButton
							key={x.id}
							value={x.experience}
							onClick={() => setExperience(x.id)}
							selected={experience === x.id}
						/>
					);
				})}
			</Content>
			<ApplyFooter>
				<VolumeButton
					onClick={() => getSpeech('디지털 활용 교육을 들어본 경험이 있나요?')}
				/>
				<ApplyButton
					onClick={() => {
						submit();
						navigate('/apply/9');
					}}
					disabled={experience === 0}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}
