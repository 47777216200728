import React from 'react';
import { styled } from 'styled-components';

interface MainLayoutProps {
	children: React.ReactNode;
}

export default function MainLayout({ children }: MainLayoutProps) {
	return <Container>{children}</Container>;
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	height: 100vh;
	background-color: ${(props) => props.theme.white};
`;
