import styled from 'styled-components';
import { ReactComponent as Lock } from '../../asset/svg/LockIcon.svg';
import theme from '../../pages/styles/theme';

export default function UsePhoneIntensiveCard() {
	return (
		<CardWrapper>
			<CardTitle>
				스마트폰 사용법
				<br />
				심화과정
			</CardTitle>
			<FlexRow>
				<CardSubtitle>
					난이도 <CardBoldText>상</CardBoldText>
					<br />총 <CardBoldText>6강</CardBoldText>
				</CardSubtitle>
			</FlexRow>
			<LockImg>
				<Lock />
			</LockImg>
		</CardWrapper>
	);
}

const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 90%;
	height: 190px;
	margin: 3% auto;
	position: relative;
	border-radius: 12px;
	background: conic-gradient(
			from 134deg at 54.73% 54.08%,
			rgba(255, 255, 255, 0.14) 0deg,
			rgba(255, 255, 255, 0) 360deg
		),
		linear-gradient(0deg, #7fabee 0%, #7fabee 100%), #fff;
	box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);

	&::after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		border-radius: 12px;
		background: rgba(217, 217, 217, 0.5);
	}
`;

const CardTitle = styled.div`
	width: 85%;
	margin: 0 auto;
	color: ${theme.white};
	font-size: 24px;
	font-weight: 700;
	line-height: 135%;
`;

const FlexRow = styled.div`
	width: 85%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const CardSubtitle = styled.div`
	color: ${theme.white};
	font-weight: 400;
	line-height: 135%;
`;

const CardBoldText = styled.span`
	font-weight: 700;
`;

const LockImg = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 15px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.65);
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	z-index: 1;
`;
