import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { tokenState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';

interface ScrollImageProps {
	last?: boolean; // last prop의 타입을 정의
	active: boolean; // Add this line
	onClick: () => void;
}

function Curriculum11() {
	const [activeButton, setActiveButton] = useState('');
	const [isCorrect, setIsCorrect] = useState(false);
	const [disable, setDisable] = useState(false);
	const token = useRecoilValue(tokenState);
	const navigate = useNavigate();

	const handleButtonClick = (buttonName: string) => {
		setActiveButton(buttonName);

		if (buttonName === '김치찌개') {
			setIsCorrect(true);
			setDisable(false);
		} else {
			return setIsCorrect(false);
		}
	};

	console.log(isCorrect);

	console.log(activeButton);

	return (
		<ApplyLayout>
			<TopHeader title='스마트폰 사용법 기초' page='/curriculum10' />
			<RangeBarWrap>
				<div>11/16</div>
				<RangeBar type='range' min={0} max={16} step={11} defaultValue={11} />
			</RangeBarWrap>
			{!disable && !isCorrect && (
				<MentBox>
					배달 앱에서 다양한 매뉴를
					<br /> 확인하고, 원하는 매뉴를
					<br /> 선택하려고 해요
				</MentBox>
			)}
			{disable && !isCorrect && (
				<MentBox>
					화면을 위 아래로 스크롤 해서
					<br /> `김치찌개`를 찾아주세요!
				</MentBox>
			)}
			{!disable && isCorrect && (
				<MentBox>
					잘하셨어요!
					<br />
					다음으로 넘어가 볼까요?
				</MentBox>
			)}

			<ScrollBox>
				<ScrollImage
					src='image/bibimBap.png'
					active={activeButton === 'bibimBap'}
					onClick={() => handleButtonClick('bibimBap')}
				/>

				<ScrollImage
					src='image/samgyeTang.png'
					active={activeButton === 'samgyeTang'}
					onClick={() => handleButtonClick('samgyeTang')}
				/>

				<ScrollImage
					src='image/seaWeedSoup.png'
					active={activeButton === 'seaWeedSoup'}
					onClick={() => handleButtonClick('seaWeedSoup')}
				/>

				<ScrollImage
					src='image/kimchiZigae.png'
					last
					active={activeButton === '김치찌개'}
					onClick={() => handleButtonClick('김치찌개')}
				/>
			</ScrollBox>

			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						if (!disable && !isCorrect) {
							getSpeech(
								'배달 앱에서 다양한 매뉴를 확인하고, 원하는 매뉴를 선택하려고 해요'
							);
						}
						if (disable && !isCorrect) {
							getSpeech(
								'화면을 위 아래로 스크롤 해서 `김치찌개`를 찾아주세요!'
							);
						}
						if (!disable && isCorrect) {
							getSpeech('잘하셨어요 다음으로 넘어가볼까요?');
						}
					}}
				/>
				<ApplyButton
					disabled={disable}
					onClick={() => {
						if (!disable && isCorrect) {
							navigate('/curriculum12');
						}
						setDisable(true);
					}}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;

const ScrollBox = styled.div`
	width: 100vw;
	margin-top: 74px;
	overflow-y: scroll; /* 수직 스크롤바 표시 */
	overflow-x: hidden; /* 수평 스크롤바 숨김 */
	display: flex;
	flex-direction: column;
	justify-content: baseline;
	align-items: center;

	/* 수직 스크롤바 스타일링 */
	&::-webkit-scrollbar {
		width: 4px; /* 스크롤바 너비 */
	}

	&::-webkit-scrollbar-thumb {
		background-color: #fd698d; /* 스크롤바 색상 */
		border-radius: 10px; /* 스크롤바 코너 반경 */
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: #e45373; /* 스크롤바 호버시 색상 변경 */
	}

	/* 수직 스크롤바의 트랙 스타일링 */
	&::-webkit-scrollbar-track {
		background-color: #f5f5f5; /* 트랙 배경색 */
	}
`;

const ScrollImage = styled.img<ScrollImageProps>`
	width: 80%;
	height: 241px;
	border-radius: 10px;
	box-shadow: ${({ active }) =>
		active ? '' : '2px 2px 4px 0px rgba(0, 0, 0, 0.25)'};
	border: ${({ active }) => (active ? '2px solid #e45373' : '')};
	margin-bottom: 30px;
`;

export default Curriculum11;
