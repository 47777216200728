import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { ReactComponent as Arrow } from '../../asset/svg/ArrowBox.svg';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { getSpeech } from '../../utils/getSpeech';

export default function Curriculum5() {
	const [disable, setDisable] = useState(true);
	const [volume, setVolume] = useState(0); // 상태 초기값 설정
	const navigate = useNavigate();

	// 볼륨 인풋 값 변경 이벤트 핸들러
	const handleVolumeChange = (event: any) => {
		const newVolume = parseInt(event.target.value, 10); // 입력된 값을 정수로 변환
		setVolume(newVolume); // 상태 업데이트
	};

	useEffect(() => {
		if (volume === 100) {
			return setDisable(false);
		} else {
			return setDisable(true);
		}
	}, [volume]);
	console.log(disable);
	return (
		<ApplyLayout>
			<TopHeader title='스마트폰 사용법 기초' page='/curriculum4' />
			<RangeBarWrap>
				<div>5/16</div>
				<RangeBar type='range' min={0} max={16} step={5} defaultValue={5} />
			</RangeBarWrap>
			{disable ? (
				<MentBox>
					버튼을 누른 채로 옆으로 밀어
					<br /> 수치를 올릴 수 있어요
				</MentBox>
			) : (
				<MentBox>
					잘하셨어요! <br />
					다음으로 넘어가 볼까요?
				</MentBox>
			)}
			<VolumeBox>
				{volume}
				<VolumeInput
					type='range'
					min={0}
					max={100}
					defaultValue={0}
					onChange={handleVolumeChange}
				/>
				<ArrowB />
			</VolumeBox>
			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						if (disable) {
							getSpeech('버튼을 누른 채로 옆으로 밀어 수치를 올릴 수 있어요');
						}

						if (!disable) {
							getSpeech('잘하셨어요 다음으로 넘어가볼까요?');
						}
					}}
				/>
				<ApplyButton
					disabled={disable}
					onClick={() => navigate('/curriculum6')}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;

const VolumeBox = styled.div`
	width: 100%;
	margin-top: 135px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: baseline;
`;

const VolumeInput = styled.input`
	width: 100%;
	height: 4px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}
`;

const ArrowB = styled(Arrow)`
	width: 20px;
	height: 36px;
	margin-top: 20px;
	transform: rotate(-90deg);
`;
