interface CheckboxData {
	id: number;
	title: string;
	detail: boolean;
}

export const AGREE: CheckboxData[] = [
	{ id: 1, title: '(필수) 서비스 이용약관', detail: true },
	{ id: 2, title: '(필수) 개인정보 수집 및 이용동의', detail: true },
	{ id: 3, title: '(선택) 마케팅 목적 개인정보 수집동의', detail: true },
	{ id: 4, title: '(선택) 똑디 알림 동의', detail: false },
	{ id: 5, title: '(선택) 푸시 알림', detail: false },
];
