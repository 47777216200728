import { useEffect, useState } from 'react';
import { Link, useLocation, useMatch } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Clipboard } from '../../asset/svg/Clipboard.svg';
import { ReactComponent as Heart } from '../../asset/svg/HeartIcon.svg';
import { ReactComponent as Home } from '../../asset/svg/HomeIcon.svg';
import { ReactComponent as MiniGame } from '../../asset/svg/MiniGameIcon.svg';
import { ReactComponent as Setting } from '../../asset/svg/SettingIcon.svg';
import theme from '../../pages/styles/theme';

interface TextProps {
	active: string;
}

export default function BottomTabs() {
	const [gameMathName, setGameMathchName] = useState('');
	const url = useLocation();
	const path = url.pathname;

	useEffect(() => {
		if (path == '/game') {
			setGameMathchName('/game');
		} else if (path == '/level') {
			setGameMathchName('/level');
		}
	}, [path]);

	const homeMatch = useMatch('/home');
	const curriculumMatch = useMatch('/curriculum');
	const gameMatch = useMatch(`${gameMathName}`);
	const guideMatch = useMatch('/guide');
	const settingMatch = useMatch('/setting');

	return (
		<Container>
			<SLink to='/home'>
				<Text active={homeMatch !== null ? 'true' : 'false'}>
					<Home />홈
				</Text>
			</SLink>
			<SLink to='/curriculum'>
				<Text active={curriculumMatch !== null ? 'true' : 'false'}>
					<Clipboard />
					교육과정
				</Text>
			</SLink>
			<SLink to='/game'>
				<Text active={gameMatch !== null ? 'true' : 'false'}>
					<MiniGame />
					미니게임
				</Text>
			</SLink>
			<SLink to='/guide'>
				<Text active={guideMatch !== null ? 'true' : 'false'}>
					<Heart />앱 가이드
				</Text>
			</SLink>
			<SLink to='/setting'>
				<Text active={settingMatch !== null ? 'true' : 'false'}>
					<Setting />
					설정
				</Text>
			</SLink>
		</Container>
	);
}

const Container = styled.section`
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 5vh;
	padding: 18px;
	background: #fff;
	box-shadow: 0px -4px 14px 0px rgba(0, 0, 0, 0.1);
`;

const SLink = styled(Link)``;

const Text = styled.div<TextProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 45px;
	color: ${({ active }) => (active === 'true' ? `${theme.pink}` : '#aeaeae')};
	font-size: 12px;
	font-weight: 500;

	> svg {
		${({ active }) =>
			active === 'true' ? `fill: ${theme.pink}` : `fill: #aeaeae`};
	}
`;
