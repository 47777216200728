import styled from 'styled-components';
import ScrollContent from '../../components/ScrollContent';
import DigitalCompetencyCard from '../../components/card/DigitalCompetencyCard';
import UsePhoneBasicCard from '../../components/card/UsePhoneBasicCard';
import UsePhoneIntensiveCard from '../../components/card/UsePhoneIntensiveCard';
import HomeLayout from '../../layout/HomeLayout';

export default function Curriculm() {
	return (
		<HomeLayout>
			<Title>나에게 딱 맞는 교육과정</Title>
			<ScrollContent>
				<CardSection>
					<DigitalCompetencyCard />
					<UsePhoneBasicCard />
					<UsePhoneIntensiveCard />
				</CardSection>
			</ScrollContent>
		</HomeLayout>
	);
}

const Title = styled.div`
	width: 90%;
	margin: 5% auto;
	font-size: 20px;
	font-weight: 600;
`;

const CardSection = styled.section`
	width: 100%;
`;
