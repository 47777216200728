import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../pages/styles/theme';

export default function GuideKorail() {
	const navigate = useNavigate();

	return (
		<CardWrapper>
			<CardTitle>코레일 톡 사용 가이드</CardTitle>
			<CardSubtitle>
				&apos;코레일 톡&apos; 사용 방법을
				<br />
				같이 알아보아요
			</CardSubtitle>
			<CardButton onClick={() => navigate('/guide/korail')}>
				알아보기
			</CardButton>
		</CardWrapper>
	);
}

const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 90%;
	height: 190px;
	margin: 3% auto;
	position: relative;
	border-radius: 12px;
	background: conic-gradient(
			from 134deg at 54.73% 54.08%,
			rgba(255, 255, 255, 0.14) 0deg,
			rgba(255, 255, 255, 0) 360deg
		),
		#79bfff;
	box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);
`;

const CardTitle = styled.div`
	width: 85%;
	margin: 3% auto;
	color: ${theme.white};
	font-size: 24px;
	font-weight: 700;
	line-height: 135%;
`;

const CardSubtitle = styled.div`
	width: 85%;
	margin: 0 auto;
	color: ${theme.white};
	font-weight: 400;
	line-height: 135%;
`;

const CardButton = styled.button`
	margin: 0 20px 3% auto;
	padding: 8px 20px;
	border: none;
	border-radius: 30px;
	box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.15);
	background: #fffade;
	font-size: 14px;
	font-weight: 600;
`;
