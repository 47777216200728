import styled from 'styled-components';
import ScrollContent from '../../components/ScrollContent';
import GuideKorail from '../../components/card/GuideKorail';
import GuideTmoney from '../../components/card/GuideTmoney';
import GuideToss from '../../components/card/GuideToss';
import HomeLayout from '../../layout/HomeLayout';

export default function Guide() {
	return (
		<HomeLayout>
			<Title>교통 앱 사용 가이드</Title>
			<ScrollContent>
				<GuideKorail />
				<GuideTmoney />

				<CardSection />
				<Title>금융 앱 사용 가이드</Title>
				<GuideToss />
			</ScrollContent>
		</HomeLayout>
	);
}

const CardSection = styled.section`
	padding-bottom: 20px;
	border-bottom: 10px solid #f8f8f8;
`;

const Title = styled.div`
	width: 90%;
	margin: 5% auto;
	font-size: 20px;
	font-weight: 600;
`;
