import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { GuideKorail } from '../../../asset/data/GuideKorail';
import { ReactComponent as Arrow } from '../../../asset/svg/ArrowRight.svg';
import ScrollContent from '../../../components/ScrollContent';
import HomeLayout from '../../../layout/HomeLayout';

export default function GuideList() {
	const navigate = useNavigate();

	return (
		<HomeLayout>
			<GuideWrapper>
				<Title>코레일 톡 사용 가이드</Title>
				<ScrollContent>
					{GuideKorail.map((x) => {
						return (
							<ListBox
								key={x.id}
								onClick={() => navigate(`/guide/korail/${x.id}`)}
							>
								<List>{x.title}</List>
								<Arrow />
							</ListBox>
						);
					})}
				</ScrollContent>
			</GuideWrapper>
		</HomeLayout>
	);
}

const GuideWrapper = styled.section`
	width: 100%;
`;

const Title = styled.div`
	padding: 5%;
	border-bottom: 10px solid#f8f8f8;
	font-size: 20px;
	font-weight: 600;
`;

const ListBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5%;
	border-bottom: 1px solid #e6e6e6;
	cursor: pointer;
`;

const List = styled.div`
	font-size: 18px;
	font-weight: 600;
`;
