import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import LevelTestLayout from '../../layout/LevelTestLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { tokenState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';

export default function Curriculum10() {
	const [disable, setDisable] = useState(true);
	const [isCorrect, setIsCorrect] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const token = useRecoilValue(tokenState);
	const navigate = useNavigate();

	console.log(inputValue);
	console.log(isCorrect);

	useEffect(() => {
		if (inputValue === '30586') {
			setDisable(true);
			setIsCorrect(true);
		} else {
			setIsCorrect(false);
		}
	}, [inputValue]);

	return (
		<LevelTestLayout>
			<TopHeader title='스마트폰 사용법 기초' page='/curriculum9' />
			<RangeBarWrap>
				<div>10/16</div>
				<RangeBar type='range' min={0} max={16} step={10} defaultValue={10} />
			</RangeBarWrap>
			{disable && !isCorrect && (
				<MentBox>
					문자로 인증번호가 왔어요
					<br /> 인증번호를 아래 칸에
					<br /> 입력해주세요
				</MentBox>
			)}
			{disable && isCorrect && (
				<MentBox>
					인증번호를 입력해도
					<br />
					아무 변화가 없는 경우엔
					<br /> 확인 버튼을 눌러주세요
				</MentBox>
			)}
			{!disable && isCorrect && (
				<MentBox>
					잘하셨어요!
					<br />
					다음으로 넘어가 볼까요?
				</MentBox>
			)}

			<AuthBox>
				{!disable && isCorrect && <Success>본인인증이 완료되었습니다</Success>}
				<AuthMessage>
					띠링에서 보낸 인증번호 입니다.
					<br />
					[30586] 을 인증번호 창에 입력하세요.
				</AuthMessage>
				<AuthInputBox>
					<AnswerInput
						type='text'
						placeholder=' 인증번호를 입력하세요'
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
					/>
					{isCorrect && inputValue && (
						<AuthButton
							onClick={() => {
								setDisable(false);
							}}
						>
							확인
						</AuthButton>
					)}
				</AuthInputBox>
			</AuthBox>
			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						if (disable && !isCorrect) {
							getSpeech(
								'문자로 인증번호가 왔어요 인증번호를 아래 칸에 입력해주세요'
							);
						}
						if (disable && isCorrect) {
							getSpeech(
								'인증번호를 입력해도 아무 변화가 없는 경우엔 확인 버튼을 눌러주세요'
							);
						}
						if (!disable && isCorrect) {
							getSpeech('잘하셨어요 다음으로 넘어가볼까요?');
						}
					}}
				/>
				<ApplyButton
					disabled={disable}
					onClick={() => {
						if (!disable && isCorrect) {
							navigate('/curriculum11');
						}
						setDisable(true);
					}}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</LevelTestLayout>
	);
}

const Success = styled.div`
	padding-left: 20px;
	width: 100%;
	height: 44px;
	border-radius: 10px;
	background: var(--D_grey7, #4c4c4c);
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-family: Pretendard;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const AuthInputBox = styled.div`
	padding-left: 20px;
	margin-top: 30px;
	width: 70vw;
	display: flex;
	justify-content: baseline;
	align-items: baseline;
	border-radius: 10px;
	border: 1px solid var(--D_grey2, #e6e6e6);
`;

const AuthButton = styled.button`
	width: 12%;
	height: 30.09px;
	margin-right: 3px;
	border: none;
	border-radius: 31.343px;
	background: var(--D_Pink, #fd698d);
	color: #fff;
	font-family: Pretendard;
	font-size: 12.537px;
	font-style: normal;
	font-weight: 400;
	line-height: 22.567px; /* 180% */
	letter-spacing: -0.256px;
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;

const AuthBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 70vw;
	margin-top: 50px;
`;

const AuthMessage = styled.div`
	display: flex;
	padding-left: 20px;
	justify-content: baseline;
	align-items: center;
	width: 100%;
	height: 56px;
	border-radius: 15px;
	background: #d9d9d9;
	margin-top: 10px;
`;

const AnswerInput = styled.input`
	width: 88%;
	height: 36px;
	border: none;
	border-radius: 10px;
`;
