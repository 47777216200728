import { SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import { authAxios } from '../../api/customAxios';
import ApplyButton from '../../components/button/ApplyButton';
import CurryButton from '../../components/button/CurryButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { correctAnswersState, tokenState } from '../../recoil/atom';
import theme from '../styles/theme';

function LevelTest8() {
	const navigate = useNavigate();
	const [yearOption, setYearOption] = useState('');
	const [monthOption, setMonthOption] = useState('');
	const [dayOption, setDayOption] = useState('');
	const [isCorrect, setIsCorrect] = useState(false);
	const [correctAnswers, setCorrectAnswers] =
		useRecoilState(correctAnswersState); // Recoil atom 상태 추가	// const [touchPosition, setTouchPosition] = useState({ x: 0, y: 0 });
	// const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
	const [dragging, setDragging] = useState(false);
	const [dragPosition, setDragPosition] = useState<{ x: number; y: number }[]>(
		[]
	);
	const [touchLocations, setTouchLocations] = useState<
		{ x: number; y: number }[]
	>([]);

	const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
		setDragging(true);
		handleMouseMove(event); // Call handleMouseMove directly to set initial position
	};

	const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = () => {
		setDragging(false);
	};

	// const touchLocation = [];

	const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
		if (!dragging) return;

		const dragX = event.clientX;
		const dragY = event.clientY;
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		const normalizedX = (dragX / screenWidth) * 100;
		const normalizedY = (dragY / screenHeight) * 100;

		setTouchLocations((prevLocations) => [
			...prevLocations,
			{ x: normalizedX, y: normalizedY },
		]);
	};

	// const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = (event) => {
	// 	const touch = event.touches[0];
	// 	const screenWidth = window.innerWidth;
	// 	const screenHeight = window.innerHeight;

	// 	const touchX = touch.clientX;
	// 	const touchY = touch.clientY;

	// 	const normalizedX = (touchX / screenWidth) * 100;
	// 	const normalizedY = (touchY / screenHeight) * 100;

	// 	// setTouchPosition({ x: normalizedX, y: normalizedY });
	// 	setTouchLocations((prevLocations) => [
	// 		...prevLocations,
	// 		{ x: normalizedX, y: normalizedY },
	// 	]);
	// };

	const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
		const clickX = event.clientX;
		const clickY = event.clientY;
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		const normalizedX = (clickX / screenWidth) * 100;
		const normalizedY = (clickY / screenHeight) * 100;

		// setClickPosition({ x: normalizedX, y: normalizedY });
		setTouchLocations((prevLocations) => [
			...prevLocations,
			{ x: normalizedX, y: normalizedY },
		]);
	};
	console.log('클릭', touchLocations);
	console.log('드래그', dragPosition);
	const token = useRecoilValue(tokenState);
	const date = `${yearOption}.${monthOption}.${dayOption}`;
	useEffect(() => {
		if (date === '2023.6.3') {
			setIsCorrect(true);
		} else {
			setIsCorrect(false);
		}
		console.log(correctAnswers);
		console.log('날짜', date);
	}, [date]);

	const yearOptionChange = (e: {
		target: { value: SetStateAction<string> };
	}) => {
		setYearOption(e.target.value);
		console.log(yearOption); // 선택된 옵션 값을 상태에 업데이트
	};
	const monthOptionChange = (e: {
		target: { value: SetStateAction<string> };
	}) => {
		setMonthOption(e.target.value);
		console.log(monthOption); // 선택된 옵션 값을 상태에 업데이트
	};
	const dayOptionChange = (e: {
		target: { value: SetStateAction<string> };
	}) => {
		setDayOption(e.target.value);
		console.log(dayOption); // 선택된 옵션 값을 상태에 업데이트
	};

	const fetchCode = async () => {
		if (isCorrect == true) {
			setCorrectAnswers(correctAnswers + 1);
		}
		try {
			const user = await authAxios(token).post(`/quiz/evaluation`, {
				quizId: 6,
				userAnswer: date,
				isCorrect: isCorrect,
			});
			const userClick = await authAxios(token).post(`/quiz/curriculum`, {
				quizId: 6,
				clickLocation: touchLocations,
			});

			console.log(userClick);
			console.log(user);
			console.log('정답', isCorrect);

			console.log(user);
		} catch (err) {
			console.log(err);
		}
	};
	console.log(isCorrect);
	useEffect(() => {
		console.log(yearOption);
		console.log(monthOption);
		console.log(dayOption);
	}, [yearOption, monthOption, dayOption]);
	return (
		<Container
			onClick={handleClick}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
		>
			<Content>
				<TopHeader title='나에게 맞는 수업 찾기' page='/levelTest7' />
				<RangeBarWrap>
					<div>8/14</div>
					<RangeBar type='range' min={0} max={14} step={8} defaultValue={8} />
				</RangeBarWrap>
				<MentBox>
					오늘 날짜는
					<br /> 2023년 6월 3일이래요.
					<br /> 날짜를 맞춰주세요!
				</MentBox>
				<DatePick>
					<YearBox>
						<CurryButton children='연도' />
						<YearPick
							defaultValue={2020}
							value={yearOption}
							onChange={yearOptionChange}
						>
							<option value={0}>연도</option>
							<option value={2023}>2023</option>
							<option value={2022}>2022</option>
							<option value={2021}>2021</option>
							<option value={2020}>2020</option>
							<option value={2019}>2019</option>
							<option value={2018}>2018</option>
						</YearPick>
					</YearBox>
					<MonthDayPickBox>
						<CurryButton children='월' />
						<MonthDayPick
							defaultValue={12}
							value={monthOption}
							onChange={monthOptionChange}
						>
							<option value={0}>월</option>
							<option value={1}>1</option>
							<option value={2}>2</option>
							<option value={3}>3</option>
							<option value={4} selected>
								4
							</option>
							<option value={5}>5</option>
							<option value={6}>6</option>
							<option value={7}>7</option>
							<option value={8}>8</option>
							<option value={9}>9</option>
							<option value={10}>10</option>
							<option value={11}>11</option>
							<option value={12}>12</option>
						</MonthDayPick>
					</MonthDayPickBox>
					<MonthDayPickBox>
						<CurryButton children='일' />
						<MonthDayPick
							defaultValue={25}
							value={dayOption}
							onChange={dayOptionChange}
						>
							<option value={0}>일</option>
							<option value={1}>1</option>
							<option value={2}>2</option>
							<option value={3}>3</option>
							<option value={4} selected>
								4
							</option>
							<option value={5}>5</option>
							<option value={6}>6</option>
							<option value={7}>7</option>
							<option value={8}>8</option>
							<option value={9}>9</option>
							<option value={10}>10</option>
							<option value={11}>11</option>
							<option value={12}>12</option>
							<option value={13}>13</option>
							<option value={14}>14</option>
							<option value={15}>15</option>
							<option value={16}>16</option>
							<option value={17}>17</option>
							<option value={18}>18</option>
							<option value={19}>19</option>
							<option value={20}>20</option>
							<option value={21}>21</option>
							<option value={22}>22</option>
							<option value={23}>23</option>
							<option value={24}>24</option>
							<option value={25}>25</option>
							<option value={26}>26</option>
							<option value={27}>27</option>
							<option value={28}>28</option>
							<option value={29}>29</option>
							<option value={30}>30</option>
							<option value={31}>31</option>
						</MonthDayPick>
					</MonthDayPickBox>
				</DatePick>
				<ApplyFooter>
					<VolumeButton />
					<ApplyButton
						onClick={() => {
							fetchCode();
							navigate('/levelTest9');
						}}
					>
						다음
					</ApplyButton>
				</ApplyFooter>
			</Content>
		</Container>
	);
}

const Container = styled.div`
	height: 100vh;
	margin: 0 auto;
	background-color: ${theme.white};
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 85%;
	height: 100vh;
	margin: 0 auto;
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;

const DatePick = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-top: 70px;
	border: none;
	gap: 5vw;
`;

const YearBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 40%;
`;

const MonthDayPickBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 30%;
`;

const YearPick = styled.select`
	width: 100%;
	height: 50px;
	margin-top: 50px;
`;

const MonthDayPick = styled.select`
	width: 100%;
	height: 50px;
	margin-top: 50px;
`;

export default LevelTest8;
