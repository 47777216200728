import { useNavigate } from 'react-router-dom';

import Title from '../../components/Title';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { getSpeech } from '../../utils/getSpeech';

export default function Apply1() {
	const navigate = useNavigate();

	return (
		<ApplyLayout>
			<TopHeader page='/login' />
			<Title>
				안녕하세요! <br /> 만나서 반가워요!
				<br />
				<br /> 시작에 앞서 몇 가지를 <br />
				알아보려고 해요
			</Title>
			<ApplyFooter>
				<VolumeButton
					onClick={() =>
						getSpeech(
							'안녕하세요! 만나서 반가워요! 시작에 앞서 몇 가지를 알아보려고 해요'
						)
					}
				/>
				<ApplyButton onClick={() => navigate('/apply/2')}>다음</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}
