import { Suspense } from 'react';
import Slider from 'react-slick';
import { useRecoilValue } from 'recoil';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import LoadingSpinner from '../../components/LoadingSpinner';
import DigitalCompetencyCard from '../../components/card/DigitalCompetencyCard';
import UsePhoneBasicCard from '../../components/card/UsePhoneBasicCard';
import HomeLayout from '../../layout/HomeLayout';
import { getNameSelector, getUserBadge, tokenState } from '../../recoil/atom';
import Apply1 from '../apply/1';
import Agree from '../auth/agree';
import Login from '../login/login';
import UserInfo from '../setting/UserInfo';
import Badge from './Badge';

export default function Home() {
	const agree = localStorage.getItem('agreeTerms') === 'true';
	const survey = localStorage.getItem('doSurvey') === 'true';

	const token = useRecoilValue(tokenState);

	if (!token) {
		return <Login />;
	}

	const name = useRecoilValue(getNameSelector);
	const userBdg = useRecoilValue(getUserBadge);

	if (!agree) {
		return <Agree />;
	} else if (!survey) {
		return <Apply1 />;
	}

	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		swipeToSlide: true,
		arrows: false,
	};

	return (
		<Suspense fallback={<LoadingSpinner isLoading={true} />}>
			<HomeLayout>
				<UserInfo />
				<CardSection>
					<SlideBox>
						<Slider {...settings}>
							<DigitalCompetencyCard />
							<UsePhoneBasicCard />
						</Slider>
					</SlideBox>
				</CardSection>
				<Badge name={name} data={userBdg} />
			</HomeLayout>
		</Suspense>
	);
}

const CardSection = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;
	margin: 0 auto;
	overflow: auto;
`;

const SlideBox = styled.div`
	width: 100%;
	height: 100%;
	min-height: 300px;
	overflow: hidden;
`;
