import { SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import ApplyButton from '../../components/button/ApplyButton';
import CurryButton from '../../components/button/CurryButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { getSpeech } from '../../utils/getSpeech';

function Curriculum7() {
	const navigate = useNavigate();
	const [yearOption, setYearOption] = useState('');
	const [monthOption, setMonthOption] = useState('');
	const [dayOption, setDayOption] = useState('');
	const [isCorrect, setIsCorrect] = useState(false);
	const [disable, setDisable] = useState(false);

	useEffect(() => {
		if (date === '2023.6.3') {
			setIsCorrect(true);
			setDisable(false);
		} else {
			setIsCorrect(false);
		}
		console.log('날짜', date);
	});
	const date = `${yearOption}.${monthOption}.${dayOption}`;

	const yearOptionChange = (e: {
		target: { value: SetStateAction<string> };
	}) => {
		setYearOption(e.target.value);
		console.log(yearOption); // 선택된 옵션 값을 상태에 업데이트
	};
	const monthOptionChange = (e: {
		target: { value: SetStateAction<string> };
	}) => {
		setMonthOption(e.target.value);
		console.log(monthOption); // 선택된 옵션 값을 상태에 업데이트
	};
	const dayOptionChange = (e: {
		target: { value: SetStateAction<string> };
	}) => {
		setDayOption(e.target.value);
		console.log(dayOption); // 선택된 옵션 값을 상태에 업데이트
	};

	console.log(isCorrect);
	useEffect(() => {
		console.log(yearOption);
		console.log(monthOption);
		console.log(dayOption);
	}, [yearOption, monthOption, dayOption]);
	return (
		<ApplyLayout>
			<TopHeader title='스마트폰 사용법 기초' page='/curriculum6' />
			<RangeBarWrap>
				<div>7/16</div>
				<RangeBar type='range' min={0} max={16} step={7} defaultValue={7} />
			</RangeBarWrap>
			{!disable && !isCorrect && (
				<MentBox>
					아래 박스를 통해
					<br /> 날짜를 맞출 수 있어요
					<br /> 박스를 클릭 후 선택해 보세요!
				</MentBox>
			)}
			{disable && !isCorrect && (
				<MentBox>
					오늘은 2023년 6월 3일이에요
					<br /> 박스를 클릭해
					<br /> 날짜를 맞춰주세요!
				</MentBox>
			)}
			{!disable && isCorrect && (
				<MentBox>
					잘하셨어요!
					<br />
					다음으로 넘어가 볼까요?
				</MentBox>
			)}

			<DatePick>
				<YearBox>
					<CurryButton children='연도' />
					<YearPick
						defaultValue={2020}
						value={yearOption}
						onChange={yearOptionChange}
					>
						<option value={0}>연도</option>
						<option value={2023}>2023</option>
						<option value={2022}>2022</option>
						<option value={2021}>2021</option>
						<option value={2020}>2020</option>
						<option value={2019}>2019</option>
						<option value={2018}>2018</option>
					</YearPick>
				</YearBox>
				<MonthDayPickBox>
					<CurryButton children='월' />
					<MonthDayPick
						defaultValue={12}
						value={monthOption}
						onChange={monthOptionChange}
					>
						<option value={0}>월</option>
						<option value={1}>1</option>
						<option value={2}>2</option>
						<option value={3}>3</option>
						<option value={4} selected>
							4
						</option>
						<option value={5}>5</option>
						<option value={6}>6</option>
						<option value={7}>7</option>
						<option value={8}>8</option>
						<option value={9}>9</option>
						<option value={10}>10</option>
						<option value={11}>11</option>
						<option value={12}>12</option>
					</MonthDayPick>
				</MonthDayPickBox>
				<MonthDayPickBox>
					<CurryButton children='일' />
					<MonthDayPick
						defaultValue={25}
						value={dayOption}
						onChange={dayOptionChange}
					>
						<option value={0}>일</option>
						<option value={1}>1</option>
						<option value={2}>2</option>
						<option value={3}>3</option>
						<option value={4} selected>
							4
						</option>
						<option value={5}>5</option>
						<option value={6}>6</option>
						<option value={7}>7</option>
						<option value={8}>8</option>
						<option value={9}>9</option>
						<option value={10}>10</option>
						<option value={11}>11</option>
						<option value={12}>12</option>
						<option value={13}>13</option>
						<option value={14}>14</option>
						<option value={15}>15</option>
						<option value={16}>16</option>
						<option value={17}>17</option>
						<option value={18}>18</option>
						<option value={19}>19</option>
						<option value={20}>20</option>
						<option value={21}>21</option>
						<option value={22}>22</option>
						<option value={23}>23</option>
						<option value={24}>24</option>
						<option value={25}>25</option>
						<option value={26}>26</option>
						<option value={27}>27</option>
						<option value={28}>28</option>
						<option value={29}>29</option>
						<option value={30}>30</option>
						<option value={31}>31</option>
					</MonthDayPick>
				</MonthDayPickBox>
			</DatePick>
			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						if (!disable && !isCorrect) {
							getSpeech(
								'아래 박스를 통해 날짜를 맞출 수 있어요 박스를 클릭 후 선택해 보세요!'
							);
						}
						if (disable && !isCorrect) {
							getSpeech(
								'오늘은 이천이십삼년 유월 삼일이에요 박스를 클릭해 날짜를 맞춰주세요!'
							);
						}
						if (!disable && isCorrect) {
							getSpeech('잘하셨어요 다음으로 넘어가볼까요?');
						}
					}}
				/>
				<ApplyButton
					disabled={disable}
					onClick={() => {
						setDisable(true);
						if (date === '2023.6.3' && disable == false) {
							navigate('/curriculum8');
						}
					}}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;

const DatePick = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-top: 100px;
	border: none;
	gap: 5vw;
`;

const YearBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 40%;
`;

const MonthDayPickBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 30%;
`;

const YearPick = styled.select`
	width: 100%;
	height: 50px;
	margin-top: 50px;
`;

const MonthDayPick = styled.select`
	width: 100%;
	height: 50px;
	margin-top: 50px;
`;

export default Curriculum7;
