import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../asset/svg/LogoWhite.svg';
import MainLayout from '../layout/MainLayout';
import { tokenState } from '../recoil/atom';

export default function Main() {
	const navigate = useNavigate();
	const token = useRecoilValue(tokenState);

	const timeout = (): NodeJS.Timeout => {
		return setTimeout(() => {
			token ? navigate('/home') : navigate('/classcode');
		}, 2000);
	};

	useEffect(() => {
		const timer: NodeJS.Timeout = timeout();
		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<MainLayout>
			<MainWrapper>
				<Logo />
			</MainWrapper>
		</MainLayout>
	);
}

const MainWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100%;
	background: linear-gradient(
			137deg,
			rgba(157, 202, 255, 0.4) 0%,
			rgba(255, 255, 255, 0) 100%
		),
		linear-gradient(0deg, #fd698d 0%, #fd698d 100%), #fff;
`;
