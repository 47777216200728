import styled from 'styled-components';

interface ScrollProps {
	children: React.ReactNode;
}

export default function wScrollContent({ children }: ScrollProps) {
	return <ScrollSection>{children}</ScrollSection>;
}

const ScrollSection = styled.div`
	overflow: auto;
	-ms-overflow-style: none; /* 인터넷 익스플로러 */
	scrollbar-width: none; /* 파이어폭스 */
	::-webkit-scrollbar {
		display: none; /* 크롬, 사파리, 오페라, 엣지 */
	}
`;
