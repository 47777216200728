import { useNavigate } from 'react-router-dom';

import Title from '../../components/Title';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { getSpeech } from '../../utils/getSpeech';

export default function Apply2() {
	const navigate = useNavigate();

	return (
		<ApplyLayout>
			<TopHeader page='/apply/1' />
			<Title>
				지금부터 드릴 질문은
				<br />
				최적의 학습 과정을
				<br />
				추천드리기 위한 것이니
				<br />
				부담없이 답변해주세요!
			</Title>
			<ApplyFooter>
				<VolumeButton
					onClick={() =>
						getSpeech(
							'지금부터 드릴 질문은, 최적의 학습 과정을 추천드리기 위한 것이니, 부담없이 답변해주세요!'
						)
					}
				/>
				<ApplyButton onClick={() => navigate('/apply/3')}>다음</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}
