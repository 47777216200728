import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { styled } from 'styled-components';

// Render a YouTube video player
interface VideoProps {
	setPlayerTime: React.Dispatch<React.SetStateAction<number>>;
}

export default function VideoPlayer({ setPlayerTime }: VideoProps) {
	const [currentTime, setCurrentTime] = useState(0);
	const [isPlaying, setIsPlaying] = useState(true);

	useEffect(() => {
		if (currentTime >= 207) {
			setIsPlaying(false);
		}
	}, [currentTime]);

	const handleProgress = (progress: { playedSeconds: number }) => {
		setCurrentTime(progress.playedSeconds);
		console.log(progress.playedSeconds);

		setPlayerTime(progress.playedSeconds);
	};

	return (
		<PlayerWrap>
			<ReactPlayer
				url='https://youtu.be/xuexbvO29Gk?si=FS5CXnTsuPC26rNG'
				playing={isPlaying}
				controls={true}
				width={330}
				height={200}
				onProgress={handleProgress}
			/>
		</PlayerWrap>
	);
}

const PlayerWrap = styled.div`
	width: 100%;
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: center;
`;
