import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import ApplyButton from '../../components/button/ApplyButton';
import CurryButton from '../../components/button/CurryButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { tokenState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';

interface ReqButtonBoxProps {
	active: boolean; // Add this line
	onClick: () => void;
}

export default function Curriculum3() {
	const [ageButton, setAgeButton] = useState('');
	const [hobbyButton, setHobbyButton] = useState('');
	const [comment, setComment] = useState(true);
	const [disable, setDisable] = useState(true);
	const token = useRecoilValue(tokenState);

	const userAnswer = { ageButton: ageButton, hobbyButton: hobbyButton };
	console.log(userAnswer.ageButton, userAnswer.hobbyButton);

	const handleAgeButtonClick = (buttonName: string) => {
		setAgeButton(buttonName);
	};

	const handleHobbyButtonClick = (buttonName: string) => {
		setHobbyButton(buttonName);
	};

	useEffect(() => {
		if (comment == true) {
			setDisable(false);
		} else if (comment == false) {
			setDisable(true);
		}
		if (
			comment == false &&
			userAnswer.ageButton == '30대' &&
			userAnswer.hobbyButton == '그림그리기'
		) {
			setDisable(false);
		}
	}, [comment, userAnswer]);

	const navigate = useNavigate();
	// const [images, setImages] = useState<String>();
	// useEffect(() => {
	//   axios.get('/data/svg.json', {}).then((result) => {
	//     console.log(result.data);
	//     setImages(result.data);
	//   });
	// }, []);
	return (
		<ApplyLayout>
			<TopHeader title='스마트폰 사용법 기초' page='/curriculum2' />
			<RangeBarWrap>
				<div>3/16</div>
				<RangeBar type='range' min={0} max={16} step={3} defaultValue={3} />
			</RangeBarWrap>
			{!disable && comment && (
				<MentBox>
					아래의 버튼은
					<br /> 여러가지를 동시에
					<br /> 선택할 수 있는 버튼이에요
				</MentBox>
			)}
			{disable && !comment && (
				<MentBox>
					아래의 항목에서
					<br /> `30대`와 `그림그리기`를
					<br /> 찾아 눌러보세요
				</MentBox>
			)}
			{!disable && !comment && (
				<MentBox>
					잘하셨어요! <br />
					다음으로 넘어가 볼까요?
				</MentBox>
			)}

			<AgeWrap>
				<AgeButtonWrap>
					<CurryButton
						active={ageButton === '20대'}
						onClick={() => {
							handleAgeButtonClick('20대');
						}}
						children='20대'
					/>
					<CurryButton
						active={ageButton === '30대'}
						onClick={() => {
							handleAgeButtonClick('30대');
						}}
						children='30대'
					/>
					<CurryButton
						active={ageButton === '40대'}
						onClick={() => {
							handleAgeButtonClick('40대');
						}}
						children='40대'
					/>
					<CurryButton
						active={ageButton === '50대'}
						onClick={() => {
							handleAgeButtonClick('50대');
						}}
						children='50대'
					/>
					<CurryButton
						active={ageButton === '60대'}
						onClick={() => {
							handleAgeButtonClick('60대');
						}}
						children='60대'
					/>
					<CurryButton
						active={ageButton === '70대'}
						onClick={() => {
							handleAgeButtonClick('70대');
						}}
						children='70대'
					/>
					<CurryButton
						active={hobbyButton === '노래하기'}
						onClick={() => {
							handleHobbyButtonClick('노래하기');
						}}
						children='노래하기'
					/>
					<CurryButton
						active={hobbyButton === '춤추기'}
						onClick={() => {
							handleHobbyButtonClick('춤추기');
						}}
						children='춤추기'
					/>
					<CurryButton
						active={hobbyButton === '그림그리기'}
						onClick={() => {
							handleHobbyButtonClick('그림그리기');
						}}
						children='그림그리기'
					/>
					<CurryButton
						active={hobbyButton === '캠핑하기'}
						onClick={() => {
							handleHobbyButtonClick('캠핑하기');
						}}
						children='캠핑하기'
					/>
					<CurryButton
						active={hobbyButton === '등산하기'}
						onClick={() => {
							handleHobbyButtonClick('등산하기');
						}}
						children='등산하기'
					/>
					<CurryButton
						active={hobbyButton === '낚시하기'}
						onClick={() => {
							handleHobbyButtonClick('낚시하기');
						}}
						children='낚시하기'
					/>
				</AgeButtonWrap>
			</AgeWrap>
			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						if (!disable && comment) {
							getSpeech(
								'아래의 버튼은 여러가지를 동시에 선택할 수 있는 버튼이에요'
							);
						}
						if (disable && !comment) {
							getSpeech(
								'아래의 항목에서`삽십대`와 `그림그리기`를찾아 눌러보세요'
							);
						}
						if (!disable && !comment) {
							getSpeech('잘하셨어요 다음으로 넘어가볼까요?');
						}
					}}
				/>
				<ApplyButton
					disabled={disable}
					onClick={() => {
						console.log(userAnswer);
						setComment(false);
						if (
							comment == false &&
							userAnswer.ageButton == '30대' &&
							userAnswer.hobbyButton == '그림그리기'
						) {
							navigate('/curriculum4');
						}
					}}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}

const AgeWrap = styled.div`
	margin-top: 10px;
	width: 90%;
`;
const AgeButtonWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around;
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const ButtonWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 80%;
	height: 200px;
	margin-top: 80px;
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;
