import { Link, useMatch } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Home } from '../../asset/svg/HomeIcon.svg';
import { ReactComponent as Jungle } from '../../asset/svg/Jungle.svg';
import { ReactComponent as Mountain } from '../../asset/svg/Mountain.svg';
import { ReactComponent as Ocean } from '../../asset/svg/Ocean.svg';
import { ReactComponent as Sky } from '../../asset/svg/Sky.svg';
import theme from '../../pages/styles/theme';

interface TextProps {
	active: string;
}

export default function CurriculumTabs() {
	const homeMatch = useMatch('/curriculum16');
	const curriculumMatch = useMatch('/mountain');
	const gameMatch = useMatch('/sea');
	const guideMatch = useMatch('/sky');
	const settingMatch = useMatch('/jungle');

	return (
		<Container>
			<SLink to='/curriculum16'>
				<Text active={homeMatch !== null ? 'true' : 'false'}>
					<Home />집
				</Text>
			</SLink>
			<SLink to='/mountain'>
				<Text active={curriculumMatch !== null ? 'true' : 'false'}>
					<Mountain />산
				</Text>
			</SLink>
			<SLink to='/sea'>
				<Text active={gameMatch !== null ? 'true' : 'false'}>
					<Ocean />
					바다
				</Text>
			</SLink>
			<SLink to='/sky'>
				<Text active={guideMatch !== null ? 'true' : 'false'}>
					<Sky />
					하늘
				</Text>
			</SLink>
			<SLink to='/jungle'>
				<Text active={settingMatch !== null ? 'true' : 'false'}>
					<Jungle />
					정글
				</Text>
			</SLink>
		</Container>
	);
}

const Container = styled.section`
	display: flex;
	justify-content: space-around;
	padding: 18px;
	background: #fff;
	box-shadow: 0px -4px 14px 0px rgba(0, 0, 0, 0.1);
`;

const SLink = styled(Link)``;

const Text = styled.div<TextProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 45px;
	color: ${({ active }) => (active === 'true' ? `${theme.pink}` : '#aeaeae')};
	font-size: 12px;
	font-weight: 500;

	> svg {
		${({ active }) =>
			active === 'true' ? `fill: ${theme.pink}` : `fill: #aeaeae`};
	}
`;
