import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { authAxios } from '../../api/customAxios';
import ScrollContent from '../../components/ScrollContent';
import HomeLayout from '../../layout/HomeLayout';
import { getUserInfoSelector, tokenState } from '../../recoil/atom';
import theme from '../styles/theme';
import UserInfo from './UserInfo';

export default function SettingMyInfo() {
	const navigate = useNavigate();
	const info = useRecoilValue(getUserInfoSelector);
	const token = useRecoilValue(tokenState);

	const deleteUser = async () => {
		if (window.confirm('회원 정보가 삭제됩니다. 정말 탈퇴하시겠어요?')) {
			try {
				await authAxios(token).delete('auth/account');
				localStorage.clear();
				navigate('/login');
			} catch (err) {
				console.log(err);
			}
		}
	};

	const logout = () => {
		localStorage.clear();
		navigate('/login');
	};

	return (
		<HomeLayout>
			<Title>마이페이지</Title>
			<Section />
			<UserInfo />
			<IdPwSection>
				<IdPwTitle>아이디</IdPwTitle>
				<Content>
					{info.nickname ? info.nickname : '소셜 로그인 계정입니다'}
				</Content>
				<IdPwTitle>휴대폰 번호</IdPwTitle>
				<Content>{info.phoneNumber}</Content>
			</IdPwSection>
			<Section />
			<ScrollContent>
				<Ul>
					<Li>비밀번호 변경</Li>
					<Li>개인정보 처리방침</Li>
					<Li>알림 동의</Li>
					<Black onClick={() => logout()}>로그아웃</Black>
					<Black onClick={() => deleteUser()}>회원 탈퇴</Black>
				</Ul>
			</ScrollContent>
		</HomeLayout>
	);
}

const Title = styled.div`
	width: 90%;
	margin: 5% auto;
	font-size: 20px;
	font-weight: 600;
`;

const Section = styled.div`
	width: 100%;
	border-bottom: 10px solid #f8f8f8;
`;

const IdPwSection = styled.section`
	width: 90%;
	margin: 0 auto 5%;
`;

const IdPwTitle = styled.section`
	margin: 5% 0 3%;
	color: ${theme.D_grey4};
	font-weight: 500;
`;

const Content = styled.div`
	padding-bottom: 3%;
	border-bottom: 1px solid #585858;
	color: ${theme.D_grey7};
	font-size: 20px;
	font-weight: 500;
`;

const Ul = styled.ul`
	width: 100%;
	margin: 0 auto;
`;

const Li = styled.li`
	display: flex;
	align-items: center;
	padding: 5%;
	border-bottom: 1px solid #e6e6e6;
	color: ${theme.D_grey5};
	font-size: 18px;
	font-weight: 500;
	cursor: pointer;
`;

const Black = styled(Li)`
	color: ${theme.D_bk};
`;
