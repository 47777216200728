// App.tsx
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Apply1 from './pages/apply/1';
import Apply10 from './pages/apply/10';
import Apply2 from './pages/apply/2';
import Apply3 from './pages/apply/3';
import Apply4 from './pages/apply/4';
import Apply5 from './pages/apply/5';
import Apply6 from './pages/apply/6';
import Apply7 from './pages/apply/7';
import Apply8 from './pages/apply/8';
import Apply9 from './pages/apply/9';
import Agree from './pages/auth/agree';
import FindId from './pages/auth/findId';
import FindPw from './pages/auth/findpw';
import Signup from './pages/auth/signup';
import ClassCode from './pages/classCode/ClassCode';
import Curriculm from './pages/curriculum';
import Curriculum1 from './pages/curriculum/Curriculum1';
import Curriculum10 from './pages/curriculum/Curriculum10';
import Curriculum11 from './pages/curriculum/Curriculum11';
import Curriculum12 from './pages/curriculum/Curriculum12';
import Curriculum13 from './pages/curriculum/Curriculum13';
import Curriculum14 from './pages/curriculum/Curriculum14';
import Curriculum15 from './pages/curriculum/Curriculum15';
import Curriculum16 from './pages/curriculum/Curriculum16';
import Curriculum2 from './pages/curriculum/Curriculum2';
import Curriculum3 from './pages/curriculum/Curriculum3';
import Curriculum4 from './pages/curriculum/Curriculum4';
import Curriculum5 from './pages/curriculum/Curriculum5';
import Curriculum6 from './pages/curriculum/Curriculum6';
import Curriculum7 from './pages/curriculum/Curriculum7';
import Curriculum8 from './pages/curriculum/Curriculum8';
import Curriculum9 from './pages/curriculum/Curriculum9';
import Jungle from './pages/curriculum/Jungle';
import Mountain from './pages/curriculum/Mountain';
import Sea from './pages/curriculum/Sea';
import Sky from './pages/curriculum/Sky';
import Guide from './pages/guide/guide';
import GuideList from './pages/guide/korail/guideList';
import Korail1 from './pages/guide/korail/korail1';
import Korail2 from './pages/guide/korail/korail2';
import Korail3 from './pages/guide/korail/korail3';
import Korail4 from './pages/guide/korail/korail4';
import Korail5 from './pages/guide/korail/korail5';
import Korail6 from './pages/guide/korail/korail6';
import Korail7 from './pages/guide/korail/korail7';
import Korail8 from './pages/guide/korail/korail8';
import Game from './pages/home/game';
import Home from './pages/home/home';
import LevelTest from './pages/levelTest/LevelTest1';
import LevelTest10 from './pages/levelTest/LevelTest10';
import LevelTest11 from './pages/levelTest/LevelTest11';
import LevelTest12 from './pages/levelTest/LevelTest12';
import LevelTest13 from './pages/levelTest/LevelTest13';
import LevelTest14 from './pages/levelTest/LevelTest14';
import LevelTest15 from './pages/levelTest/LevelTest15';
import LevelTest2 from './pages/levelTest/LevelTest2';
import LevelTest3 from './pages/levelTest/LevelTest3';
import LevelTest4 from './pages/levelTest/LevelTest4';
import LevelTest5 from './pages/levelTest/LevelTest5';
import LevelTest6 from './pages/levelTest/LevelTest6';
import LevelTest7 from './pages/levelTest/LevelTest7';
import LevelTest8 from './pages/levelTest/LevelTest8';
import LevelTest9 from './pages/levelTest/LevelTest9';
import KakaoLogin from './pages/login/kakaoLogin';
import Login from './pages/login/login';
import NaverLogin from './pages/login/naverLogin';
import Signin from './pages/login/signin';
import Main from './pages/main';
import Game1 from './pages/minigame/Game1';
import Game2 from './pages/minigame/Game2';
import Game3 from './pages/minigame/Game3';
import Game4 from './pages/minigame/Game4';
import Game5 from './pages/minigame/Game5';
import Game6 from './pages/minigame/Game6';
import Game7 from './pages/minigame/Game7';
import Setting from './pages/setting';
import SettingMyInfo from './pages/setting/myinfo';
import CurriculumEnd from './pages/curriculum/CurriculumEnd';

const App: React.FC = () => {
	return (
		<Router>
			<Routes>
				<Route path='/' element={<Main />} />
				<Route path='/classcode' element={<ClassCode />} />
				<Route path='/home' element={<Home />} />
				<Route path='/curriculum' element={<Curriculm />} />
				<Route path='/game' element={<Game />} />
				<Route path='/guide' element={<Guide />} />
				<Route path='/setting' element={<Setting />} />
				<Route path='/setting/myinfo' element={<SettingMyInfo />} />
				<Route path='/login' element={<Login />} />
				<Route path='/auth/kakao' element={<KakaoLogin />} />
				<Route path='/auth/naver' element={<NaverLogin />} />
				<Route path='/auth/agree' element={<Agree />} />
				<Route path='/signin' element={<Signin />} />
				<Route path='/signup' element={<Signup />} />
				<Route path='/find/id' element={<FindId />} />
				<Route path='/find/pw' element={<FindPw />} />
				<Route path='/apply/1' element={<Apply1 />} />
				<Route path='/apply/2' element={<Apply2 />} />
				<Route path='/apply/3' element={<Apply3 />} />
				<Route path='/apply/4' element={<Apply4 />} />
				<Route path='/apply/5' element={<Apply5 />} />
				<Route path='/apply/6' element={<Apply6 />} />
				<Route path='/apply/7' element={<Apply7 />} />
				<Route path='/apply/8' element={<Apply8 />} />
				<Route path='/apply/9' element={<Apply9 />} />
				<Route path='/apply/10' element={<Apply10 />} />
				<Route path='/leveltest' element={<LevelTest />} />
				<Route path='/leveltest2' element={<LevelTest2 />} />
				<Route path='/leveltest3' element={<LevelTest3 />} />
				<Route path='/leveltest4' element={<LevelTest4 />} />
				<Route path='/leveltest5' element={<LevelTest5 />} />
				<Route path='/leveltest6' element={<LevelTest6 />} />
				<Route path='/leveltest7' element={<LevelTest7 />} />
				<Route path='/leveltest8' element={<LevelTest8 />} />
				<Route path='/leveltest9' element={<LevelTest9 />} />
				<Route path='/leveltest10' element={<LevelTest10 />} />
				<Route path='/leveltest11' element={<LevelTest11 />} />
				<Route path='/leveltest12' element={<LevelTest12 />} />
				<Route path='/leveltest13' element={<LevelTest13 />} />
				<Route path='/leveltest14' element={<LevelTest14 />} />
				<Route path='/leveltest15' element={<LevelTest15 />} />
				<Route path='/curriculum1' element={<Curriculum1 />} />
				<Route path='/curriculum2' element={<Curriculum2 />} />
				<Route path='/curriculum3' element={<Curriculum3 />} />
				<Route path='/curriculum4' element={<Curriculum4 />} />
				<Route path='/curriculum5' element={<Curriculum5 />} />
				<Route path='/curriculum6' element={<Curriculum6 />} />
				<Route path='/curriculum7' element={<Curriculum7 />} />
				<Route path='/curriculum8' element={<Curriculum8 />} />
				<Route path='/curriculum9' element={<Curriculum9 />} />
				<Route path='/curriculum10' element={<Curriculum10 />} />
				<Route path='/curriculum11' element={<Curriculum11 />} />
				<Route path='/curriculum12' element={<Curriculum12 />} />
				<Route path='/curriculum13' element={<Curriculum13 />} />
				<Route path='/curriculum14' element={<Curriculum14 />} />
				<Route path='/curriculum15' element={<Curriculum15 />} />
				<Route path='/curriculum16' element={<Curriculum16 />} />
				<Route path='/curriculumend' element={<CurriculumEnd />} />
				<Route path='/guide/korail' element={<GuideList />} />
				<Route path='/guide/korail/1' element={<Korail1 />} />
				<Route path='/guide/korail/2' element={<Korail2 />} />
				<Route path='/guide/korail/3' element={<Korail3 />} />
				<Route path='/guide/korail/4' element={<Korail4 />} />
				<Route path='/guide/korail/5' element={<Korail5 />} />
				<Route path='/guide/korail/6' element={<Korail6 />} />
				<Route path='/guide/korail/7' element={<Korail7 />} />
				<Route path='/guide/korail/8' element={<Korail8 />} />
				<Route path='/game1' element={<Game1 />} />
				<Route path='/game2' element={<Game2 />} />
				<Route path='/game3' element={<Game3 />} />
				<Route path='/game4' element={<Game4 />} />
				<Route path='/game5' element={<Game5 />} />
				<Route path='/game6' element={<Game6 />} />
				<Route path='/game7' element={<Game7 />} />
				<Route path='/sky' element={<Sky />} />
				<Route path='/sea' element={<Sea />} />
				<Route path='/jungle' element={<Jungle />} />
				<Route path='/mountain' element={<Mountain />} />
			</Routes>
		</Router>
	);
};

export default App;
