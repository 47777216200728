import React, { useState } from 'react';
import { styled } from 'styled-components';
import { ReactComponent as Wifi } from '../asset/svg/Wifi.svg';
import theme from '../pages/styles/theme';

type FlippableOnOffProps = {
	isFlipped: boolean;
} & React.SVGProps<SVGSVGElement>;

interface WifiProps {
	setWifi: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function WifiImage({ setWifi }: WifiProps) {
	const [isFlipped, setIsFlipped] = useState(false);
	const [onOff, setOnOff] = useState('');

	const handleImageClick = () => {
		setIsFlipped(!isFlipped);
		setWifi(!isFlipped);
		setOnOff(`${!isFlipped}`);
	};
	console.log('dlrj', onOff);
	return (
		<WifiWrap>
			<Wifi />
			<FlippableOnOff onClick={handleImageClick}>
				<Button isFlipped={isFlipped}>
					<Cricle></Cricle>
				</Button>
			</FlippableOnOff>
		</WifiWrap>
	);
}

const WifiWrap = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
	width: 300px;
`;

const FlippableOnOff = styled.div`
	width: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: transform 0s;
`;

const Button = styled.div<FlippableOnOffProps>`
	width: 52px;
	height: 32px;
	border-radius: 50px;
	background-color: ${({ isFlipped }: { isFlipped: boolean }) =>
		isFlipped ? `#b0c8f5` : `${theme.D_grey3}`};
	${({ isFlipped }: { isFlipped: boolean }) =>
		isFlipped ? 'transform: scaleX(-1);' : ''}
	display: flex;
	align-items: center;
`;

const Cricle = styled.div`
	width: 25px;
	height: 25px;
	border-radius: 50px;
	background-color: white;
	margin-left: 5px;
`;
