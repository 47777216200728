import { useEffect, useRef, useState } from 'react';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import LevelTestLayout from '../../layout/LevelTestLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { tokenState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';

declare global {
	interface Window {
		initMap: () => void;
	}
}

window.initMap = () => {
	// Google Maps API 로드 시 호출될 함수
	// 이 함수 내에서 Google Maps 관련 작업을 수행합니다.
};
export default function Curriculum13() {
	const navigate = useNavigate();
	const mapRef = useRef<HTMLDivElement>(null);
	const searchRef = useRef<HTMLInputElement>(null);
	const [isCorrect, setIsCorrect] = useState(false);
	const [disable, setDisable] = useState(true);
	const token = useRecoilValue(tokenState);

	useEffect(() => {
		// 이미 initMap 함수가 전역 스코프에 정의되어 있으므로,
		// Google Maps API 스크립트가 로드되면 바로 호출합니다.
		if (window.google.maps && window.google) {
			initMap();
		} else {
			console.error('Google Maps API not loaded.');
		}
		console.log('맵', mapRef);
		console.log('서치', searchRef);
		console.log(isCorrect);
	}, []);

	const initMap = () => {
		if (mapRef.current) {
			const map = new window.google.maps.Map(mapRef.current, {
				center: { lat: 37.5665, lng: 126.978 },
				zoom: 15,
				disableDefaultUI: true,
			});

			const marker = new window.google.maps.Marker({
				position: { lat: 37.57589241187062, lng: 126.97681468220921 },
				map: map,
				title: '광화문',
			});

			// 검색 버튼 클릭 시 실행되는 함수
			const searchLocation = () => {
				const searchValue = searchRef.current?.value;
				if (searchValue) {
					const geocoder = new window.google.maps.Geocoder();
					geocoder.geocode({ address: searchValue }, (results, status) => {
						if (status === 'OK' && results?.[0]) {
							map.setCenter(results[0].geometry.location);
						} else {
							console.error(
								'Geocode was not successful for the following reason:',
								status
							);
						}
					});
				}
			};

			// 검색 버튼 클릭 이벤트 추가
			const searchButton = document.getElementById('search-button');
			if (searchButton) {
				searchButton.addEventListener('click', searchLocation);
			}
		} else {
			console.error('Map container not found.');
		}
	};

	const reValue = () => {
		if (searchRef.current?.value === '광화문') {
			setIsCorrect(true);
			setDisable(false);
		} else if (searchRef.current?.value !== '광화문') {
			return setIsCorrect(false);
		}
	};
	console.log(isCorrect);

	const userAnswer = searchRef.current?.value;
	console.log(userAnswer);

	return (
		<LevelTestLayout>
			<TopHeader title='스마트폰 사용법 기초' page='/curriculum12' />
			<RangeBarWrap>
				<div>13/16</div>
				<RangeBar type='range' min={0} max={16} step={13} defaultValue={13} />
			</RangeBarWrap>
			{disable && !isCorrect && (
				<MentBox>
					지도에서 원하는 장소를
					<br />
					검색해 찾아보고 싶어요
					<br />
					먼저 검색창을 눌러주세요!
				</MentBox>
			)}
			{disable && isCorrect && <MentBox>광화문을 검색해주세요!</MentBox>}
			{!disable && isCorrect && (
				<MentBox>
					잘하셨어요!
					<br />
					다음으로 넘어가볼까요?
				</MentBox>
			)}
			<MapWrap>
				<SearchBox>
					<InputBox
						type='text'
						ref={searchRef}
						placeholder='광화문을 검색해보세요'
						onClick={() => {
							setIsCorrect(true);
						}}
					/>
					<PiMagnifyingGlass
						className='search-button'
						id='search-button'
						onClick={() => {
							reValue();
						}}
					/>
				</SearchBox>
				<MapContainer ref={mapRef} />
			</MapWrap>
			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						if (disable && !isCorrect) {
							getSpeech(
								'지도에서 원하는 장소를 검색해 찾아보고 싶어요 먼저 검색창을 눌러주세요!'
							);
						}
						if (disable && isCorrect) {
							getSpeech('광화문을 검색해주세요!');
						}
						if (!disable && isCorrect) {
							getSpeech('잘하셨어요 다음으로 넘어가볼까요?');
						}
					}}
				/>
				<ApplyButton
					disabled={disable}
					onClick={() => {
						navigate('/curriculum14');
					}}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</LevelTestLayout>
	);
}

const SearchBox = styled.div`
	position: absolute;
	top: 40vh;
	z-index: 20;
	width: 90%;
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

	.search-button {
		width: 24px;
		height: 24px;
		color: gray;

		&:hover {
			cursor: pointer;
		}
	}
`;

const InputBox = styled.input`
	width: 80%;
	border: none;
`;

const MapWrap = styled.div`
	width: 100vw;
	margin-top: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const MapContainer = styled.div`
	width: 100%;
	height: 250px; /* Adjust the height as needed */
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;
