const theme = {
	pink: '#FD698D',
	yellow: '#FDE400',
	green: '#5AC467',
	D_bk: '#252525',
	D_grey2: '#E6E6E6',
	D_grey3: '#D3D3D3',
	D_grey4: '#AEAEAE',
	D_grey5: '#868686',
	D_grey6: '#585858',
	D_grey7: '#4C4C4C',
	D_blue: '#E8F0FA',
	white: '#FFFFFF',
};

export default theme;
