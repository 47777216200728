import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { baseAxios } from '../../api/customAxios';
import Content from '../../components/Content';
import Title from '../../components/Title';
import ApplyButton from '../../components/button/ApplyButton';
import InputButton from '../../components/button/InputButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { getSpeech } from '../../utils/getSpeech';
import LoginContainer from '../login/LoginContainer';
import theme from '../styles/theme';

export default function FindId() {
	const navigate = useNavigate();
	const [name, setName] = useState<string>('');
	const [phone, setPhone] = useState<string>('');
	const [id, setId] = useState<string>('');
	const [findStatus, setFindStatus] = useState<boolean | null>();

	const handleName = (e: ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
	};

	const handlePhone = (e: ChangeEvent<HTMLInputElement>) => {
		// 숫자만 남기고 나머지 문자 제거
		const digits = e.target.value.replace(/\D/g, '');

		// 전화번호 형식에 맞게 가공
		let formattedNumber = '';
		if (digits.length > 0) {
			formattedNumber += digits.substring(0, 3);
		}
		if (digits.length >= 4) {
			formattedNumber += '-' + digits.substring(3, 7);
		}
		if (digits.length >= 8) {
			formattedNumber += '-' + digits.substring(7, 11);
		}

		setPhone(formattedNumber);
	};

	const submit = async () => {
		try {
			const res = await baseAxios.post('auth/id', {
				name: name,
				phoneNumber: phone,
			});
			setFindStatus(true);
			setId(res.data.nickname);
		} catch (error) {
			setFindStatus(false);
		}
	};

	return (
		<ApplyLayout>
			<TopHeader title='아이디 찾기' page='/login' />
			{findStatus === undefined && (
				<>
					<Title>
						가입하실 때 입력하신 <br />
						정보를 입력해주세요
					</Title>
					<Content>
						<InputButton
							type='text'
							label='이름'
							placeholder='홍길동'
							value={name}
							onChange={handleName}
						/>
						<InputButton
							type='tel'
							label='전화번호'
							placeholder='010-1234-5678'
							value={phone}
							onChange={handlePhone}
						/>
					</Content>
					<ApplyFooter>
						<VolumeButton
							onClick={() =>
								getSpeech('가입하실 때, 입력하신 정보를, 입력해주세요')
							}
						/>
						<ApplyButton disabled={!(name && phone)} onClick={submit}>
							찾기
						</ApplyButton>
					</ApplyFooter>
				</>
			)}

			{findStatus && id && (
				<>
					<Title>
						입력하신 정보와 연동된 <br />
						아이디는 &apos; <Bold>{id}</Bold> &apos; 입니다
					</Title>

					<ApplyFooter>
						<VolumeButton
							onClick={() =>
								getSpeech(
									'입력하신 정보와, 연동된 아이디를, 화면에서 확인하세요'
								)
							}
						/>
						<FindPw onClick={() => navigate('/find/pw')}>비밀번호 찾기</FindPw>
						<ApplyButton onClick={() => navigate('/signin')}>
							로그인하기
						</ApplyButton>
					</ApplyFooter>
				</>
			)}

			{findStatus === false && (
				<>
					<Title>
						입력하신 정보로 <br />
						가입된 계정이 없습니다
					</Title>
					<Result>
						<LoginContainer />
					</Result>
				</>
			)}
		</ApplyLayout>
	);
}

const Result = styled.div`
	margin-top: 50%;
`;

const Bold = styled.span`
	font-size: 24px;
	font-weight: 700;
`;

const FindPw = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 85%;
	margin: 0 auto 2%;
	height: 52px;
	padding: 0 24px 0 24px;
	color: ${theme.D_grey6};
	font-size: 16px;
	font-weight: 400;
	cursor: pointer;
`;
