import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import GuideLayout from '../../../layout/GuideLayout';
import GuideHeader from '../../../layout/header/GuideHeader';

export default function Korail6() {
	const images = [
		require('../../../asset/jpg/korailFirst.jpg'),
		require('../../../asset/jpg/korail-6/1.jpg'),
		require('../../../asset/jpg/korail-6/2.jpg'),
		require('../../../asset/jpg/korail-6/3.jpg'),
		require('../../../asset/jpg/korail-6/4.jpg'),
		require('../../../asset/jpg/korail-6/5.jpg'),
		require('../../../asset/jpg/korail-6/6.jpg'),
		require('../../../asset/jpg/korail-6/7.jpg'),
		require('../../../asset/jpg/korail-6/8.jpg'),
		require('../../../asset/jpg/korail-6/9.jpg'),
		require('../../../asset/jpg/korail-6/10.jpg'),
		require('../../../asset/jpg/korail-6/11.jpg'),
	];

	const navigate = useNavigate();
	const [currentImgIdx, setCurrentImgIdx] = useState(0);

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const halfScreenWidth = window.innerWidth / 2;

		if (e.clientX < halfScreenWidth) {
			// 왼쪽 클릭시, 이전 이미지로
			if (currentImgIdx > 0) {
				setCurrentImgIdx(currentImgIdx - 1);
			}
		} else {
			// 오른쪽 클릭시, 다음 이미지로
			if (currentImgIdx < images.length - 1) {
				setCurrentImgIdx(currentImgIdx + 1);
			} else {
				navigate('/guide/korail');
			}
		}
	};

	return (
		<GuideLayout>
			<GuideHeader
				title="승차권의 예매 내역을 변경하고 싶어요"
				page="/guide/korail"
			/>
			<ImgSlider onClick={(e) => handleClick(e)}>
				<ImgBox src={images[currentImgIdx]} alt="korail guide" />
			</ImgSlider>
		</GuideLayout>
	);
}

const ImgSlider = styled.div``;

const ImgBox = styled.img`
	width: 100%;
	height: 100%;
`;
