import { useEffect, useState } from 'react';
import { styled } from 'styled-components';

interface CheckListProps {
	setCheckedTasksText: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function CheckList({ setCheckedTasksText }: CheckListProps) {
	const [tasks, setTasks] = useState([
		{ id: 1, text: '1. 청소하기', checked: false },
		{ id: 2, text: '2. 요리하기', checked: false },
		{ id: 3, text: '3. 화분 물주기', checked: false },
		{ id: 4, text: '4. 빨래하기', checked: false },
	]);

	// useEffect(() => {
	// 	tasks;
	// }, [tasks]);

	// const handleCheckboxChange = (taskId: number) => {
	// 	// tasks 업데이트를 setTasks 이후에 수행
	// 	const updatedTasks = tasks.map((task) =>
	// 		task.id === taskId ? { ...task, checked: !task.checked } : task
	// 	);
	// 	setTasks(updatedTasks);

	// 	// setTasks 이후에 checkedTexts 업데이트
	// 	const checkedTexts = updatedTasks
	// 		.filter((task) => task.checked)
	// 		.map((task) => task.text);
	// 	setCheckedTasksText(checkedTexts);
	// };

	const handleCheckboxChange = (taskId: number) => {
		setTasks((prevTasks) => {
			// 이전 상태를 기반으로 새로운 tasks 배열 생성
			const updatedTasks = prevTasks.map((task) =>
				task.id === taskId ? { ...task, checked: !task.checked } : task
			);
			return updatedTasks;
		});
	};

	useEffect(() => {
		const checkedTexts = tasks
			.filter((task) => task.checked)
			.map((task) => task.text);
		setCheckedTasksText(checkedTexts);
	}, [tasks]);

	return (
		<CheckListWall>
			<img src='../image/drawingpin.png' alt='drawing pin' />
			<CheckListTextBox>
				{tasks.map((task) => (
					<CheckListText key={task.id}>
						<div>{task.text}</div>
						<CheckBox
							type='checkbox'
							checked={task.checked}
							onChange={() => {
								console.log('Checkbox clicked for task id:', task.id);
								handleCheckboxChange(task.id);
							}}
						/>
					</CheckListText>
				))}
			</CheckListTextBox>
		</CheckListWall>
	);
}

const CheckListWall = styled.div`
	width: 300px;
	height: 267px;
	background-color: #fffacb;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 40px;
	box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.3);
`;

const CheckListTextBox = styled.div`
	margin-top: 20px;
`;

const CheckListText = styled.div`
	display: flex;
	width: 192px;
	height: 30px;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 14px;
	font-size: 20px;
`;

const CheckBox = styled.input`
	width: 20px;
	height: 20px;
`;
