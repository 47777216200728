import { useState } from 'react';
import {
	FiMoreHorizontal,
	FiSettings,
	FiShare,
	FiTrash,
	FiVolume2,
} from 'react-icons/fi';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import LevelTestLayout from '../../layout/LevelTestLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { correctAnswersState, tokenState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';

interface ReqButtonBoxProps {
	active: boolean; // Add this line
	onClick: () => void;
}

function Curriculum12() {
	const [activeButton, setActiveButton] = useState('');
	const [disable, setDisable] = useState(true);
	const [isCorrect, setIsCorrect] = useState(false);
	const [correctAnswers, setCorrectAnswers] =
		useRecoilState(correctAnswersState); // Recoil atom 상태 추가
	const token = useRecoilValue(tokenState);

	const handleButtonClick = (buttonName: string) => {
		setActiveButton(buttonName);
		if (buttonName == 'search') {
			setIsCorrect(true);
			setDisable(false);
		} else {
			return setIsCorrect(false);
		}
	};
	console.log(isCorrect);

	const navigate = useNavigate();
	return (
		<LevelTestLayout>
			<TopHeader title='스마트폰 사용법 기초' page='/curriculum11' />
			<RangeBarWrap>
				<div>12/16</div>
				<RangeBar type='range' min={0} max={16} step={12} defaultValue={12} />
			</RangeBarWrap>
			{disable && !isCorrect && (
				<MentBox>
					돋보기 모양의 아이콘은
					<br />
					‘검색하기’를 의미해요
					<br />
					찾아서 눌러볼까요?
				</MentBox>
			)}
			{!disable && isCorrect && (
				<MentBox>
					잘하셨어요!
					<br />
					다음으로 넘어가 볼까요?
				</MentBox>
			)}
			<ButtonMargin>
				<ButtonWrap>
					<ReqButtonBox
						active={activeButton === 'menu'}
						onClick={() => handleButtonClick('menu')}
					>
						<FiMoreHorizontal />
					</ReqButtonBox>
					<ReqButtonBox
						active={activeButton === 'volume'}
						onClick={() => handleButtonClick('volume')}
					>
						<FiVolume2 />
					</ReqButtonBox>
					<ReqButtonBox
						active={activeButton === 'trash'}
						onClick={() => handleButtonClick('trash')}
					>
						<FiTrash />
					</ReqButtonBox>
				</ButtonWrap>
				<ButtonWrap>
					<ReqButtonBox
						active={activeButton === 'settings'}
						onClick={() => handleButtonClick('settings')}
					>
						<FiSettings />
					</ReqButtonBox>
					<ReqButtonBox
						active={activeButton === 'search'}
						onClick={() => handleButtonClick('search')}
					>
						<PiMagnifyingGlass />
					</ReqButtonBox>
					<ReqButtonBox
						active={activeButton === 'share'}
						onClick={() => handleButtonClick('share')}
					>
						<FiShare />
					</ReqButtonBox>
				</ButtonWrap>
			</ButtonMargin>
			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						if (disable && !isCorrect) {
							getSpeech(
								'돋보기 모양의 아이콘은 ‘검색하기’를 의미해요 찾아서 눌러볼까요?'
							);
						}
						if (!disable && isCorrect) {
							getSpeech('잘하셨어요 다음으로 넘어가볼까요?');
						}
					}}
				/>
				<ApplyButton
					disabled={disable}
					onClick={() => {
						if (!disable && isCorrect) {
							navigate('/curriculum13');
						}
					}}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</LevelTestLayout>
	);
}
const ButtonMargin = styled.div`
	margin-top: 60px;
	width: 70%;
`;
const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;

const ButtonWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100px;
	gap: 10px;
`;

const ReqButtonBox = styled.button<ReqButtonBoxProps>`
	// Use the updated prop type
	width: 84px;
	height: 84px;
	border: 2px solid var(--d-grey-2, #e6e6e6);
	border-radius: 11px;
	box-shadow: ${({ active }) =>
		active ? '2px 2px 4px 0px rgba(0, 0, 0, 0.25)' : ''};
	background-color: ${({ active }) => (active ? '#FD698D' : '#fff')};
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	color: ${({ active }) => (active ? '#fff' : '#000')};
`;

export default Curriculum12;
