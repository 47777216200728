import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import { authAxios } from '../../api/customAxios';
import { AGREE } from '../../asset/data/Agree';
import { ReactComponent as Arrow } from '../../asset/svg/ArrowRight.svg';
import { ReactComponent as LogoPink } from '../../asset/svg/LogoPink.svg';
import Checkbox from '../../components/Checkbox';
import ApplyButton from '../../components/button/ApplyButton';
import MainLayout from '../../layout/MainLayout';
import { tokenState } from '../../recoil/atom';
import theme from '../styles/theme';

export default function Agree() {
	const navigate = useNavigate();
	const [agree, setAgree] = useState<number[]>([]);
	const token = useRecoilValue(tokenState);

	const selectAll = () => {
		if (agree.length === 5) {
			setAgree([]);
		} else {
			setAgree(AGREE.map((x) => x.id));
		}
	};

	const handleChange = (id: number) => {
		if (agree.includes(id)) {
			setAgree((prev) => prev.filter((x) => x !== id).sort());
		} else {
			setAgree((prev) => [...prev, id].sort());
		}
	};

	const fetchAgree = async () => {
		try {
			await authAxios(token).post('user/terms', {
				requiredTerms: agree.filter((x) => x < 3),
				optionalTerms: agree.filter((x) => x > 2),
			});
			localStorage.setItem('agreeTerms', 'true');
			navigate('/apply/1');
		} catch (err) {
			if (axios.isAxiosError(err)) {
				console.log(err.response?.data.message);
				alert('Server Error!\n죄송합니다. 똑디 아이디로 로그인해주세요.');
				localStorage.clear();
				navigate('/login');
			}
		}
	};

	return (
		<MainLayout>
			<Header>
				<LogoPink />
				<Subtitle>똑똑하게 배우는 디지털 세상</Subtitle>
			</Header>

			<Container>
				<Title>
					똑디를 이용하기 위한 <br />
					<BoldText>약관 동의</BoldText>가 필요합니다.
				</Title>
				<AllSelectBox checked={agree.length === 5}>
					<Checkbox
						title='모두 동의합니다'
						checked={agree.length === 5}
						onChange={selectAll}
					/>
				</AllSelectBox>
				<SelectBox>
					{AGREE.map((x) => {
						return (
							<SelectWrapper key={x.id}>
								<Checkbox
									title={x.title}
									checked={agree.includes(x.id)}
									onChange={() => handleChange(x.id)}
								/>
								{x.detail ? (
									<Arrow
										onClick={() =>
											window.open(
												'https://jet-koi-be0.notion.site/13abd1d1206c43da8a45c8195fa1952c'
											)
										}
									/>
								) : null}
							</SelectWrapper>
						);
					})}
				</SelectBox>
				<Footer>
					<ApplyButton
						disabled={!(agree.includes(1) && agree.includes(2))}
						onClick={fetchAgree}
					>
						다음
					</ApplyButton>
				</Footer>
			</Container>
		</MainLayout>
	);
}

const Header = styled.header`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 50vh;
`;

const Subtitle = styled.div`
	margin-top: 10%;
	color: #252525;
	font-size: 16px;
	font-weight: 600;
`;

const Container = styled.div`
	width: 100%;
	padding: 8% 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-top: 2px solid #e5e5e5;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	background-color: #ffffff;
	box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.3);
`;

const Title = styled.div`
	width: 85%;
	margin: 0 auto 6%;
	font-size: 24px;
	font-weight: 500;
	line-height: 35px;
`;

const BoldText = styled.span`
	font-weight: 700;
`;

const AllSelectBox = styled.div<{ checked: boolean }>`
	border: 1.5px solid ${(props) => (props.checked ? theme.pink : theme.D_grey3)};
	border-radius: 11px;
	width: 80%;
	padding: 3%;

	label {
		font-weight: 600;
	}
`;

const SelectBox = styled.div`
	width: 85%;
	margin: 3% auto;
`;

const SelectWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
`;

const Footer = styled.div`
	width: 90%;
`;
