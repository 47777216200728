import React from 'react';
import { styled } from 'styled-components';
import theme from '../pages/styles/theme';

interface MainLayoutProps {
	children: React.ReactNode;
}

export default function GuideLayout({ children }: MainLayoutProps) {
	return (
		<Container>
			<Content>{children}</Content>
		</Container>
	);
}

const Container = styled.div`
	height: 100vh;
	/* max-width: 425px; */
	margin: 0 auto;
	border: 1px solid black;
	background-color: ${theme.white};
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100vh;
	margin: 0 auto;
`;
