import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as GameName } from '../../asset/svg/GameName.svg';
import GameLayout from '../../layout/GameLayout';
import TopHeader from '../../layout/header/TopHeader';
import BottomTabs from '../../layout/tab/BottomTabs';

export default function Game1() {
	const navegate = useNavigate();
	return (
		<GameLayout>
			<TopHeaderWrap>
				<TopHeader title='' page='/game' />
			</TopHeaderWrap>
			<LayoutGameName>
				<GameNameBox />
			</LayoutGameName>
			<StartButton
				onClick={() => {
					navegate('/game2');
				}}
			>
				start!
			</StartButton>
			<Footer>
				<BottomTabs />
			</Footer>
		</GameLayout>
	);
}
const Footer = styled.div`
	width: 100%;
	background-color: ${(props) => props.theme.background};
`;

const TopHeaderWrap = styled.div`
	margin: -6% 1% 0 1%;
`;

const LayoutGameName = styled.div`
	position: absolute;
	top: 6%;
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
`;

const GameNameBox = styled(GameName)``;

const StartButton = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 243px;
	height: 76px;
	flex-shrink: 0;
	border-radius: 16px;
	background: var(--D_Pink, #fd698d);
	box-shadow: -4px -4px 4px 0px rgba(0, 0, 0, 0.25) inset;
	color: #fff;
	font-size: 40px;
	font-style: normal;
	font-weight: 900;
	letter-spacing: 1px;
	text-align: center;
`;
