import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

export default function FindIdPw() {
	const navigate = useNavigate();

	return (
		<FindBox>
			<IdPw onClick={() => navigate('/find/id')}>아이디 찾기</IdPw>|
			<IdPw onClick={() => navigate('/find/pw')}>비밀번호 찾기</IdPw>
		</FindBox>
	);
}

const FindBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 75%;
	margin-top: 10%;
`;

const IdPw = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
`;
