import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as MiniGame } from '../../asset/svg/MiniGame.svg';
import HomeLayout from '../../layout/HomeLayout';

export default function Game() {
	const navigate = useNavigate();
	return (
		<HomeLayout>
			<Fortune
				onClick={() => {
					navigate('/game1');
				}}
			/>
		</HomeLayout>
	);
}

const Fortune = styled(MiniGame)`
	width: 100%;
	height: 210px;
`;
