import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
	SettingData1,
	SettingData2,
	SettingData3,
} from '../../asset/data/Setting';
import { ReactComponent as Arrow } from '../../asset/svg/ArrowRight.svg';
import ScrollContent from '../../components/ScrollContent';
import HomeLayout from '../../layout/HomeLayout';
import theme from '../styles/theme';

export default function Setting() {
	const navigate = useNavigate();

	return (
		<HomeLayout>
			<Title>마이페이지</Title>
			<Section />
			<UserSection>
				<Img src={require('../../asset/jpg/ddokDLogo.png')} alt='profileImg' />
				<TitleText onClick={() => navigate('/setting/myinfo')}>
					<BoldText>회원 정보</BoldText>
					<SmallText>회원 정보를 확인할 수 있습니다</SmallText>
				</TitleText>
				<SArrow onClick={() => navigate('/setting/myinfo')} />
			</UserSection>
			<ScrollContent>
				<AppSetting>
					<Title>어플리케이션 정보</Title>
					<Ul>
						{SettingData2.map((x) => {
							return (
								<Li key={x.id}>
									<x.icon />
									<Settings onClick={() => window.open(x.link)}>
										<SettingTitle>{x.title}</SettingTitle>
										<SettingSubtitle>{x.subtitle}</SettingSubtitle>
									</Settings>
								</Li>
							);
						})}
					</Ul>
				</AppSetting>
				<AppSetting>
					<Title>똑디 커뮤니티 바로가기</Title>
					<Ul>
						{SettingData3.map((x) => {
							return (
								<Li key={x.id}>
									<x.icon />
									<Settings onClick={() => window.open(x.link)}>
										<SettingTitle>{x.title}</SettingTitle>
										<SettingSubtitle>{x.subtitle}</SettingSubtitle>
									</Settings>
								</Li>
							);
						})}
					</Ul>
				</AppSetting>
				<AppSetting>
					<Title>어플리케이션 설정</Title>
					<Ul>
						{SettingData1.map((x) => {
							return (
								<Li key={x.id}>
									<x.icon />
									<Settings>
										<TitleDisabled>{x.title}</TitleDisabled>
										<SubtitleDisabled>{x.subtitle}</SubtitleDisabled>
									</Settings>
								</Li>
							);
						})}
					</Ul>
				</AppSetting>
			</ScrollContent>
		</HomeLayout>
	);
}

const Title = styled.div`
	width: 90%;
	margin: 5% auto;
	font-size: 20px;
	font-weight: 600;
`;

const Section = styled.div`
	width: 100%;
	border-bottom: 10px solid #f8f8f8;
`;

const UserSection = styled.section`
	width: 90%;
	margin: 5% auto 3%;
	display: flex;
	align-items: center;
`;

const Img = styled.img`
	width: 70px;
	height: 70px;
	margin-right: 20px;
	border-radius: 50%;
`;

const TitleText = styled.div`
	cursor: pointer;
`;

const BoldText = styled.div`
	font-size: 20px;
	font-weight: 700;
`;

const SmallText = styled.div`
	margin-top: 5%;
	color: #898989;
	font-size: 12px;
`;

const SArrow = styled(Arrow)`
	margin-left: auto;
	cursor: pointer;
`;

const AppSetting = styled.section`
	width: 100%;
	border-bottom: 1px solid #e6e6e6;
`;

const Ul = styled.ul`
	width: 90%;
	margin: 0 auto;
`;

const Li = styled.li`
	display: flex;
	align-items: center;
	margin: 5% 0;
`;

const Settings = styled.div`
	margin-left: 5%;
`;

const SettingTitle = styled.div`
	font-weight: 500;
	cursor: pointer;
`;

const SettingSubtitle = styled.div`
	margin-top: 3%;
	color: ${theme.D_grey5};
	font-size: 12px;
	font-weight: 400;
`;

const TitleDisabled = styled(SettingTitle)`
	color: ${theme.D_grey5};
	cursor: default;
`;

const SubtitleDisabled = styled(SettingSubtitle)`
	color: ${theme.D_grey4};
`;
