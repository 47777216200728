import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Celebrate } from '../asset/svg/Celebrate.svg';
import theme from '../pages/styles/theme';

interface LevelProps {
	level?: string;
}

export default function Celebrated(props: LevelProps) {
	const [isCorrect, setIsCorrect] = useState();
	const url = useLocation();
	const path = url.pathname;
	const navigate = useNavigate();

	console.log(path);
	return (
		<Background>
			<Celebratea />
			<Ment>
				<Sorry>축하해요!</Sorry>
				<Find>
					<div>모든 네잎클로버를 찾았어요!</div>
					<br />
					<div>행운이 가득하길!</div>
				</Find>
			</Ment>
			<ButtonBox>
				<Button
					onClick={() => {
						navigate('/game');
					}}
				>
					종료
				</Button>
			</ButtonBox>
		</Background>
	);
}

const Find = styled.div`
	width: 100%;
	font-size: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 10px;
`;

const Celebratea = styled(Celebrate)`
	position: absolute;
	top: 10vh;
`;

const Background = styled.div`
	position: absolute;
	z-index: 50;
	width: 100vw;
	height: 100vh;
	opacity: 0.8;
	background-color: #000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 48px;
	font-weight: 900;
`;

const Ment = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 10px;
`;

const Sorry = styled.div`
	font-size: 20px;
`;

const ButtonBox = styled.div`
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	bottom: 60px;
	gap: 20px;
`;

const ButtonRe = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90%;
	height: 52px;
	padding: 0 24px 0 24px;
	border: none;
	border-radius: 11px;
	color: #fff;
	background-color: ${theme.pink};
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	opacity: 1;
`;
const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90%;
	height: 52px;
	padding: 0 24px 0 24px;
	border: none;
	border-radius: 11px;
	color: #000;
	background-color: ${theme.D_grey3};
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	opacity: 1;
`;
