import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Routes } from '../../asset/data/Routes';
import Content from '../../components/Content';
import Title from '../../components/Title';
import ApplyButton from '../../components/button/ApplyButton';
import SelectButton from '../../components/button/SelectButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { applyState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';

export default function Apply6() {
	const navigate = useNavigate();
	const [apply, setApply] = useRecoilState(applyState);
	const [selectId, setSelectId] = useState<number[]>(apply.funnelIds || []);

	const handleBtn = (id: number) => {
		if (selectId.includes(id)) {
			setSelectId(selectId.filter((x) => x !== id).sort());
			return;
		}
		setSelectId((prev) => [...prev, id].sort());
	};

	const submit = () => {
		setApply({ ...apply, funnelIds: selectId });
	};

	return (
		<ApplyLayout>
			<TopHeader page='/apply/5' />
			<Title>똑디를 알게된 경로가 궁금해요!</Title>
			<Content>
				{Routes.map((route) => {
					return (
						<SelectButton
							key={route.id}
							value={route.route}
							onClick={() => handleBtn(route.id)}
							selected={selectId.includes(route.id)}
						/>
					);
				})}
			</Content>
			<ApplyFooter>
				<VolumeButton
					onClick={() => getSpeech('똑디를 알게된, 경로가 궁금해요')}
				/>
				<ApplyButton
					onClick={() => {
						submit();
						navigate('/apply/7');
					}}
					disabled={selectId.length === 0}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}
