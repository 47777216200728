import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { ReactComponent as FindFortune } from '../../asset/svg/FindFortune.svg';
import { ReactComponent as MinigameHeart } from '../../asset/svg/MinigameHeart.svg';
import EndGame from '../../components/EndGame';
import GameLoad from '../../components/GameLoad';
import TimerRangeBar from '../../components/Timer';
import GameLayout from '../../layout/GameLayout';
import TopHeader from '../../layout/header/TopHeader';

interface ReqButtonBoxProps {
	active: boolean; // Add this line
	onClick: () => void;
}

export default function Game4() {
	const [activeButton, setActiveButton] = useState('');
	const [isCorrect, setIsCorrect] = useState(false);
	const [disable, setDisable] = useState(false);
	const [one, setOne] = useState(true);
	const [two, setTwo] = useState(true);
	const [three, setThree] = useState(true);
	const [isLoading, setIsLoading] = useState(true); // 로딩 스피너 표시 여부
	const [value, setValue] = useState(100);
	const navigate = useNavigate();

	useEffect(() => {
		// 1.5초 후에 로딩 스피너 감추기
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 1500);
		if (value == 0) {
			setOne(false);
			setTwo(false);
			setThree(false);
		}
		// 컴포넌트 언마운트 시 타이머 클리어
		return () => {
			clearTimeout(timer);
		};
	}, [value]);

	const handleButtonClick = (buttonName: string) => {
		setActiveButton(buttonName);
		if (buttonName === 'Fortune') {
			navigate('/game5');
		}
		if (buttonName !== 'Fortune' && one === true) {
			setOne(false);
		}
		if (buttonName !== 'Fortune' && one === false) {
			setTwo(false);
		}
		if (buttonName !== 'Fortune' && one === false && two === false) {
			setThree(false);
		}
	};
	const level = 'Level3';
	const time = 10000;

	return (
		<GameLayout>
			{isLoading && <GameLoad level={level} />}
			<ScrollMargin>
				<TopHeader title='Level3' page='/game3' />
				<FortuneWrap>
					<FindFortune />
				</FortuneWrap>
				<RangeBarWrap>
					<TimerRangeBar setValue={setValue} time={time} />
					<Heart>
						{one && <MinigameHeart />}
						{two && <MinigameHeart />}
						{three && <MinigameHeart />}
					</Heart>
				</RangeBarWrap>
				<ButtonWrap>
					<ImageWrap>
						<ReqButtonBox
							active={activeButton === '1'}
							onClick={() => handleButtonClick('1')}
						>
							<ImageButton src='../image/Rock.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === '2'}
							onClick={() => handleButtonClick('2')}
						>
							<ImageButton src='../image/Strawberry.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === '3'}
							onClick={() => handleButtonClick('3')}
						>
							<ImageButton src='../image/Bee.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === '4'}
							onClick={() => handleButtonClick('4')}
						>
							<ImageButton src='../image/Dog.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === '5'}
							onClick={() => handleButtonClick('5')}
						>
							<ImageButton src='../image/Rock.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === '6'}
							onClick={() => handleButtonClick('6')}
						>
							<ImageButton src='../image/Strawberry.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === '7'}
							onClick={() => handleButtonClick('7')}
						>
							<ImageButton src='../image/Bee.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === '8'}
							onClick={() => handleButtonClick('8')}
						>
							<ImageButton src='../image/Dog.png' />
						</ReqButtonBox>
					</ImageWrap>
				</ButtonWrap>
				<ButtonWrap>
					<ImageWrap>
						<ReqButtonBox
							active={activeButton === '9'}
							onClick={() => handleButtonClick('9')}
						>
							<ImageButton src='../image/Banana.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === '10'}
							onClick={() => handleButtonClick('10')}
						>
							<ImageButton src='../image/Leaves.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === '11'}
							onClick={() => handleButtonClick('11')}
						>
							<ImageButton src='../image/Cherry.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === '12'}
							onClick={() => handleButtonClick('12')}
						>
							<ImageButton src='../image/Banana.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === '13'}
							onClick={() => handleButtonClick('13')}
						>
							<ImageButton src='../image/Leaves.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === '14'}
							onClick={() => handleButtonClick('14')}
						>
							<ImageButton src='../image/Cherry.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === '15'}
							onClick={() => handleButtonClick('15')}
						>
							<ImageButton src='../image/Cherry.png' />
						</ReqButtonBox>

						<ReqButtonBox
							active={activeButton === 'Fortune'}
							onClick={() => handleButtonClick('Fortune')}
						>
							<ImageButton src='../image/Fortune.png' />
						</ReqButtonBox>
					</ImageWrap>
				</ButtonWrap>
			</ScrollMargin>
			{!three && <EndGame />}
		</GameLayout>
	);
}

const FortuneWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
`;

const ScrollMargin = styled.div`
	padding: 0px 37px 0px 37px;
`;

const ButtonWrap = styled.div`
	width: 100%;
	height: 160px;
	gap: 10px;
	overflow-x: scroll; /* 수평 스크롤바 숨김 */
	display: flex;
	justify-content: baseline;
	align-items: center;

	/* 수직 스크롤바 스타일링 */
	&::-webkit-scrollbar {
		width: 10px; /* 스크롤바 너비 */
	}

	&::-webkit-scrollbar-thumb {
		background-color: black; /* 스크롤바 색상 */
		border-radius: 10px; /* 스크롤바 코너 반경 */
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: transparent; /* 스크롤바 호버시 색상 변경 */
	}

	/* 수직 스크롤바의 트랙 스타일링 */
	&::-webkit-scrollbar-track {
		background-color: black; /* 트랙 배경색 */
	}
`;

const ReqButtonBox = styled.button<ReqButtonBoxProps>`
	// Use the updated prop type
	width: 140px;
	height: 140px;
	border: 2px solid var(--d-grey-2, #e6e6e6);
	border-radius: 11px;
	box-shadow: ${({ active }) =>
		active ? '2px 2px 4px 0px rgba(0, 0, 0, 0.25)' : ''};
	background-color: ${({ active }) => (active ? '#FD698D' : '#ebf0f9')};
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	color: ${({ active }) => (active ? '#fff' : '#000')};
	margin-right: 10px;
`;

const ImageWrap = styled.div`
	width: 1200px;
	display: flex;
	align-items: baseline;
	justify-content: center;
`;

const ImageButton = styled.img`
	width: 75px;
	height: 75px;
`;

const Heart = styled.div`
	width: 100%;
	display: flex;
	justify-content: end;
	padding-right: 5px;
	margin-top: 10px;
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
	margin-bottom: 40px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	margin-bottom: 50px;
`;
