import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import { authAxios } from '../../api/customAxios';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { correctAnswersState, tokenState } from '../../recoil/atom';
import theme from '../styles/theme';

interface ScrollImageProps {
	last?: boolean; // last prop의 타입을 정의
	active: boolean; // Add this line
	onClick: () => void;
}

function LevelTest12() {
	const [activeButton, setActiveButton] = useState('');
	const [isCorrect, setIsCorrect] = useState(false);
	const token = useRecoilValue(tokenState);
	const [correctAnswers, setCorrectAnswers] =
		useRecoilState(correctAnswersState); // Recoil atom 상태 추가	// const [touchPosition, setTouchPosition] = useState({ x: 0, y: 0 });
	// const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
	const [dragging, setDragging] = useState(false);
	const [dragPosition, setDragPosition] = useState<{ x: number; y: number }[]>(
		[]
	);
	const [touchLocations, setTouchLocations] = useState<
		{ x: number; y: number }[]
	>([]);

	const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
		setDragging(true);
		handleMouseMove(event); // Call handleMouseMove directly to set initial position
	};

	const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = () => {
		setDragging(false);
	};

	// const touchLocation = [];

	const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
		if (!dragging) return;

		const dragX = event.clientX;
		const dragY = event.clientY;
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		const normalizedX = (dragX / screenWidth) * 100;
		const normalizedY = (dragY / screenHeight) * 100;

		setTouchLocations((prevLocations) => [
			...prevLocations,
			{ x: normalizedX, y: normalizedY },
		]);
	};

	// const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = (event) => {
	// 	const touch = event.touches[0];
	// 	const screenWidth = window.innerWidth;
	// 	const screenHeight = window.innerHeight;

	// 	const touchX = touch.clientX;
	// 	const touchY = touch.clientY;

	// 	const normalizedX = (touchX / screenWidth) * 100;
	// 	const normalizedY = (touchY / screenHeight) * 100;

	// 	// setTouchPosition({ x: normalizedX, y: normalizedY });
	// 	setTouchLocations((prevLocations) => [
	// 		...prevLocations,
	// 		{ x: normalizedX, y: normalizedY },
	// 	]);
	// };

	const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
		const clickX = event.clientX;
		const clickY = event.clientY;
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		const normalizedX = (clickX / screenWidth) * 100;
		const normalizedY = (clickY / screenHeight) * 100;

		// setClickPosition({ x: normalizedX, y: normalizedY });
		setTouchLocations((prevLocations) => [
			...prevLocations,
			{ x: normalizedX, y: normalizedY },
		]);
	};
	console.log('클릭', touchLocations);
	console.log('드래그', dragPosition);
	const navigate = useNavigate();

	const handleButtonClick = (buttonName: string) => {
		setActiveButton(buttonName);

		if (buttonName === '김치찌개') {
			setIsCorrect(true);
		} else {
			return setIsCorrect(false);
		}
	};

	console.log(isCorrect);

	console.log(activeButton);
	const fetchCode = async () => {
		if (isCorrect == true) {
			setCorrectAnswers(correctAnswers + 1);
		}
		try {
			const user = await authAxios(token).post(`/quiz/evaluation`, {
				quizId: 10,
				userAnswer: activeButton,
				isCorrect: isCorrect,
			});
			const userClick = await authAxios(token).post(`/quiz/curriculum`, {
				quizId: 10,
				clickLocation: touchLocations,
			});

			console.log(userClick);
			console.log(user);
			console.log('정답', isCorrect);

			console.log(user);
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<Container
			onClick={handleClick}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
		>
			<Content>
				<TopHeader title='나에게 맞는 수업 찾기' page='/levelTest11' />
				<RangeBarWrap>
					<div>12/14</div>
					<RangeBar type='range' min={0} max={14} step={12} defaultValue={12} />
				</RangeBarWrap>
				<MentBox>
					화면을 위 아래로 스크롤 해서
					<br /> `김치찌개`를 찾아주세요!
				</MentBox>

				<ScrollBox>
					<ScrollImage
						src='image/bibimBap.png'
						active={activeButton === 'bibimBap'}
						onClick={() => handleButtonClick('bibimBap')}
					/>

					<ScrollImage
						src='image/samgyeTang.png'
						active={activeButton === 'samgyeTang'}
						onClick={() => handleButtonClick('samgyeTang')}
					/>

					<ScrollImage
						src='image/seaWeedSoup.png'
						active={activeButton === 'seaWeedSoup'}
						onClick={() => handleButtonClick('seaWeedSoup')}
					/>

					<ScrollImage
						src='image/kimchiZigae.png'
						last
						active={activeButton === '김치찌개'}
						onClick={() => handleButtonClick('김치찌개')}
					/>
				</ScrollBox>

				<ApplyFooter>
					<VolumeButton />
					<ApplyButton
						onClick={() => {
							fetchCode();
							navigate('/levelTest13');
							console.log(activeButton);
							console.log(isCorrect);
						}}
					>
						다음
					</ApplyButton>
				</ApplyFooter>
			</Content>
		</Container>
	);
}

const Container = styled.div`
	height: 100vh;
	margin: 0 auto;
	background-color: ${theme.white};
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 85%;
	height: 100vh;
	margin: 0 auto;
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;

const ScrollBox = styled.div`
	width: 100vw;
	margin-top: 74px;
	overflow-y: scroll; /* 수직 스크롤바 표시 */
	overflow-x: hidden; /* 수평 스크롤바 숨김 */
	display: flex;
	flex-direction: column;
	justify-content: baseline;
	align-items: center;

	/* 수직 스크롤바 스타일링 */
	&::-webkit-scrollbar {
		width: 4px; /* 스크롤바 너비 */
	}

	&::-webkit-scrollbar-thumb {
		background-color: #fd698d; /* 스크롤바 색상 */
		border-radius: 10px; /* 스크롤바 코너 반경 */
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: #e45373; /* 스크롤바 호버시 색상 변경 */
	}

	/* 수직 스크롤바의 트랙 스타일링 */
	&::-webkit-scrollbar-track {
		background-color: #f5f5f5; /* 트랙 배경색 */
	}
`;

const ScrollImage = styled.img<ScrollImageProps>`
	width: 80%;
	height: 241px;
	border-radius: 10px;
	box-shadow: ${({ active }) =>
		active ? '' : '2px 2px 4px 0px rgba(0, 0, 0, 0.25)'};
	border: ${({ active }) => (active ? '2px solid #e45373' : '')};
	margin-bottom: 30px;
`;

export default LevelTest12;
