import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import theme from '../pages/styles/theme';

interface ContainerProps {
  backgroundPath: string; // backgroundPath 속성을 타입 정의에 추가
}

interface MainLayoutProps {
  children: React.ReactNode;
}

export default function CurriculumLayout({ children }: MainLayoutProps) {
  const url = useLocation();
  console.log(url.pathname);
  let path = '';

  useEffect(() => {
    if (url.pathname === '/sea') {
      path = '/images/Sea.png'; // 이미지 파일의 절대 경로로 설정
    } else if (url.pathname === '/sky') {
      path = '/images/Sky.png'; // 이미지 파일의 절대 경로로 설정
    }
    console.log(path);
  }, [url.pathname]); // url.pathname이 변경될 때마다 useEffect가 실행되도록 설정

  return (
    <Container backgroundPath={path}>
      <Content>{children}</Content>
    </Container>
  );
}

const Container = styled.div<ContainerProps>`
  height: 100vh;
  /* max-width: 425px; */
  margin: 0 auto;
  /* background-color: ${theme.white}; */
  background-image: url(${(props) => props.backgroundPath});
  background-size: cover; // 이미지를 컨테이너에 맞게 조절
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  height: 100vh;
  margin: 0 auto;
`;
