import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

import { ReactComponent as CancelBtn } from '../../asset/svg/CancelBtn.svg';
import { ReactComponent as PreviousBtn } from '../../asset/svg/PreviousBtn.svg';

interface TopHeaderProps {
	title?: string;
	page: string;
}

export default function TopHeader({ title, page }: TopHeaderProps) {
	const navigate = useNavigate();

	return (
		<Header>
			<SPreviousBtn onClick={() => navigate(`${page}`)} />
			<Title>{title}</Title>
			<SCancelBtn onClick={() => navigate('/home')} />
		</Header>
	);
}

const Header = styled.header`
	height: 40px;
	width: 100%;
	margin: 7% auto 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const SPreviousBtn = styled(PreviousBtn)`
	cursor: pointer;
`;

const Title = styled.div`
	font-size: 16px;
	font-weight: 500;
`;

const SCancelBtn = styled(CancelBtn)`
	cursor: pointer;
`;
