import React, { useState } from 'react';
import { styled } from 'styled-components';

import theme from '../pages/styles/theme';

interface MainLayoutProps {
	children: React.ReactNode;
}

export default function LevelTestLayout({ children }: MainLayoutProps) {
	// const [touchPosition, setTouchPosition] = useState({ x: 0, y: 0 });
	// const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
	const [dragging, setDragging] = useState(false);
	const [dragPosition, setDragPosition] = useState<{ x: number; y: number }[]>(
		[]
	);
	const [touchLocations, setTouchLocations] = useState<
		{ x: number; y: number }[]
	>([]);

	const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
		setDragging(true);
		handleMouseMove(event); // Call handleMouseMove directly to set initial position
	};

	const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = () => {
		setDragging(false);
	};

	// const touchLocation = [];

	const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
		if (!dragging) return;

		const dragX = event.clientX;
		const dragY = event.clientY;
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		const normalizedX = (dragX / screenWidth) * 100;
		const normalizedY = (dragY / screenHeight) * 100;

		setTouchLocations((prevLocations) => [
			...prevLocations,
			{ x: normalizedX, y: normalizedY },
		]);
	};

	// const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = (event) => {
	// 	const touch = event.touches[0];
	// 	const screenWidth = window.innerWidth;
	// 	const screenHeight = window.innerHeight;

	// 	const touchX = touch.clientX;
	// 	const touchY = touch.clientY;

	// 	const normalizedX = (touchX / screenWidth) * 100;
	// 	const normalizedY = (touchY / screenHeight) * 100;

	// 	// setTouchPosition({ x: normalizedX, y: normalizedY });
	// 	setTouchLocations((prevLocations) => [
	// 		...prevLocations,
	// 		{ x: normalizedX, y: normalizedY },
	// 	]);
	// };

	const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
		const clickX = event.clientX;
		const clickY = event.clientY;
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		const normalizedX = (clickX / screenWidth) * 100;
		const normalizedY = (clickY / screenHeight) * 100;

		// setClickPosition({ x: normalizedX, y: normalizedY });
		setTouchLocations((prevLocations) => [
			...prevLocations,
			{ x: normalizedX, y: normalizedY },
		]);
	};
	console.log('클릭', touchLocations);
	console.log('드래그', dragPosition);
	return (
		<Container
			onClick={handleClick}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
		>
			<Content>{children}</Content>
		</Container>
	);
}

const Container = styled.div`
	height: 100vh;
	margin: 0 auto;
	background-color: ${theme.white};
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 85%;
	height: 100vh;
	margin: 0 auto;
`;
