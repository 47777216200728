import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { baseAxios } from '../../api/customAxios';
import Content from '../../components/Content';
import Title from '../../components/Title';
import ApplyButton from '../../components/button/ApplyButton';
import InputButton from '../../components/button/InputButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { getSpeech } from '../../utils/getSpeech';
import theme from '../styles/theme';

export default function Signup() {
	const [id, setId] = useState<string>('');
	const [pw, setPw] = useState<string>('');
	const [isIdValid, setIsIdValid] = useState<boolean | null>(null);
	const [isPwValid, setIsPwValid] = useState<boolean>(false);

	const navigate = useNavigate();

	const pwRegex = /^(?=.*[a-z])(?=.*\d)[a-z\d]{8,}$/;
	const isRegexPw = (pw: string) => pwRegex.test(pw);

	const handleId = (e: ChangeEvent<HTMLInputElement>) => {
		setId(e.target.value);
	};

	const handlePw = (e: ChangeEvent<HTMLInputElement>) => {
		setPw(e.target.value);
	};

	// 중복 검사
	const validId = async () => {
		try {
			await baseAxios.get(`auth/nickname-check/${id}`);
			setIsIdValid(true);
		} catch (err) {
			setIsIdValid(false);
		}
	};

	const validPw = () => {
		setIsPwValid(isRegexPw(pw));
	};

	const handleErrMsg = (pw: string) => {
		let errMsg = '';
		if (!isPwValid) {
			if (pw.length < 8) {
				errMsg += '8자 이상 입력해주세요.';
			} else if (!/[a-z]/.test(pw)) {
				errMsg += '알파벳 소문자 1자 이상 입력해주세요.';
			} else if (!/\d/.test(pw)) {
				errMsg += '숫자를 적어도 1개 이상 입력해주세요.';
			}

			return errMsg;
		}
	};

	const handleSignUp = async () => {
		try {
			await baseAxios.post(`/auth/sign-up`, {
				nickname: id,
				password: pw,
			});
			alert('회원가입이 성공적으로 완료되었습니다!');
			navigate('/signin');
		} catch (err) {
			alert('잠시 후에 시도해주세요');
		}
	};

	return (
		<ApplyLayout>
			<TopHeader title='회원가입' page='/login' />
			<Title>
				사용하실 아이디와 <br />
				비밀번호를 입력해주세요
			</Title>
			<Content>
				<IdWrapper>
					<InputButton
						type='text'
						id='idinput'
						label='아이디'
						placeholder='사용하실 아이디를 입력하세요'
						value={id}
						onChange={handleId}
					/>
					{id && <IdCheck onClick={validId}>중복 확인</IdCheck>}
				</IdWrapper>

				{id && isIdValid === true && (
					<Available>사용 가능한 아이디 입니다</Available>
				)}
				{isIdValid === false && <Validation>중복된 아이디 입니다</Validation>}

				<InputButton
					type='password'
					id='pwinput'
					label='비밀번호'
					placeholder='8자 이상 영문자+숫자 비밀번호'
					value={pw}
					onChange={handlePw}
					max='20'
					onKeyUp={validPw}
				/>
				{pw && <Validation>{handleErrMsg(pw)}</Validation>}
			</Content>
			<ApplyFooter>
				<VolumeButton
					onClick={() => getSpeech('사용하실 아이디와 비밀번호를 입력해주세요')}
				/>
				<ApplyButton
					disabled={!(isIdValid && isPwValid)}
					onClick={handleSignUp}
				>
					회원가입
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}

const IdWrapper = styled.section`
	width: 100%;
	position: relative;
`;

const IdCheck = styled.button`
	position: absolute;
	right: 10px;
	bottom: 25px;
	padding: 1% 3%;
	border: 1px solid ${theme.D_grey3};
	border-radius: 10px;
	font-size: 15px;
`;

const Available = styled.span`
	width: 100%;
	margin-left: 10%;
	font-weight: 500;
`;

const Validation = styled.span`
	width: 100%;
	margin-left: 10%;
	color: ${theme.pink};
	font-weight: 500;
`;
