import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import App from './App';
import LoadingSpinner from './components/LoadingSpinner';
import './index.css';
import GlobalStyle from './pages/styles/globalStyle';
import theme from './pages/styles/theme';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<RecoilRoot>
			<React.Suspense fallback={<LoadingSpinner isLoading={true} />}>
				<ThemeProvider theme={theme}>
					<App />
					<GlobalStyle />
					<ToastContainer />
				</ThemeProvider>
			</React.Suspense>
		</RecoilRoot>
	</React.StrictMode>
);

serviceWorkerRegistration.register();

reportWebVitals();
