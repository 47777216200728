import axios from 'axios';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { baseAxios } from '../../api/customAxios';
import Content from '../../components/Content';
import Title from '../../components/Title';
import ApplyButton from '../../components/button/ApplyButton';
import InputButton from '../../components/button/InputButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { tokenState } from '../../recoil/atom';
import { getSpeech } from '../../utils/getSpeech';
import FindIdPw from '../auth/FindIdPw';

export default function Signin() {
	const [id, setId] = useState<string>('');
	const [pw, setPw] = useState<string>('');
	const setToken = useSetRecoilState(tokenState);

	const navigate = useNavigate();

	const handleId = (e: ChangeEvent<HTMLInputElement>) => {
		setId(e.target.value);
	};

	const handlePw = (e: ChangeEvent<HTMLInputElement>) => {
		setPw(e.target.value);
	};

	const handleSignin = async () => {
		try {
			const res = await baseAxios.post(`auth/sign-in`, {
				nickname: id,
				password: pw,
			});
			setToken(res.data.token);
			localStorage.setItem('doSurvey', res.data.doServey);
			localStorage.setItem('agreeTerms', res.data.agreeTerms);
			if (!res.data.agreeTerms) {
				navigate('/auth/agree');
			} else if (!res.data.doServey) {
				navigate('/apply/1');
			} else {
				alert('환영합니다!');
				navigate('/home');
			}
		} catch (err) {
			if (axios.isAxiosError(err)) {
				if (err.response?.status === 400) {
					alert('아이디와 비밀번호를 다시 확인해주세요');
				} else {
					alert('잠시 후에 다시 시도해주세요');
				}
			}
		}
	};

	return (
		<ApplyLayout>
			<TopHeader title='똑디 아이디로 로그인하기' page='/login' />
			<Title>
				아이디와 비밀번호를 <br />
				입력해주세요
			</Title>
			<Content>
				<InputButton
					type='text'
					label='아이디'
					placeholder='아이디를 입력하세요'
					value={id}
					onChange={handleId}
				/>
				<InputButton
					type='password'
					label='비밀번호'
					placeholder='8자리 이상 입력해주세요'
					value={pw}
					onChange={handlePw}
				/>
				<FindIdPw />
			</Content>
			<ApplyFooter>
				<VolumeButton
					onClick={() => getSpeech('아이디와 비밀번호를 입력해주세요')}
				/>
				<ApplyButton disabled={!(id && pw)} onClick={handleSignin}>
					로그인
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}
