import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { getSpeech } from '../../utils/getSpeech';

export default function Curriculum15() {
	const [isCorrect, setIsCorrect] = useState(false);
	const [disable, setDisable] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const navigate = useNavigate();

	const handleChange = (e: any) => {
		setInputValue(e.target.value);
	};

	useEffect(() => {
		if (inputValue === '안녕하세요') {
			setIsCorrect(true);
			setDisable(false);
		} else {
			setIsCorrect(false);
		}
	}, [inputValue]);

	console.log(inputValue);
	console.log(isCorrect);
	return (
		<ApplyLayout>
			<TopHeader title='스마트폰 사용법 기초' page='/curriculum14' />
			<RangeBarWrap>
				<div>15/16</div>
				<RangeBar
					type='range'
					min={0}
					max={16}
					step={15}
					// value={15}
					defaultValue={15}
				/>
			</RangeBarWrap>
			{!disable && !isCorrect && (
				<MentBox>
					복사와 붙여넣기 기능을
					<br />
					활용하기 위해 아래 문장을
					<br />꾹 눌러 복사해보세요
				</MentBox>
			)}
			{disable && !isCorrect && (
				<MentBox>
					그런 다음 아래 빈칸을 꾹 눌러
					<br /> 붙여넣기 해보세요
				</MentBox>
			)}
			{!disable && isCorrect && (
				<MentBox>
					잘하셨어요!
					<br />
					다음으로 넘어가 볼까요?
				</MentBox>
			)}
			<CopyBox value={'안녕하세요'} />
			{disable && !isCorrect && <CopyBox onChange={handleChange} />}
			{!disable && isCorrect && (
				<CopyBox value={inputValue} onChange={handleChange} />
			)}
			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						if (!disable && !isCorrect) {
							getSpeech(
								'복사와 붙여넣기 기능을 활용하기 위해 아래 문장을 꾹 눌러 복사해보세요'
							);
						}
						if (disable && !isCorrect) {
							getSpeech('그런 다음 아래 빈칸을 꾹 눌러 붙여넣기 해보세요');
						}
						if (!disable && isCorrect) {
							getSpeech('잘하셨어요 다음으로 넘어가볼까요?');
						}
					}}
				/>
				<ApplyButton
					disabled={disable}
					onClick={() => {
						if (!disable && isCorrect) {
							navigate('/curriculumend');
						} else {
							setDisable(true);
						}
					}}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}

const CopyBox = styled.input`
	width: 90%;
	height: 36px;
	flex-shrink: 0;
	border-radius: 10px;
	border: 1px solid var(--D_Pink, #fd698d);
	display: flex;
	align-items: center;
	padding-left: 15px;
	margin-top: 10px;
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	margin-bottom: 40px;
	line-height: 150%; /* 36px */
`;
