import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
// import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { getSpeech } from '../../utils/getSpeech';

export default function Curriculum2() {
	const navigate = useNavigate();
	return (
		<ApplyLayout>
			<TopHeader title='스마트폰 사용법 기초' page='/curriculum1' />
			<RangeBarWrap>
				<div>2/16</div>
				<RangeBar type='range' min={0} max={16} step={2} defaultValue={2} />
			</RangeBarWrap>
			<MentBox>
				수업이 끝나면
				<br /> 유튜브를 포함한 기본적인
				<br /> 어플을 손쉽게 사용할 수 있어요!
				<br />
				<br />
				준비되셨나요?
			</MentBox>
			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						getSpeech(
							'수업이 끝나면 유튜브를 포함한 기본적인	어플을 손쉽게 사용할 수 있어요!	준비되셨나요?'
						);
					}}
				/>
				<ApplyButton onClick={() => navigate('/curriculum3')}>다음</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}

const ApplyFooter = styled.footer`
	/* margin-top: auto; */
	position: fixed;
	bottom: 0;
	margin-bottom: 30px;
	/* width: 100%; */
	width: 85%;
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	line-height: 150%; /* 36px */
`;
