import { styled } from 'styled-components';
import theme from '../../pages/styles/theme';

interface SelectButtonProps {
	value?: string;
	onClick?: () => void;
	selected?: boolean;
}

export default function SelectButton(props: SelectButtonProps) {
	return (
		<Btn onClick={props.onClick} selected={props.selected}>
			{props.value}
		</Btn>
	);
}

const Btn = styled.button<SelectButtonProps>`
	width: 100%;
	height: 48px;
	margin: 1% 0;
	padding-left: 5%;
	border: 1.5px solid
		${({ selected }) => (selected ? theme.pink : theme.D_grey3)};
	border-radius: 11px;
	color: ${({ selected }) => (selected ? theme.white : theme.D_grey6)};
	background-color: ${({ selected }) => (selected ? theme.pink : theme.white)};
	font-size: 16px;
	${({ selected }) => (selected ? `font-weight: 600` : ``)};
	text-align: start;
	cursor: pointer;

	&:focus {
		outline: none;
	}
`;
