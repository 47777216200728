import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import ApplyButton from '../../components/button/ApplyButton';
import VolumeButton from '../../components/button/VolumeButton';
import ApplyLayout from '../../layout/ApplyLayout';
import ApplyFooter from '../../layout/footer/ApplyFooter';
import TopHeader from '../../layout/header/TopHeader';
import { getSpeech } from '../../utils/getSpeech';

interface ReqButtonBoxProps {
	active: boolean; // Add this line
	onClick: () => void;
}

export default function Curriculum14() {
	const [activeButton, setActiveButton] = useState('');
	const [isCorrect, setIsCorrect] = useState(false);
	const [disable, setDisable] = useState(false);
	const navigate = useNavigate();

	const handleButtonClick = (buttonName: string) => {
		setActiveButton(buttonName);
		if (buttonName === 'Fortune') {
			setIsCorrect(true);
			setDisable(false);
		} else {
			setIsCorrect(false);
			setDisable(true);
		}
	};

	return (
		<ApplyLayout>
			<TopHeader title='스마트폰 사용법 기초' page='/curriculum13' />
			<RangeBarWrap>
				<div>14/16</div>
				<RangeBar type='range' min={0} max={16} step={14} defaultValue={14} />
			</RangeBarWrap>
			{!disable && !isCorrect && (
				<MentBox>
					가로로 나열된 박스를 좌우로
					<br />
					움직일 수 있어요
					<br />
					아래 박스를 좌우로 움직여보세요
				</MentBox>
			)}
			{disable && !isCorrect && (
				<MentBox>
					화면에 숨겨진 네잎클로버를
					<br />
					찾아서 선택해주세요
				</MentBox>
			)}
			{!disable && isCorrect && (
				<MentBox>
					잘하셨어요!
					<br />
					다음으로 넘어가 볼까요?
				</MentBox>
			)}
			{!disable && !isCorrect && (
				<ButtonWrap>
					<ImageWrap>
						<ReqButtonBox
							active={activeButton === 'Rock'}
							onClick={() => handleButtonClick('Rock')}
						>
							<ImageButton src='../image/Rock.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === 'Strawberry'}
							onClick={() => handleButtonClick('Strawberry')}
						>
							<ImageButton src='../image/Strawberry.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === 'Bee'}
							onClick={() => handleButtonClick('Bee')}
						>
							<ImageButton src='../image/Bee.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === 'Dog'}
							onClick={() => handleButtonClick('Dog')}
						>
							<ImageButton src='../image/Dog.png' />
						</ReqButtonBox>
						<ReqButtonBox
							active={activeButton === 'Fortune'}
							onClick={() => handleButtonClick('Fortune')}
						>
							<ImageButton src='../image/Fortune.png' />
						</ReqButtonBox>
					</ImageWrap>
				</ButtonWrap>
			)}
			{disable && !isCorrect && (
				<>
					<ButtonWrap>
						<ImageWrap>
							<ReqButtonBox
								active={activeButton === 'Rock'}
								onClick={() => handleButtonClick('Rock')}
							>
								<ImageButton src='../image/Rock.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Strawberry'}
								onClick={() => handleButtonClick('Strawberry')}
							>
								<ImageButton src='../image/Strawberry.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Bee'}
								onClick={() => handleButtonClick('Bee')}
							>
								<ImageButton src='../image/Bee.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Dog1'}
								onClick={() => handleButtonClick('Dog1')}
							>
								<ImageButton src='../image/Dog.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Cherry'}
								onClick={() => handleButtonClick('Cherry')}
							>
								<ImageButton src='../image/Cherry.png' />
							</ReqButtonBox>
						</ImageWrap>
					</ButtonWrap>
					<ButtonWrap>
						<ImageWrap>
							<ReqButtonBox
								active={activeButton === 'Banana'}
								onClick={() => handleButtonClick('Banana')}
							>
								<ImageButton src='../image/Banana.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Dog2'}
								onClick={() => handleButtonClick('Dog2')}
							>
								<ImageButton src='../image/Dog.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Leaves'}
								onClick={() => handleButtonClick('Leaves')}
							>
								<ImageButton src='../image/Leaves.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Heart'}
								onClick={() => handleButtonClick('Heart')}
							>
								<ImageButton src='../image/Heart.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Fortune'}
								onClick={() => handleButtonClick('Fortune')}
							>
								<ImageButton src='../image/Fortune.png' />
							</ReqButtonBox>
						</ImageWrap>
					</ButtonWrap>
				</>
			)}
			{!disable && isCorrect && (
				<>
					<ButtonWrap>
						<ImageWrap>
							<ReqButtonBox
								active={activeButton === 'Rock'}
								onClick={() => handleButtonClick('Rock')}
							>
								<ImageButton src='../image/Rock.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Strawberry'}
								onClick={() => handleButtonClick('Strawberry')}
							>
								<ImageButton src='../image/Strawberry.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Bee'}
								onClick={() => handleButtonClick('Bee')}
							>
								<ImageButton src='../image/Bee.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Dog1'}
								onClick={() => handleButtonClick('Dog1')}
							>
								<ImageButton src='../image/Dog.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Cherry'}
								onClick={() => handleButtonClick('Cherry')}
							>
								<ImageButton src='../image/Cherry.png' />
							</ReqButtonBox>
						</ImageWrap>
					</ButtonWrap>
					<ButtonWrap>
						<ImageWrap>
							<ReqButtonBox
								active={activeButton === 'Banana'}
								onClick={() => handleButtonClick('Banana')}
							>
								<ImageButton src='../image/Banana.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Dog2'}
								onClick={() => handleButtonClick('Dog2')}
							>
								<ImageButton src='../image/Dog.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Leaves'}
								onClick={() => handleButtonClick('Leaves')}
							>
								<ImageButton src='../image/Leaves.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Heart'}
								onClick={() => handleButtonClick('Heart')}
							>
								<ImageButton src='../image/Heart.png' />
							</ReqButtonBox>
							<ReqButtonBox
								active={activeButton === 'Fortune'}
								onClick={() => handleButtonClick('Fortune')}
							>
								<ImageButton src='../image/Fortune.png' />
							</ReqButtonBox>
						</ImageWrap>
					</ButtonWrap>
				</>
			)}
			<ApplyFooter>
				<VolumeButton
					onClick={() => {
						if (!disable && !isCorrect) {
							getSpeech(
								'가로로 나열된 박스를 좌우로 움직일 수 있어요 아래 박스를 좌우로 움직여보세요'
							);
						}
						if (disable && !isCorrect) {
							getSpeech('화면에 숨겨진 네잎클로버를 찾아서 선택해주세요');
						}
						if (!disable && isCorrect) {
							getSpeech('잘하셨어요 다음으로 넘어가볼까요?');
						}
					}}
				/>
				<ApplyButton
					disabled={disable}
					onClick={() => {
						if (!disable && isCorrect) {
							navigate('/curriculum15');
						}
						setDisable(true);
					}}
				>
					다음
				</ApplyButton>
			</ApplyFooter>
		</ApplyLayout>
	);
}

const ScrollMargin = styled.div`
	margin-top: 60px;
`;

const ButtonWrap = styled.div`
	width: 100%;
	height: 150px;
	gap: 10px;
	overflow-x: scroll; /* 수평 스크롤바 숨김 */
	display: flex;
	justify-content: baseline;
	align-items: center;

	/* 수직 스크롤바 스타일링 */
	&::-webkit-scrollbar {
		width: 1px; /* 스크롤바 너비 */
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent; /* 스크롤바 색상 */
		border-radius: 10px; /* 스크롤바 코너 반경 */
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: transparent; /* 스크롤바 호버시 색상 변경 */
	}

	/* 수직 스크롤바의 트랙 스타일링 */
	&::-webkit-scrollbar-track {
		background-color: transparent; /* 트랙 배경색 */
	}
`;

const ReqButtonBox = styled.button<ReqButtonBoxProps>`
	// Use the updated prop type
	width: 124px;
	height: 124px;
	border: 2px solid var(--d-grey-2, #e6e6e6);
	border-radius: 11px;
	box-shadow: ${({ active }) =>
		active ? '2px 2px 4px 0px rgba(0, 0, 0, 0.25)' : ''};
	background-color: ${({ active }) => (active ? '#FD698D' : '#ebf0f9')};
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	color: ${({ active }) => (active ? '#fff' : '#000')};
	margin-right: 10px;
`;

const ImageWrap = styled.div`
	width: 661px;

	display: flex;
	align-items: baseline;
	justify-content: center;
`;

const ImageButton = styled.img`
	width: 50px;
	height: 50px;
`;

const RangeBarWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 24px;
`;
const RangeBar = styled.input`
	width: 100%;
	height: 8px;
	background:
		linear-gradient(to right, #ccc, #ccc) no-repeat,
		linear-gradient(to right, #fd698d, #fd698d) no-repeat;
	background-size:
		100% 100%,
		100% 100%;
	border-radius: 8px;
	outline: none;
	transition: background-size 450ms ease-in;
	-webkit-appearance: none;
	accent-color: #fd698d;
	margin-top: 19px;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background:
			linear-gradient(to right, #ccc, #ccc) no-repeat,
			linear-gradient(to right, #fd698d, #fd698d) no-repeat;
		background-size:
			100% 100%,
			100% 100%;
	}
`;

const MentBox = styled.div`
	font-size: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: baseline;
	margin-top: 34px;
	margin-bottom: 50px;
	line-height: 150%; /* 36px */
`;
