interface AppType {
	id: number;
	app: string;
}

export const HardestApp: AppType[] = [
	{
		id: 1,
		app: '코레일톡, 티머니 고, 버스타고 등 교통 앱',
	},
	{
		id: 2,
		app: '토스, 은행, 증권사 등 금융 앱',
	},
	{
		id: 3,
		app: '페이스북, 인스타그램 등 SNS',
	},
	{
		id: 4,
		app: '정부24 등 각종 지자체 운영 앱',
	},
	{
		id: 5,
		app: '기타',
	},
];
