import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import GuideLayout from '../../../layout/GuideLayout';
import GuideHeader from '../../../layout/header/GuideHeader';

export default function Korail3() {
	const images = [
		require('../../../asset/jpg/korailFirst.jpg'),
		require('../../../asset/jpg/korail-3/1.jpg'),
		require('../../../asset/jpg/korail-3/2.jpg'),
		require('../../../asset/jpg/korail-3/3.jpg'),
		require('../../../asset/jpg/korail-3/4.jpg'),
		require('../../../asset/jpg/korail-3/5.jpg'),
		require('../../../asset/jpg/korail-3/6.jpg'),
		require('../../../asset/jpg/korail-3/7.jpg'),
		require('../../../asset/jpg/korail-3/8.jpg'),
		require('../../../asset/jpg/korail-3/9.jpg'),
		require('../../../asset/jpg/korail-3/10.jpg'),
		require('../../../asset/jpg/korail-3/11.jpg'),
		require('../../../asset/jpg/korail-3/12.jpg'),
		require('../../../asset/jpg/korail-3/13.jpg'),
		require('../../../asset/jpg/korail-3/14.jpg'),
		require('../../../asset/jpg/korail-3/15.jpg'),
		require('../../../asset/jpg/korail-3/16.jpg'),
		require('../../../asset/jpg/korail-3/17.jpg'),
		require('../../../asset/jpg/korail-3/18.jpg'),
		require('../../../asset/jpg/korail-3/19.jpg'),
		require('../../../asset/jpg/korail-3/20.jpg'),
		require('../../../asset/jpg/korail-3/21.jpg'),
		require('../../../asset/jpg/korail-3/22.jpg'),
		require('../../../asset/jpg/korail-3/23.jpg'),
		require('../../../asset/jpg/korail-3/24.jpg'),
		require('../../../asset/jpg/korail-3/25.jpg'),
		require('../../../asset/jpg/korail-3/26.jpg'),
		require('../../../asset/jpg/korail-3/27.jpg'),
		require('../../../asset/jpg/korail-3/28.jpg'),
	];

	const navigate = useNavigate();
	const [currentImgIdx, setCurrentImgIdx] = useState(0);

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const halfScreenWidth = window.innerWidth / 2;

		if (e.clientX < halfScreenWidth) {
			// 왼쪽 클릭시, 이전 이미지로
			if (currentImgIdx > 0) {
				setCurrentImgIdx(currentImgIdx - 1);
			}
		} else {
			// 오른쪽 클릭시, 다음 이미지로
			if (currentImgIdx < images.length - 1) {
				setCurrentImgIdx(currentImgIdx + 1);
			} else {
				navigate('/guide/korail');
			}
		}
	};

	return (
		<GuideLayout>
			<GuideHeader title="정기권 구매 방법을 모르겠어요" page="/guide/korail" />
			<ImgSlider onClick={(e) => handleClick(e)}>
				<ImgBox src={images[currentImgIdx]} alt="korail guide" />
			</ImgSlider>
		</GuideLayout>
	);
}

const ImgSlider = styled.div``;

const ImgBox = styled.img`
	width: 100%;
	height: 100%;
`;
