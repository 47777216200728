interface CityGuType {
	id: number;
	sigungu: string;
	address_sido_id: number;
}

export const CityGu: CityGuType[] = [
	{
		id: 1,
		sigungu: '종로구',
		address_sido_id: 1,
	},
	{
		id: 2,
		sigungu: '중구',
		address_sido_id: 1,
	},
	{
		id: 3,
		sigungu: '용산구',
		address_sido_id: 1,
	},
	{
		id: 4,
		sigungu: '성동구',
		address_sido_id: 1,
	},
	{
		id: 5,
		sigungu: '광진구',
		address_sido_id: 1,
	},
	{
		id: 6,
		sigungu: '동대문구',
		address_sido_id: 1,
	},
	{
		id: 7,
		sigungu: '중랑구',
		address_sido_id: 1,
	},
	{
		id: 8,
		sigungu: '성북구',
		address_sido_id: 1,
	},
	{
		id: 9,
		sigungu: '강북구',
		address_sido_id: 1,
	},
	{
		id: 10,
		sigungu: '도봉구',
		address_sido_id: 1,
	},
	{
		id: 11,
		sigungu: '노원구',
		address_sido_id: 1,
	},
	{
		id: 12,
		sigungu: '은평구',
		address_sido_id: 1,
	},
	{
		id: 13,
		sigungu: '서대문구',
		address_sido_id: 1,
	},
	{
		id: 14,
		sigungu: '마포구',
		address_sido_id: 1,
	},
	{
		id: 15,
		sigungu: '양천구',
		address_sido_id: 1,
	},
	{
		id: 16,
		sigungu: '강서구',
		address_sido_id: 1,
	},
	{
		id: 17,
		sigungu: '구로구',
		address_sido_id: 1,
	},
	{
		id: 18,
		sigungu: '금천구',
		address_sido_id: 1,
	},
	{
		id: 19,
		sigungu: '영등포구',
		address_sido_id: 1,
	},
	{
		id: 20,
		sigungu: '동작구',
		address_sido_id: 1,
	},
	{
		id: 21,
		sigungu: '관악구',
		address_sido_id: 1,
	},
	{
		id: 22,
		sigungu: '서초구',
		address_sido_id: 1,
	},
	{
		id: 23,
		sigungu: '강남구',
		address_sido_id: 1,
	},
	{
		id: 24,
		sigungu: '송파구',
		address_sido_id: 1,
	},
	{
		id: 25,
		sigungu: '강동구',
		address_sido_id: 1,
	},
	{
		id: 26,
		sigungu: '중구',
		address_sido_id: 2,
	},
	{
		id: 27,
		sigungu: '서구',
		address_sido_id: 2,
	},
	{
		id: 28,
		sigungu: '동구',
		address_sido_id: 2,
	},
	{
		id: 29,
		sigungu: '영도구',
		address_sido_id: 2,
	},
	{
		id: 30,
		sigungu: '부산진구',
		address_sido_id: 2,
	},
	{
		id: 31,
		sigungu: '동래구',
		address_sido_id: 2,
	},
	{
		id: 32,
		sigungu: '남구',
		address_sido_id: 2,
	},
	{
		id: 33,
		sigungu: '북구',
		address_sido_id: 2,
	},
	{
		id: 34,
		sigungu: '해운대구',
		address_sido_id: 2,
	},
	{
		id: 35,
		sigungu: '사하구',
		address_sido_id: 2,
	},
	{
		id: 36,
		sigungu: '금정구',
		address_sido_id: 2,
	},
	{
		id: 37,
		sigungu: '강서구',
		address_sido_id: 2,
	},
	{
		id: 38,
		sigungu: '연제구',
		address_sido_id: 2,
	},
	{
		id: 39,
		sigungu: '수영구',
		address_sido_id: 2,
	},
	{
		id: 40,
		sigungu: '사상구',
		address_sido_id: 2,
	},
	{
		id: 41,
		sigungu: '기장군',
		address_sido_id: 2,
	},
	{
		id: 42,
		sigungu: '중구',
		address_sido_id: 3,
	},
	{
		id: 43,
		sigungu: '동구',
		address_sido_id: 3,
	},
	{
		id: 44,
		sigungu: '서구',
		address_sido_id: 3,
	},
	{
		id: 45,
		sigungu: '남구',
		address_sido_id: 3,
	},
	{
		id: 46,
		sigungu: '북구',
		address_sido_id: 3,
	},
	{
		id: 47,
		sigungu: '수성구',
		address_sido_id: 3,
	},
	{
		id: 48,
		sigungu: '달서구',
		address_sido_id: 3,
	},
	{
		id: 49,
		sigungu: '달성군',
		address_sido_id: 3,
	},
	{
		id: 50,
		sigungu: '군위군',
		address_sido_id: 3,
	},
	{
		id: 51,
		sigungu: '중구',
		address_sido_id: 4,
	},
	{
		id: 52,
		sigungu: '동구',
		address_sido_id: 4,
	},
	{
		id: 53,
		sigungu: '미추홀구',
		address_sido_id: 4,
	},
	{
		id: 54,
		sigungu: '연수구',
		address_sido_id: 4,
	},
	{
		id: 55,
		sigungu: '남동구',
		address_sido_id: 4,
	},
	{
		id: 56,
		sigungu: '부평구',
		address_sido_id: 4,
	},
	{
		id: 57,
		sigungu: '계양구',
		address_sido_id: 4,
	},
	{
		id: 58,
		sigungu: '서구',
		address_sido_id: 4,
	},
	{
		id: 59,
		sigungu: '강화군',
		address_sido_id: 4,
	},
	{
		id: 60,
		sigungu: '옹진군',
		address_sido_id: 4,
	},
	{
		id: 61,
		sigungu: '동구',
		address_sido_id: 5,
	},
	{
		id: 62,
		sigungu: '서구',
		address_sido_id: 5,
	},
	{
		id: 63,
		sigungu: '남구',
		address_sido_id: 5,
	},
	{
		id: 64,
		sigungu: '북구',
		address_sido_id: 5,
	},
	{
		id: 65,
		sigungu: '광산구',
		address_sido_id: 5,
	},
	{
		id: 66,
		sigungu: '동구',
		address_sido_id: 6,
	},
	{
		id: 67,
		sigungu: '중구',
		address_sido_id: 6,
	},
	{
		id: 68,
		sigungu: '서구',
		address_sido_id: 6,
	},
	{
		id: 69,
		sigungu: '유성구',
		address_sido_id: 6,
	},
	{
		id: 70,
		sigungu: '대덕구',
		address_sido_id: 6,
	},
	{
		id: 71,
		sigungu: '중구',
		address_sido_id: 7,
	},
	{
		id: 72,
		sigungu: '남구',
		address_sido_id: 7,
	},
	{
		id: 73,
		sigungu: '동구',
		address_sido_id: 7,
	},
	{
		id: 74,
		sigungu: '북구',
		address_sido_id: 7,
	},
	{
		id: 75,
		sigungu: '울주군',
		address_sido_id: 7,
	},
	{
		id: 76,
		sigungu: '세종시',
		address_sido_id: 8,
	},
	{
		id: 77,
		sigungu: '수원시',
		address_sido_id: 9,
	},
	{
		id: 78,
		sigungu: '고양시',
		address_sido_id: 9,
	},
	{
		id: 79,
		sigungu: '용인시',
		address_sido_id: 9,
	},
	{
		id: 80,
		sigungu: '성남시',
		address_sido_id: 9,
	},
	{
		id: 81,
		sigungu: '부천시',
		address_sido_id: 9,
	},
	{
		id: 82,
		sigungu: '화성시',
		address_sido_id: 9,
	},
	{
		id: 83,
		sigungu: '안산시',
		address_sido_id: 9,
	},
	{
		id: 84,
		sigungu: '남양주시',
		address_sido_id: 9,
	},
	{
		id: 85,
		sigungu: '안양시',
		address_sido_id: 9,
	},
	{
		id: 86,
		sigungu: '평택시',
		address_sido_id: 9,
	},
	{
		id: 87,
		sigungu: '시흥시',
		address_sido_id: 9,
	},
	{
		id: 88,
		sigungu: '파주시',
		address_sido_id: 9,
	},
	{
		id: 89,
		sigungu: '의정부시',
		address_sido_id: 9,
	},
	{
		id: 90,
		sigungu: '김포시',
		address_sido_id: 9,
	},
	{
		id: 91,
		sigungu: '광주시',
		address_sido_id: 9,
	},
	{
		id: 92,
		sigungu: '광명시',
		address_sido_id: 9,
	},
	{
		id: 93,
		sigungu: '군포시',
		address_sido_id: 9,
	},
	{
		id: 94,
		sigungu: '하남시',
		address_sido_id: 9,
	},
	{
		id: 95,
		sigungu: '오산시',
		address_sido_id: 9,
	},
	{
		id: 96,
		sigungu: '양주시',
		address_sido_id: 9,
	},
	{
		id: 97,
		sigungu: '이천시',
		address_sido_id: 9,
	},
	{
		id: 98,
		sigungu: '구리시',
		address_sido_id: 9,
	},
	{
		id: 99,
		sigungu: '안성시',
		address_sido_id: 9,
	},
	{
		id: 100,
		sigungu: '포천시',
		address_sido_id: 9,
	},
	{
		id: 101,
		sigungu: '의왕시',
		address_sido_id: 9,
	},
	{
		id: 102,
		sigungu: '양평군',
		address_sido_id: 9,
	},
	{
		id: 103,
		sigungu: '여주시',
		address_sido_id: 9,
	},
	{
		id: 104,
		sigungu: '동두천시',
		address_sido_id: 9,
	},
	{
		id: 105,
		sigungu: '가평군',
		address_sido_id: 9,
	},
	{
		id: 106,
		sigungu: '과천시',
		address_sido_id: 9,
	},
	{
		id: 107,
		sigungu: '연천군',
		address_sido_id: 9,
	},
	{
		id: 108,
		sigungu: '춘천시',
		address_sido_id: 10,
	},
	{
		id: 109,
		sigungu: '원주시',
		address_sido_id: 10,
	},
	{
		id: 110,
		sigungu: '강릉시',
		address_sido_id: 10,
	},
	{
		id: 111,
		sigungu: '동해시',
		address_sido_id: 10,
	},
	{
		id: 112,
		sigungu: '태백시',
		address_sido_id: 10,
	},
	{
		id: 113,
		sigungu: '속초시',
		address_sido_id: 10,
	},
	{
		id: 114,
		sigungu: '삼척시',
		address_sido_id: 10,
	},
	{
		id: 115,
		sigungu: '홍천군',
		address_sido_id: 10,
	},
	{
		id: 116,
		sigungu: '횡성군',
		address_sido_id: 10,
	},
	{
		id: 117,
		sigungu: '영월군',
		address_sido_id: 10,
	},
	{
		id: 118,
		sigungu: '평창군',
		address_sido_id: 10,
	},
	{
		id: 119,
		sigungu: '정선군',
		address_sido_id: 10,
	},
	{
		id: 120,
		sigungu: '철원군',
		address_sido_id: 10,
	},
	{
		id: 121,
		sigungu: '화천군',
		address_sido_id: 10,
	},
	{
		id: 122,
		sigungu: '양구군',
		address_sido_id: 10,
	},
	{
		id: 123,
		sigungu: '인제군',
		address_sido_id: 10,
	},
	{
		id: 124,
		sigungu: '고성군',
		address_sido_id: 10,
	},
	{
		id: 125,
		sigungu: '양양군',
		address_sido_id: 10,
	},
	{
		id: 126,
		sigungu: '청주시',
		address_sido_id: 11,
	},
	{
		id: 127,
		sigungu: '충주시',
		address_sido_id: 11,
	},
	{
		id: 128,
		sigungu: '제천시',
		address_sido_id: 11,
	},
	{
		id: 129,
		sigungu: '보은군',
		address_sido_id: 11,
	},
	{
		id: 130,
		sigungu: '옥천군',
		address_sido_id: 11,
	},
	{
		id: 131,
		sigungu: '영동군',
		address_sido_id: 11,
	},
	{
		id: 132,
		sigungu: '증평군',
		address_sido_id: 11,
	},
	{
		id: 133,
		sigungu: '진천군',
		address_sido_id: 11,
	},
	{
		id: 134,
		sigungu: '괴산군',
		address_sido_id: 11,
	},
	{
		id: 135,
		sigungu: '음성군',
		address_sido_id: 11,
	},
	{
		id: 136,
		sigungu: '단양군',
		address_sido_id: 11,
	},
	{
		id: 137,
		sigungu: '천안시',
		address_sido_id: 12,
	},
	{
		id: 138,
		sigungu: '공주시',
		address_sido_id: 12,
	},
	{
		id: 139,
		sigungu: '보령시',
		address_sido_id: 12,
	},
	{
		id: 140,
		sigungu: '아산시',
		address_sido_id: 12,
	},
	{
		id: 141,
		sigungu: '서산시',
		address_sido_id: 12,
	},
	{
		id: 142,
		sigungu: '논산시',
		address_sido_id: 12,
	},
	{
		id: 143,
		sigungu: '계룡시',
		address_sido_id: 12,
	},
	{
		id: 144,
		sigungu: '당진시',
		address_sido_id: 12,
	},
	{
		id: 145,
		sigungu: '금산군',
		address_sido_id: 12,
	},
	{
		id: 146,
		sigungu: '부여군',
		address_sido_id: 12,
	},
	{
		id: 147,
		sigungu: '서천군',
		address_sido_id: 12,
	},
	{
		id: 148,
		sigungu: '청양군',
		address_sido_id: 12,
	},
	{
		id: 149,
		sigungu: '홍성군',
		address_sido_id: 12,
	},
	{
		id: 150,
		sigungu: '예산군',
		address_sido_id: 12,
	},
	{
		id: 151,
		sigungu: '태안군',
		address_sido_id: 12,
	},
	{
		id: 152,
		sigungu: '전주시',
		address_sido_id: 13,
	},
	{
		id: 153,
		sigungu: '군산시',
		address_sido_id: 13,
	},
	{
		id: 154,
		sigungu: '익산시',
		address_sido_id: 13,
	},
	{
		id: 155,
		sigungu: '정읍시',
		address_sido_id: 13,
	},
	{
		id: 156,
		sigungu: '남원시',
		address_sido_id: 13,
	},
	{
		id: 157,
		sigungu: '김제시',
		address_sido_id: 13,
	},
	{
		id: 158,
		sigungu: '완주군',
		address_sido_id: 13,
	},
	{
		id: 159,
		sigungu: '진안군',
		address_sido_id: 13,
	},
	{
		id: 160,
		sigungu: '무주군',
		address_sido_id: 13,
	},
	{
		id: 161,
		sigungu: '장수군',
		address_sido_id: 13,
	},
	{
		id: 162,
		sigungu: '임실군',
		address_sido_id: 13,
	},
	{
		id: 163,
		sigungu: '순창군',
		address_sido_id: 13,
	},
	{
		id: 164,
		sigungu: '고창군',
		address_sido_id: 13,
	},
	{
		id: 165,
		sigungu: '부안군',
		address_sido_id: 13,
	},
	{
		id: 166,
		sigungu: '목포시',
		address_sido_id: 14,
	},
	{
		id: 167,
		sigungu: '여수시',
		address_sido_id: 14,
	},
	{
		id: 168,
		sigungu: '순천시',
		address_sido_id: 14,
	},
	{
		id: 169,
		sigungu: '나주시',
		address_sido_id: 14,
	},
	{
		id: 170,
		sigungu: '광양시',
		address_sido_id: 14,
	},
	{
		id: 171,
		sigungu: '담양군',
		address_sido_id: 14,
	},
	{
		id: 172,
		sigungu: '곡성군',
		address_sido_id: 14,
	},
	{
		id: 173,
		sigungu: '구례군',
		address_sido_id: 14,
	},
	{
		id: 174,
		sigungu: '고흥군',
		address_sido_id: 14,
	},
	{
		id: 175,
		sigungu: '보성군',
		address_sido_id: 14,
	},
	{
		id: 176,
		sigungu: '화순군',
		address_sido_id: 14,
	},
	{
		id: 177,
		sigungu: '장흥군',
		address_sido_id: 14,
	},
	{
		id: 178,
		sigungu: '강진군',
		address_sido_id: 14,
	},
	{
		id: 179,
		sigungu: '해남군',
		address_sido_id: 14,
	},
	{
		id: 180,
		sigungu: '영암군',
		address_sido_id: 14,
	},
	{
		id: 181,
		sigungu: '무안군',
		address_sido_id: 14,
	},
	{
		id: 182,
		sigungu: '함평군',
		address_sido_id: 14,
	},
	{
		id: 183,
		sigungu: '영광군',
		address_sido_id: 14,
	},
	{
		id: 184,
		sigungu: '장성군',
		address_sido_id: 14,
	},
	{
		id: 185,
		sigungu: '완도군',
		address_sido_id: 14,
	},
	{
		id: 186,
		sigungu: '진도군',
		address_sido_id: 14,
	},
	{
		id: 187,
		sigungu: '신안군',
		address_sido_id: 14,
	},
	{
		id: 188,
		sigungu: '포항시',
		address_sido_id: 15,
	},
	{
		id: 189,
		sigungu: '경주시',
		address_sido_id: 15,
	},
	{
		id: 190,
		sigungu: '김천시',
		address_sido_id: 15,
	},
	{
		id: 191,
		sigungu: '안동시',
		address_sido_id: 15,
	},
	{
		id: 192,
		sigungu: '구미시',
		address_sido_id: 15,
	},
	{
		id: 193,
		sigungu: '영주시',
		address_sido_id: 15,
	},
	{
		id: 194,
		sigungu: '영천시',
		address_sido_id: 15,
	},
	{
		id: 195,
		sigungu: '상주시',
		address_sido_id: 15,
	},
	{
		id: 196,
		sigungu: '문경시',
		address_sido_id: 15,
	},
	{
		id: 197,
		sigungu: '경산시',
		address_sido_id: 15,
	},
	{
		id: 198,
		sigungu: '의성군',
		address_sido_id: 15,
	},
	{
		id: 199,
		sigungu: '청송군',
		address_sido_id: 15,
	},
	{
		id: 200,
		sigungu: '영양군',
		address_sido_id: 15,
	},
	{
		id: 201,
		sigungu: '영덕군',
		address_sido_id: 15,
	},
	{
		id: 202,
		sigungu: '청도군',
		address_sido_id: 15,
	},
	{
		id: 203,
		sigungu: '고령군',
		address_sido_id: 15,
	},
	{
		id: 204,
		sigungu: '성주군',
		address_sido_id: 15,
	},
	{
		id: 205,
		sigungu: '칠곡군',
		address_sido_id: 15,
	},
	{
		id: 206,
		sigungu: '예천군',
		address_sido_id: 15,
	},
	{
		id: 207,
		sigungu: '봉화군',
		address_sido_id: 15,
	},
	{
		id: 208,
		sigungu: '울진군',
		address_sido_id: 15,
	},
	{
		id: 209,
		sigungu: '울릉군',
		address_sido_id: 15,
	},
	{
		id: 210,
		sigungu: '창원시',
		address_sido_id: 16,
	},
	{
		id: 211,
		sigungu: '진주시',
		address_sido_id: 16,
	},
	{
		id: 212,
		sigungu: '통영시',
		address_sido_id: 16,
	},
	{
		id: 213,
		sigungu: '사천시',
		address_sido_id: 16,
	},
	{
		id: 214,
		sigungu: '김해시',
		address_sido_id: 16,
	},
	{
		id: 215,
		sigungu: '밀양시',
		address_sido_id: 16,
	},
	{
		id: 216,
		sigungu: '거제시',
		address_sido_id: 16,
	},
	{
		id: 217,
		sigungu: '양산시',
		address_sido_id: 16,
	},
	{
		id: 218,
		sigungu: '의령군',
		address_sido_id: 16,
	},
	{
		id: 219,
		sigungu: '함안군',
		address_sido_id: 16,
	},
	{
		id: 220,
		sigungu: '창녕군',
		address_sido_id: 16,
	},
	{
		id: 221,
		sigungu: '고성군',
		address_sido_id: 16,
	},
	{
		id: 222,
		sigungu: '남해군',
		address_sido_id: 16,
	},
	{
		id: 223,
		sigungu: '하동군',
		address_sido_id: 16,
	},
	{
		id: 224,
		sigungu: '산청군',
		address_sido_id: 16,
	},
	{
		id: 225,
		sigungu: '함양군',
		address_sido_id: 16,
	},
	{
		id: 226,
		sigungu: '거창군',
		address_sido_id: 16,
	},
	{
		id: 227,
		sigungu: '합천군',
		address_sido_id: 16,
	},
	{
		id: 228,
		sigungu: '제주시',
		address_sido_id: 17,
	},
	{
		id: 229,
		sigungu: '서귀포시',
		address_sido_id: 17,
	},
];
