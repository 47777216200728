import axios from 'axios';
import { SEVER_URL } from '../pages/config/constants';

export const baseAxios = axios.create({
	baseURL: SEVER_URL,
});

export const authAxios = (accessToken: string) =>
	axios.create({
		baseURL: SEVER_URL,
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});
