import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { baseAxios } from '../../api/customAxios';
import MainLayout from '../../layout/MainLayout';
import { tokenState } from '../../recoil/atom';

export default function NaverLogin() {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const setToken = useSetRecoilState(tokenState);
	const NAVER_CODE = searchParams.get('code');

	const fetchCode = async () => {
		try {
			const user = await baseAxios.post(`auth/social-login/naver`, {
				code: NAVER_CODE,
			});
			setToken(user.data.token);
			localStorage.setItem('doSurvey', user.data.doServey);
			localStorage.setItem('agreeTerms', user.data.agreeTerms);
			if (!user.data.agreeTerms) {
				navigate('/auth/agree');
			} else if (!user.data.doServey) {
				navigate('/apply/1');
			} else {
				navigate('/home');
			}
		} catch (err) {
			if (axios.isAxiosError(err)) {
				console.log(err.response?.data);
				alert('네이버 로그인 오류');
				navigate('/login');
			}
		}
	};

	useEffect(() => {
		if (NAVER_CODE) {
			fetchCode();
		}
	}, []);

	return (
		<MainLayout>
			<LoginText>로그인중입니다..</LoginText>
		</MainLayout>
	);
}

const LoginText = styled.div`
	font-size: 20px;
	font-weight: 600;
	text-align: center;
`;
