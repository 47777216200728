import styled from 'styled-components';
import { ReactComponent as Lock } from '../../asset/svg/LockIconWhite.svg';
import theme from '../../pages/styles/theme';

export default function GuideTmoney() {
	return (
		<CardWrapper>
			<CardTitle>티머니 고 사용 가이드</CardTitle>
			<CardSubtitle>
				&apos;티머니 고&apos; 사용 방법을
				<br />
				같이 알아보아요
			</CardSubtitle>
			<SLock />
		</CardWrapper>
	);
}

const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 90%;
	height: 190px;
	margin: 3% auto;
	position: relative;
	border-radius: 12px;
	background: conic-gradient(
			from 134deg at 54.73% 54.08%,
			rgba(255, 255, 255, 0.14) 0deg,
			rgba(255, 255, 255, 0) 360deg
		),
		#ffa34f;
	box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);

	&::after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		border-radius: 12px;
		background: rgba(118, 118, 118, 0.6);
	}
`;

const CardTitle = styled.div`
	width: 85%;
	margin: 3% auto;
	color: ${theme.white};
	font-size: 24px;
	font-weight: 700;
	line-height: 135%;
`;

const CardSubtitle = styled.div`
	width: 85%;
	margin: 0 auto;
	color: ${theme.white};
	font-weight: 400;
	line-height: 135%;
`;

const SLock = styled(Lock)`
	margin-left: auto;
	margin-right: 20px;
	z-index: 1;
`;
