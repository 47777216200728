import styled from 'styled-components';
import theme from '../pages/styles/theme';

interface LoadingProp {
	isLoading: boolean;
}

export default function LoadingSpinner({ isLoading }: LoadingProp) {
	return (
		<SpinnerSection>
			<Container>
				<Container>로딩중..</Container>
				{isLoading ? <Spinner /> : null}
			</Container>
		</SpinnerSection>
	);
}

const SpinnerSection = styled.section`
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0px;
	width: 100vmax;
	height: 100vmax;
	background: rgba(255, 255, 255, 0.7);
`;

const Container = styled.div`
	display: flex;
	position: fixed;
	top: 50%;
	left: 50%;
	align-items: center;
	justify-content: center;
	width: 240px;
	height: 240px;
	transform: translate(-50%, -50%);
	font-size: 14px;
`;

const Spinner = styled.div`
	position: relative;
	width: 60px;
	height: 60px;
	animation: spin 2s linear infinite;
	border: 7px solid #e0e0e0;
	border-bottom: 7px solid ${theme.pink};
	border-radius: 50%;

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;
